/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Dialog as PrimitiveDialog } from 'primitives';

export const dialogWidth = 861;
const scrollableHeight = 420;

export const Dialog = styled(PrimitiveDialog)`
  overflow-x: hidden;
`;

export const DialogContainer = styled.div`
  max-width: ${dialogWidth}px;
  height: 50vh;
  max-height: ${scrollableHeight}px;
  background-color: var(--silver-base-97);
`;

export const TemplatesList = styled.div`
  position: relative;
  height: 100%;
`;

export const ScrollableList = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;

  padding-left: 15px;
  padding-top: 15px;
`;

export const Footer = styled(Dialog.Footer)`
  background-color: white;
`;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import minimapModule from 'diagram-js-minimap';
import { TabList, Tabs, Tab } from '@carbon/react';

import { SendFeedbackButton } from 'App/Pages/Diagram/SendFeedback';
import { Modeler, CodeEditor, DiffingDetailsModal, DiagramControls } from 'components';
import { Spinner } from 'primitives';
import { shadedDiagram } from 'utils/diffing';
import { diagramControlStore, diffingStore, milestoneStore, notificationStore, processApplicationStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { projectService, fileService, trackingService, folderService } from 'services';
import { isContainedInProcessApplication, isDMN } from 'utils/helpers';
import FileSwitcher from 'features/cycle-through-files/FileSwitcher';

import Sidebar from './Sidebar';
import Header from './Header';
import * as Styled from './DiagramMilestones.styled';
import useViewModes from './useViewModes';
import useCarbonTabsAriaControls from './useCarbonTabsAriaControls';

const VIEW_MODES_TABS_CLASSNAME = 'view-mode-tab';

const DiagramMilestones = () => {
  const history = useHistory();
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const feedbackButtonSlotRef = useRef();

  const { isDiffingEnabled, diagram } = milestoneStore.state;

  useEffect(() => {
    trackingService.trackPageView('milestone');
    init();

    return () => {
      milestoneStore.reset();
      diffingStore.reset();
      diagramControlStore.reset();
    };
  }, [params.id]);

  const tabsContainerRef = useRef(null);
  const ariaControls = useCarbonTabsAriaControls(tabsContainerRef, VIEW_MODES_TABS_CLASSNAME, loading);
  const { view, switchView, VIEW_MODES } = useViewModes(milestoneStore);

  const init = async () => {
    setLoading(true);

    try {
      const file = await fileService.fetchById(params.id);

      const requests = [projectService.fetchById({ projectId: file.projectId })];
      if (isContainedInProcessApplication(file)) {
        const skipParentInitialization = true;
        requests.push(processApplicationStore.init(file.folderId, skipParentInitialization));
      } else if (file.folderId) {
        requests.push(folderService.fetchById(file.folderId));
      }
      const [project, folder] = await Promise.all(requests);
      if (!hasAccess(project, actions.VIEW_VERSIONS)) {
        return history.push(`/diagrams/${params.id}`);
      }
      const relatedFiles = await fileService.fetchRelatedFiles(file.id);
      const milestoneIdsFromUrl = params.slug?.split('...');
      let milestoneIds = milestoneIdsFromUrl;
      if (isDMN(file) && milestoneIds?.length > 1) {
        milestoneIds = [milestoneIdsFromUrl[0]];
      }

      milestoneStore.init({ ...file, folder }, project, relatedFiles, milestoneIds);

      setLoading(false);
    } catch (ex) {
      notificationStore.showError('Yikes! Could not load the milestone data. Please try again later.');
    }
  };

  const additionalModules = () => {
    if (milestoneStore.isDMN) {
      return { drd: [minimapModule] };
    } else {
      return [minimapModule];
    }
  };

  return (
    <Styled.Container>
      {loading ? (
        <Spinner fullHeight />
      ) : (
        <Fragment>
          <Header />
          <Styled.TabsContainer ref={tabsContainerRef}>
            {processApplicationStore.fromAProcessApplication && <FileSwitcher fileId={params.id} />}
            <Tabs selectedIndex={view} onChange={(e) => switchView(e.selectedIndex)}>
              <TabList aria-label="List of tabs" activation="manual">
                <Tab className={VIEW_MODES_TABS_CLASSNAME}>Visual view</Tab>
                <Tab className={VIEW_MODES_TABS_CLASSNAME}>Code view</Tab>
              </TabList>
            </Tabs>
            <Styled.TabPanels>
              {view === VIEW_MODES.VISUAL && (
                <Styled.TabPanel id={ariaControls[0]}>
                  <Modeler
                    diagram={diagram}
                    initialContent={isDiffingEnabled ? shadedDiagram(diagram.content) : diagram.content}
                    isViewOnly
                    additionalModules={additionalModules()}
                    bottomRight={
                      <Styled.CanvasBottomRight>
                        <Styled.FeedbackButtonSlot ref={feedbackButtonSlotRef}>
                          <SendFeedbackButton feedbackButtonSlotRef={feedbackButtonSlotRef} from="milestones" />
                        </Styled.FeedbackButtonSlot>
                        <DiagramControls />
                      </Styled.CanvasBottomRight>
                    }
                    onModelerLoaded={(modeler) => {
                      diagramControlStore.setModeler(modeler);
                      milestoneStore.setModeler(modeler);
                      milestoneStore.fetchAll();
                    }}
                    onModelerInit={() => {
                      milestoneStore.trackMilestoneView('milestone-history');
                    }}
                  />
                </Styled.TabPanel>
              )}
              {view === VIEW_MODES.CODE && (
                <Styled.TabPanel id={ariaControls[1]}>
                  <CodeEditor
                    titles={milestoneStore.titles}
                    value={milestoneStore.primaryContent}
                    modified={milestoneStore.secondaryContent}
                    language="xml"
                  />
                </Styled.TabPanel>
              )}
            </Styled.TabPanels>
          </Styled.TabsContainer>
          <Sidebar />
          <DiffingDetailsModal />
        </Fragment>
      )}
    </Styled.Container>
  );
};

export default observer(DiagramMilestones);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

import isClusterPaused from 'utils/cluster/is-cluster-paused';
import { clustersStore, currentDiagramStore } from 'stores';
import lintingStore from 'components/Modeler/LintingStore';
import { inboundConnectorStore } from 'App/Pages/Diagram/stores';
import { connectorService, Service } from 'services';

class BaseConnectorPollingService extends Service {
  /**
   * Poll for inbound connectors data
   */
  pollInboundConnectorStatus({ isImplementMode, processId, interval = 5000, isAllowedToPoll = false }) {
    useEffect(() => {
      if (isAllowedToPoll) {
        (async () => {
          if (processId) {
            const queryInboundConnectorStatus = async () => {
              if (clustersStore.clusters?.length < 1) {
                inboundConnectorStore.setInitialStatusDataLoadingInProgress(false);
                return;
              }
              let allActiveInboundConnectorStatusData = [];
              const activeClusters = clustersStore.clusters?.filter(
                (cluster) => !isClusterPaused(cluster.status.zeebe)
              );
              await Promise.all(
                activeClusters?.map(async (cluster) => {
                  const baseUrl = cluster.urls.connectors;
                  try {
                    const activeInboundConnectorStatusDataInCluster = await connectorService.getActiveInboundConnectors(
                      baseUrl,
                      processId
                    );
                    const activeInboundConnectorStatusAfterTransform =
                      BaseConnectorPollingService.#transformDataFromNewResponseFormat(
                        activeInboundConnectorStatusDataInCluster
                      );
                    const extendedActiveInboundConnectorStatusData = activeInboundConnectorStatusAfterTransform?.map(
                      (inboundConnector) => ({
                        ...inboundConnector,
                        clusterId: cluster.uuid,
                        clusterName: cluster.name,
                        clusterUrl: baseUrl
                      })
                    );
                    allActiveInboundConnectorStatusData.push(extendedActiveInboundConnectorStatusData);
                  } catch (e) {
                    console.error(
                      'error on getting active inbound connectors',
                      e,
                      'baseUrl',
                      baseUrl,
                      'processId',
                      processId
                    );
                  }
                })
              );
              const allActiveInboundConnectorStatusDataSorted = allActiveInboundConnectorStatusData
                .flat()
                .sort((a, b) => a.clusterName?.localeCompare(b.clusterName));
              inboundConnectorStore.setStatusData(allActiveInboundConnectorStatusDataSorted);
              if (currentDiagramStore.modeler && currentDiagramStore.isValid) {
                lintingStore.performRuntimeLint(
                  currentDiagramStore.modeler,
                  isImplementMode,
                  inboundConnectorStore.statusData
                );
              }
            };

            // Make initial request
            await queryInboundConnectorStatus();
            inboundConnectorStore.setInitialStatusDataLoadingInProgress(false);

            if (this.pollingInterval) {
              // Clear previous interval
              clearInterval(this.pollingInterval);
            }

            // Keep polling
            this.pollingInterval = setInterval(queryInboundConnectorStatus, interval);
          } else if (this.pollingInterval) {
            clearInterval(this.pollingInterval);
          }
        })();
      }

      return () => {
        clearInterval(this.pollingInterval);
      };
    }, [isImplementMode, processId]);
  }

  // Connectors >= 8.6 changed the response format from /inbound
  // This function converts the new format to the older format expected by the rest of webapp
  static #transformDataFromNewResponseFormat(activeInboundConnectorStatusDataInCluster) {
    const response = [];
    for (let connector of activeInboundConnectorStatusDataInCluster) {
      const is8Dot5OrEarlier = !connector.elements;

      if (is8Dot5OrEarlier) {
        response.push(connector);
      } else {
        const { elements, ...rest } = connector;
        const mappedElements = elements.map((element) => {
          // eslint-disable-next-line no-unused-vars
          const { processDefinitionKey, ...elementWithoutProcessDefinitionKey } = element;
          return {
            ...elementWithoutProcessDefinitionKey,
            ...rest
          };
        });

        response.push(...mappedElements);
      }
    }

    return response;
  }
}

export default new BaseConnectorPollingService();

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Loader } from 'primitives';

import * as Styled from './SubmitInProgress.styled';

export default function SubmitInProgress({ deployOnly = false }) {
  return (
    <Styled.SubmitInProgressSection>
      <Loader size="small" /> {deployOnly ? 'Deploying' : 'Running'}
    </Styled.SubmitInProgressSection>
  );
}

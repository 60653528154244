/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { BPMN, NO_PROCESS_ID } from 'utils/constants';

import getRelationId from './get-relation-id';
import getDefinitions from './get-definitions';
import getExecutableProcessId from './get-executable-process-id';

/**
 * Reads a given BPMN diagram's content and extracts the relation ID, process ID, and call links.
 *
 * @param {Object} diagram
 * @param {String} [diagram.type] The diagram type. Only BPMN diagrams can be parsed.
 * @param {String} [diagram.content] The diagram's content.
 * @returns {Object} An object with the extracted values.
 */
export default async function (diagram) {
  if (diagram.type === BPMN) {
    try {
      const definitions = await getDefinitions(diagram.content);

      return {
        relationId: getRelationId(definitions),
        processId: getExecutableProcessId(definitions) || NO_PROCESS_ID
      };
    } catch (ex) {
      return {};
    }
  }

  return {};
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { CheckmarkFilled, PauseOutlineFilled, PendingFilled, WarningFilled } from '@carbon/react/icons';

import isClusterPaused from 'utils/cluster/is-cluster-paused';

import * as Styled from './ClusterStatus.styled';

export default function ClusterStatus({ status }) {
  const colors = {
    Healthy: 'var(--cds-support-success)',
    Unhealthy: 'var(--cds-support-error)',
    Creating: 'var(--cds-support-warning)',
    Updating: 'var(--cds-support-warning)',
    Resuming: 'var(--cds-support-warning)',
    Suspended: 'var(--cds-icon-secondary)'
  };

  const icons = {
    Healthy: <CheckmarkFilled />,
    Unhealthy: <WarningFilled />,
    Creating: <PendingFilled />,
    Updating: <PendingFilled />,
    Resuming: <PendingFilled />,
    Suspended: <PauseOutlineFilled />
  };

  const isStatusKnown = colors.hasOwnProperty(status);

  return (
    <Styled.Container>
      <Styled.Icon color={isStatusKnown ? colors[status] : colors['Unhealthy']}>
        {isStatusKnown ? icons[status] : icons['Unhealthy']}
      </Styled.Icon>
      <Styled.Label>{isClusterPaused(status) ? 'Paused' : isStatusKnown ? status : 'Unknown'}</Styled.Label>
    </Styled.Container>
  );
}

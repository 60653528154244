/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Tab, TabPanels, Tabs } from '@carbon/react';

import { commentsStore, currentDiagramStore } from 'stores';
import {
  dedicatedModesStore,
  detailsPanelTabsStore,
  inboundConnectorStore,
  publicationStore
} from 'App/Pages/Diagram/stores';
import { trackingService } from 'services';
import { Details } from 'icons';
import { ResizablePanel, Sidebar, Tooltip } from 'primitives';
import capitalize from 'utils/capitalize';
import { CallingProcesses, callingProcessesStore } from 'App/Pages/Diagram/CallingProcesses';
import hasAccess, { actions } from 'utils/user-access';
import useIsNewContextPadEnabled from 'experiments/new-context-pad/useIsNewContextPadEnabled';

import CommentList from './CommentList';
import CommentForm from './CommentForm';
import Properties from './Properties';
import { InboundConnector } from './InboundConnector';
import { Publication } from './Publication';
import * as Styled from './DetailsPanel.styled';

export const DetailsPanel = ({ permission, showSidebarTitle }) => {
  const { isSidebarVisible } = commentsStore.state;
  const { selectedTabIndex, isInboundConnectorTabSelected, isPublicationTabSelected } = detailsPanelTabsStore;
  const { isSelectedElementInboundConnector, selectedInboundFunctionality } = inboundConnectorStore;
  const { isSelectedElementStartEvent } = publicationStore;
  const [shouldAutofocus, setShouldAutofocus] = useState(true);
  const { project } = currentDiagramStore.state;

  const { showCallingProcesses } = callingProcessesStore;

  const deriveAutofocusOnClick = (event) => {
    const { target } = event;
    setShouldAutofocus(!target.classList.contains('mode-tab') && !target.classList.contains('sidebar-tab'));
  };

  useEffect(() => {
    document.body.addEventListener('click', deriveAutofocusOnClick, true);

    return () => {
      document.body.removeEventListener('click', deriveAutofocusOnClick, true);
    };
  }, []);

  useEffect(() => {
    detailsPanelTabsStore?.init();

    return () => {
      detailsPanelTabsStore?.reset();
    };
  }, [detailsPanelTabsStore]);

  const { tabsList, tabsPanels } = renderTabs({
    isSelectedElementInboundConnector,
    isSelectedElementStartEvent,
    isInboundConnectorTabSelected,
    isPublicationTabSelected,
    selectedInboundFunctionality,
    showCallingProcesses,
    shouldAutofocus,
    showSidebarTitle,
    isReadOnly: permission.is(['COMMENT']),
    project
  });
  const hasVisibleTabs = tabsList && tabsPanels;

  const newContextPadEnabled = useIsNewContextPadEnabled();

  return (
    <ResizablePanel
      data-test="diagram-sidebar"
      panelKey="properties-panel"
      handleAriaLabel="Resize details panel"
      open={isSidebarVisible}
      background="white"
      position="right"
      minSize={400}
      maxSize={1200}
      onOpenChange={(state) => commentsStore.setSidebarVisible(!state)}
    >
      <Sidebar.Toggle
        onClick={commentsStore.toggleSidebar}
        type="button"
        title={isSidebarVisible ? 'Close Details Panel' : 'Open Details Panel'}
        data-test="details-panel-toggle"
        $newContextPad={newContextPadEnabled}
      >
        <Details width="20" height="20" />
        Details
      </Sidebar.Toggle>

      {isSidebarVisible && (
        <Sidebar.Inner data-test="details-panel-wrapper">
          <CallingProcesses type={`${currentDiagramStore.isBPMN ? 'process' : 'decision'}`} />

          {currentDiagramStore.isBPMN ? (
            hasVisibleTabs && (
              <Styled.TabsContainer>
                <Tabs
                  selectedIndex={selectedTabIndex}
                  onChange={(e) => detailsPanelTabsStore.switchTab(e.selectedIndex)}
                >
                  <Styled.TabList
                    $newContextPad={newContextPadEnabled}
                    aria-label="Details panel tabs"
                    activation="manual"
                  >
                    {tabsList.map((tab) => tab)}
                    <Styled.SpaceFiller />
                  </Styled.TabList>

                  <TabPanels>{tabsPanels.map((panel) => panel)}</TabPanels>
                </Tabs>
              </Styled.TabsContainer>
            )
          ) : (
            <Styled.PropertiesSection $showSidebarTitle={showSidebarTitle} $showCallingProcesses={showCallingProcesses}>
              <Properties isReadOnly={permission.is(['COMMENT'])} />
            </Styled.PropertiesSection>
          )}
        </Sidebar.Inner>
      )}
    </ResizablePanel>
  );
};

const CommentsSection = observer(({ showSidebarTitle, shouldAutofocus }) => {
  return (
    <>
      {showSidebarTitle && (
        <Tooltip title={commentsStore.sidebarTitle} showOnlyOnOverflow>
          <Styled.CommentsTitle data-test="details-panel-title" className="overflow-ellipsis">
            {commentsStore.sidebarTitle}
          </Styled.CommentsTitle>
        </Tooltip>
      )}
      <Styled.CommentsWrapper>
        <CommentList />
        <CommentForm autofocus={shouldAutofocus} />
      </Styled.CommentsWrapper>
    </>
  );
});

const renderTabs = ({
  isSelectedElementInboundConnector,
  isSelectedElementStartEvent,
  isInboundConnectorTabSelected,
  isPublicationTabSelected,
  selectedInboundFunctionality,
  showCallingProcesses,
  shouldAutofocus,
  showSidebarTitle,
  isReadOnly,
  project
}) => {
  const tabsList = [
    <Tab className="sidebar-tab" title="Properties" key="properties-tab">
      Properties
    </Tab>,
    <Tab className="sidebar-tab" title="Comments" key="comments-tab">
      Comments
    </Tab>
  ];

  const tabsPanels = [
    <Styled.TabPanel key="properties-panel" $showCallingProcesses={showCallingProcesses}>
      <Properties isReadOnly={isReadOnly} />
    </Styled.TabPanel>,
    <Styled.TabPanel key="comments-panel" $showCallingProcesses={showCallingProcesses}>
      <CommentsSection shouldAutofocus={shouldAutofocus} showSidebarTitle={showSidebarTitle} />
    </Styled.TabPanel>
  ];

  if (dedicatedModesStore.isImplementMode) {
    if (isSelectedElementInboundConnector && hasAccess(project, actions.VIEW_INBOUND_CONNECTOR_TAB)) {
      tabsList.push(
        <Tab
          className="sidebar-tab"
          title={selectedInboundFunctionality}
          key={`${selectedInboundFunctionality}-tab`}
          onClick={() => {
            trackingService.trackInboundConnectorTabClick();
          }}
        >
          {capitalize(selectedInboundFunctionality)}
        </Tab>
      );

      tabsPanels.push(
        <Styled.TabPanel key={`${selectedInboundFunctionality}-panel`}>
          {isInboundConnectorTabSelected && <InboundConnector />}
        </Styled.TabPanel>
      );
    }

    if (
      !isSelectedElementInboundConnector &&
      isSelectedElementStartEvent &&
      hasAccess(project, actions.VIEW_PUBLICATION_TAB)
    ) {
      tabsList.push(
        <Tab
          className="sidebar-tab"
          title="Publication"
          key="publication-tab"
          onClick={() => {
            trackingService.trackPublicationTabClick();
          }}
        >
          Publication
        </Tab>
      );

      tabsPanels.push(
        <Styled.TabPanel key="publication-panel">{isPublicationTabSelected && <Publication />}</Styled.TabPanel>
      );
    }
  }

  return {
    tabsList,
    tabsPanels
  };
};

export default observer(DetailsPanel);

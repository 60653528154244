/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import * as Sentry from '@sentry/browser';

import config from 'utils/config';
import { version } from 'utils/version';

class TracingService {
  ignoreModules = [
    // Ignore errors from core libraries
    'bpmn-',
    '@bpmn-io',
    '@camunda',
    'camunda-',
    'diagram-',
    'dmn-',
    // Ignore errors from the code editor
    'min/vs/editor/editor.main',
    'tiny-svg',
    'appcues'
  ];

  ignoreErrors = [
    // A recurrent error but with uncertain origin, from external libraries
    'findLastIndex is not a function',
    // Ignore errors from auth invalid state
    'Invalid state',
    // Ignore errors from auth login required
    'Login required',
    "Failed to execute 'setTranslate' on 'SVGTransform': The provided float value is non-finite",
    // Ignored because it generates spike in Sentry. Tracked via https://github.com/camunda/web-modeler/issues/8283
    "Uncaught TypeError: Cannot read properties of undefined (reading 'cloneNode')",
    "Cannot read properties of undefined (reading 'cloneNode')"
  ];

  onBeforeSend = (event) => {
    if (this.#shouldSkipError(event)) {
      return null;
    }

    return event;
  };

  constructor() {
    this.isSentryEnabled = config.sentry?.enabled;

    if (this.isSentryEnabled) {
      Sentry.init({
        dsn: config.sentry.key,
        release: version,
        environment: config.sentry.environment,
        ignoreErrors: [...this.ignoreErrors, ...this.ignoreModules],
        beforeSend: this.onBeforeSend
      });
    }
  }

  registerUser(payload) {
    if (this.isSentryEnabled && payload.modelerUser?.id) {
      this.#registerSentryUser(payload.modelerUser.id);
    }
  }

  #registerSentryUser(id) {
    Sentry.setUser({ id });
  }

  traceError = (error, customMessage) => {
    this.capture(error, customMessage);
  };

  capture(error, customMessage) {
    if (this.isSentryEnabled) {
      Sentry.captureException(error);

      if (customMessage) {
        Sentry.captureMessage(customMessage);
      }
    }
  }

  logout = () => {
    if (this.isSentryEnabled) {
      Sentry.setUser(null);
    }
  };

  /** This code filters out exceptions that occur in given modules.
   *
   * It does this by iterating through the frames of the exception's stack trace
   * and checking if any of the frames are from a given list of modules. If any of the
   * frames are from the given module, then the exception is filtered out.
   *
   * Filtering is necessary so that Sentry logs are not spammed and the monthly quota is not exceeded.
   */
  #shouldSkipError(event) {
    for (const i in event?.exception?.values) {
      const exception = event?.exception?.values[i];
      const frames = exception?.stacktrace?.frames;

      if (!frames) {
        continue;
      }

      const containsErrorFromIgnoredModule = frames.some((frame) => {
        const { filename } = frame;
        return this.ignoreModules.some((module) => filename.includes(module));
      });

      if (containsErrorFromIgnoredModule) {
        return true;
      }
    }

    return false;
  }
}

export default new TracingService();

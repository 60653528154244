/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Button as CarbonButton } from '@carbon/react';

import { defineStagesModalStore } from 'App/Pages/Diagram/Deployment/defineStagesModal';

import * as Styled from './StagesHeader.styled';

export default function StagesHeader({ deployOnly, onClose }) {
  return (
    <Styled.Container>
      <span>Your process application will be deployed in the following defined default clusters.</span>
      <CarbonButton
        size="md"
        kind="tertiary"
        onClick={() => {
          onClose();
          defineStagesModalStore.open({ executeModalMode: deployOnly ? 'deploy' : 'execute' });
        }}
      >
        Define stages
      </CarbonButton>
    </Styled.Container>
  );
}

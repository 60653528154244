/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

// Checks if canvas viewport is attached in the DOM
function isAttached(canvas) {
  const size = canvas.getSize();
  // if diagram-js canvas has a size, it is attached in the DOM
  return size.width > 0 && size.height > 0;
}

// Wait until the modeler viewport is attached in the DOM
export async function waitForAttach(modeler) {
  const canvas = modeler.get('canvas');

  if (isAttached(canvas)) {
    return;
  }

  return new Promise((resolve) => {
    // Check every 100ms if viewport is attached
    const interval = setInterval(() => {
      if (isAttached(canvas)) {
        clearInterval(interval);
        resolve();
      }
    }, 100);
  });
}

/**
 * This function adds a custom keydown listener for resetting both native
 * browser zoom level and canvas zoom level, when presing CTRL/CMD + 0
 */
export function addResetZoomListener(modeler) {
  const keyboard = modeler.get('keyboard');
  const canvas = modeler.get('canvas');

  // Add a listener with higher priority and returns false on CTRL/CMD + 0
  keyboard.addListener(1200, function (context) {
    var event = context.keyEvent;

    if ((event.ctrlKey || event.metaKey) && !event.altKey && event.key === '0') {
      canvas.zoom(1);

      // Returning false prevents bpmn-js listener with a lower priority
      // from executing.
      return false;
    }
  });
}

export function getSavedLayout() {
  const layout = localStorage.getItem('properties-panel-layout');

  if (!layout) {
    return {};
  }

  return JSON.parse(layout);
}

export function saveLayout(layout) {
  localStorage.setItem('properties-panel-layout', JSON.stringify(layout));
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Link, Modal, TextInput, Theme } from '@carbon/react';

import { gitSettingsStore, GitSyncStatus, gitSyncStore, useShowBackgroundSyncNotification } from 'features/git-sync';
import { getGitProviderLabel } from 'features/git-sync/utils';

import * as Styled from './GitSyncDialog.styled';
import InlineNotification from './InlineNotification';
import ConflictsHandler from './ConflictsHandler';

const GitSyncDialog = () => {
  const {
    shouldShowDialog,
    versionTag,
    status,
    isPulling,
    isPushing,
    pullingStatus,
    pushingStatus,
    pullMessage,
    pushMessage,
    hasValidVersion,
    versionTagMessage,
    hasConflicts,
    hasFinished,
    hasErrors
  } = gitSyncStore;

  const { settings } = gitSettingsStore;
  const gitLabel = getGitProviderLabel();

  const handleClose = () => {
    gitSyncStore.close();
  };

  const getLoadingDescription = () => {
    if (hasFinished) {
      return 'Synchronized';
    }

    if (hasConflicts) {
      return 'Conflict';
    }

    if (hasErrors) {
      return 'Failed';
    }

    return 'Synchronizing...';
  };

  useShowBackgroundSyncNotification();

  return (
    <Theme theme="g10">
      <Modal
        open={shouldShowDialog}
        size="sm"
        modalHeading={`Sync with ${gitLabel}`}
        primaryButtonText="Synchronize"
        primaryButtonDisabled={!hasValidVersion}
        secondaryButtonText="Cancel"
        onRequestSubmit={() => gitSyncStore.start()}
        onSecondarySubmit={() => handleClose()}
        onRequestClose={() => handleClose()}
        loadingStatus={status}
        loadingDescription={getLoadingDescription()}
      >
        <>
          <Styled.Message>
            Synchronizing your process application with your repository:
            <br />
            {settings?.repositoryUrl ? (
              <Link href={settings?.repositoryUrl} target="_blank">
                {settings.repositoryUrl}
              </Link>
            ) : (
              'Loading settings...'
            )}
          </Styled.Message>

          <Styled.VersionTagDescription>
            <span>
              Name your milestone with a version tag to track it through the development lifecycle and ensure the right
              version is called.
            </span>
            <Link
              href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#versioning"
              target="_blank"
            >
              Learn more
            </Link>
          </Styled.VersionTagDescription>

          <TextInput
            id="git-version-tag"
            type="text"
            labelText="Version tag"
            value={versionTag}
            helperText={versionTagMessage}
            onFocus={(e) => e.target.select()}
            onChange={(e) => gitSyncStore.setVersionTag(e.target.value)}
            disabled={status !== GitSyncStatus.INACTIVE}
            data-modal-primary-focus
          />

          {isPulling && (
            <Styled.ProgressBar
              label={`Pulling latest changes from ${gitLabel}`}
              helperText={pullMessage}
              size="small"
              status={pullingStatus}
            />
          )}

          {isPushing && (
            <Styled.ProgressBar
              label={`Pushing changes to ${gitLabel}`}
              helperText={pushMessage}
              size="small"
              status={pushingStatus}
            />
          )}

          {hasConflicts ? <ConflictsHandler /> : <InlineNotification />}
        </>
      </Modal>
    </Theme>
  );
};

export default observer(GitSyncDialog);

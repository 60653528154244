/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState } from 'react';
import { observer } from 'mobx-react';
import { useHistory } from 'react-router-dom';
import { Button as CarbonButton } from '@carbon/react';
import { Enterprise, Folders } from '@carbon/icons-react';

import { isContainedInProcessApplication } from 'utils/helpers';
import { organizationStore } from 'stores';
import { Download, Error, Info, Milestone, Saved } from 'icons';
import { Button, IconButton } from 'primitives';
import { Dropdown, VersionPublishDialog } from 'components';
import { exportConnectorTemplate } from 'utils/file-io';
import hasAccess, { actions } from 'utils/user-access';

import connectorTemplateStore from './ConnectorTemplateStore';
import * as Styled from './ActionBar.styled';

const getStatusIcon = (status) => {
  switch (status) {
    case 'progress':
      return <Styled.Spinner width="22" height="22" />;
    case 'done':
      return <Saved width="24" height="24" />;
    case 'error':
      return <Error width="22" height="22" />;
    default:
      return <Info width="18" height="18" />;
  }
};

const PUBLICATION_MODE = {
  PROJECT: 'project',
  ORGANIZATION: 'organization'
};

export const ActionBar = () => {
  const [publicationMode, setPublicationMode] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const history = useHistory();

  const { connectorTemplate, loading, project, status, isValid, uploadImage, setShowDuplicateDialog } =
    connectorTemplateStore;

  const handlePublicationToProject = () => {
    setAnchorEl(null);

    setPublicationMode(PUBLICATION_MODE.PROJECT);
  };

  const handlePublicationToOrg = () => {
    setAnchorEl(null);

    setPublicationMode(PUBLICATION_MODE.ORGANIZATION);
  };

  const getPublishConnectorButton = () => {
    const isDisabled = loading || !isValid(connectorTemplate.content);

    if (!connectorTemplate || isContainedInProcessApplication(connectorTemplate)) {
      return null;
    }

    return organizationStore.hasElevatedOrganizationPermissions ? (
      <>
        {connectorTemplate.imported && (
          <Button
            className="publish-to-organization-button"
            data-test="publish-to-org"
            onClick={handlePublicationToOrg}
            disabled={isDisabled}
          >
            <Enterprise />
            Publish to organization
          </Button>
        )}

        {!connectorTemplate.imported && (
          <>
            <Button
              dropdown
              className="publish-button"
              aria-haspopup="true"
              onClick={(evt) => setAnchorEl(evt?.currentTarget)}
              data-test="publish-connector-template-dropdown"
            >
              Publish
            </Button>

            <Dropdown anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={() => setAnchorEl(null)}>
              <Dropdown.ListItem
                onClick={handlePublicationToProject}
                data-test="publish-to-project"
                disabled={isDisabled}
              >
                <Styled.PublishOption>
                  <Folders />
                  Publish to project
                </Styled.PublishOption>
              </Dropdown.ListItem>

              <Dropdown.ListItem onClick={handlePublicationToOrg} data-test="publish-to-org" $isDisabled={isDisabled}>
                <Styled.PublishOption>
                  <Enterprise />
                  Publish to organization
                </Styled.PublishOption>
              </Dropdown.ListItem>
            </Dropdown>
          </>
        )}
      </>
    ) : !connectorTemplate.imported ? (
      <Button
        className="publish-button"
        data-test="publish-connector-template"
        onClick={() => setPublicationMode(PUBLICATION_MODE.PROJECT)}
        disabled={isDisabled}
      >
        Publish
      </Button>
    ) : null;
  };

  return (
    <Styled.ActionBar>
      <Styled.Left>
        {hasAccess(project, actions.MODIFY_CONNECTOR_TEMPLATE) && !connectorTemplate.imported && !loading && (
          <Button
            onClick={uploadImage}
            disabled={!isValid(connectorTemplate.content)}
            variant="secondary"
            data-test="upload-icon"
          >
            Upload icon
          </Button>
        )}

        {Boolean(status) && (
          <Styled.Status data-test="autosave" $isDanger={status.status === 'error'}>
            {getStatusIcon(status.status)}
            {status.message}
          </Styled.Status>
        )}

        {!hasAccess(project, actions.MODIFY_CONNECTOR_TEMPLATE) && !connectorTemplate?.imported && !loading && (
          <Styled.Status data-test="collaborator-info">
            {getStatusIcon()} Only collaborators with write access may modify Connector templates.
          </Styled.Status>
        )}

        {connectorTemplate?.imported && (
          <Styled.Status data-test="imported-info">
            {getStatusIcon()} This Connector template has been downloaded from the Marketplace and cannot be modified.{' '}
            {hasAccess(project, actions.MODIFY_CONNECTOR_TEMPLATE) && 'Duplicate the template to customize it.'}
          </Styled.Status>
        )}
      </Styled.Left>

      {hasAccess(project, actions.MODIFY_CONNECTOR_TEMPLATE) && connectorTemplate?.imported && (
        <>
          <CarbonButton
            onClick={() => {
              setShowDuplicateDialog(true);
            }}
            kind={
              hasAccess(project, actions.VIEW_VERSIONS) && organizationStore.hasElevatedOrganizationPermissions
                ? 'ghost'
                : 'primary'
            }
          >
            Customize template
          </CarbonButton>
        </>
      )}

      {hasAccess(project, actions.VIEW_VERSIONS) && (
        <>
          {getPublishConnectorButton()}
          <IconButton
            title="Show version history"
            data-test="version-history"
            onClick={() => {
              history.push(`/connector-templates/${connectorTemplate.id}/versions`);
            }}
          >
            <Milestone width="24" height="24" />
          </IconButton>
        </>
      )}

      <IconButton
        disabled={loading}
        onClick={() => exportConnectorTemplate(connectorTemplate)}
        title="Download Connector template"
        data-test="export-json"
      >
        <Download width="25" height="24" />
      </IconButton>

      {publicationMode && (
        <VersionPublishDialog
          open
          targetIsOrganization={publicationMode === PUBLICATION_MODE.ORGANIZATION}
          onClose={() => setPublicationMode(null)}
          file={connectorTemplate}
          origin="publish"
        />
      )}
    </Styled.ActionBar>
  );
};

export default observer(ActionBar);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useRef } from 'react';

import { IconButton } from 'primitives';
import { Cross } from 'icons';
import useFocusTrap from 'hooks/useFocusTrap';
import useKeyboardTrap from 'hooks/useKeyboardTrap';

import * as Styled from './Dialog.styled';

export default function Dialog({
  open,
  clickOutsideToClose,
  onClose,
  onConfirm,
  children,
  backdropStyle,
  maxWidth,
  ...props
}) {
  const innerRef = useRef();

  useFocusTrap({ open, ref: innerRef });

  const handleKeyDown = useKeyboardTrap({
    onClose,
    onSubmit: onConfirm,
    ref: innerRef
  });

  const handleClick = (evt) => {
    if (evt.currentTarget != evt.target) {
      return;
    }

    if (typeof onClose == 'function' && clickOutsideToClose) {
      onClose(evt);
    }
  };

  if (!open) {
    return null;
  }

  return createPortal(
    <Styled.Background onClick={handleClick} style={backdropStyle}>
      <Styled.Inner
        tabIndex={1}
        onKeyDown={handleKeyDown}
        role="dialog"
        aria-modal="true"
        aria-labelledby="dialog-title"
        ref={innerRef}
        $maxWidth={maxWidth}
        {...props}
      >
        {onClose && (
          <Styled.CloseIcon>
            <IconButton onClick={onClose} title="Close the dialog" data-test="close-dialog">
              <Cross width="16" height="16" />
            </IconButton>
          </Styled.CloseIcon>
        )}
        {children}
      </Styled.Inner>
    </Styled.Background>,
    document.body
  );
}

Dialog.propTypes = {
  open: PropTypes.bool.isRequired,
  clickOutsideToClose: PropTypes.bool,
  onClose: PropTypes.func,
  onConfirm: PropTypes.func,
  maxWidth: PropTypes.number,
  backdropStyle: PropTypes.object
};

Dialog.defaultProps = {
  clickOutsideToClose: true,
  maxWidth: 750
};

const DialogHeader = ({ children }) => <Styled.Header>{children}</Styled.Header>;

DialogHeader.propTypes = {
  children: PropTypes.node.isRequired
};

Dialog.Header = DialogHeader;
Dialog.Title = Styled.Title;
Dialog.Subtitle = Styled.Subtitle;
Dialog.Content = Styled.Content;
Dialog.Footer = Styled.Footer;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';

import { parseClusterZeebeVersion } from 'utils/cluster/cluster-version-util';
import { clusterService } from 'services';
import { currentDiagramStore, organizationStore } from 'stores';
import { BPMN } from 'utils/constants';

class ClustersStore {
  clusters = null;
  isPolling = false;
  #watchClustersTimer = null;

  constructor() {
    makeAutoObservable(this);
  }

  /**
   * Watch clusters and update the store when the clusters are available.
   * @returns {Promise<Boolean>} A promise that resolves when the clusters are available. The promise resolves with a boolean value indicating if the clusters are available.
   */
  watchClustersAndUpdate = () => {
    return new Promise((resolve, reject) => {
      this.setIsPolling(true);

      this.#watchClustersTimer = setInterval(async () => {
        try {
          const clusters = await clusterService.getClusters(organizationStore.currentOrganizationId);

          if (!this.isPolling) {
            // Avoid to set clusters if the polling is not active anymore
            resolve(false);
            return;
          }

          if (clusters?.length) {
            this.setClusters(clusters);
            this.clearWatchClustersTimer();
            resolve(true);
          }
        } catch (err) {
          console.error(err);
          this.clearWatchClustersTimer();
          reject(err);
        }
      }, 1000);
    });
  };

  clearWatchClustersTimer() {
    if (this.#watchClustersTimer) {
      clearInterval(this.#watchClustersTimer);
      this.setIsPolling(false);
    }
  }

  setIsPolling(value) {
    this.isPolling = value;
  }

  async loadClusters() {
    const clusters = await clusterService.getClusters(organizationStore.currentOrganizationId);
    this.setClusters(clusters);
  }

  setClusters(clusters) {
    this.clusters = clusters;
  }

  get availableClusters() {
    return this.clusters?.filter(this.isClusterAvailable) || [];
  }

  get unavailableClusters() {
    return this.clusters?.filter((cluster) => !this.isClusterAvailable(cluster)) || [];
  }

  getClustersByLabel(label) {
    return this.clusters?.filter((cluster) => cluster?.labels?.camunda?.includes(label)) || [];
  }

  get devClusters() {
    return this.getClustersByLabel('dev');
  }

  get testClusters() {
    return this.getClustersByLabel('test');
  }

  get stageClusters() {
    return this.getClustersByLabel('stage');
  }

  get prodClusters() {
    return this.getClustersByLabel('prod');
  }

  isClusterAvailable = (cluster) => {
    if (currentDiagramStore.state.diagram?.type === BPMN) {
      // BPMN diagrams can be deployed to all clusters
      return true;
    }

    const versionInfo = parseClusterZeebeVersion(cluster.generation?.versions?.zeebe);

    if (!versionInfo) {
      // if the version information cannot be parsed (e.g. for names that do not contain numbers)
      // we assume that dmn deployment is allowed
      return true;
    }

    // DMN diagrams can only be deployed to clusters with version > 1.4.0-alpha2
    const { major, minor, patch, prerelease } = versionInfo;
    return (
      major > 1 ||
      (major === 1 && minor > 4) ||
      (major === 1 && minor === 4 && patch > 0) ||
      (major === 1 && minor === 4 && patch === 0 && prerelease?.[0] !== 'alpha1')
    );
  };
}

export default new ClustersStore();

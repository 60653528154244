/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { DEFAULT_ZEEBE_VERSION, EXPORTER } from 'utils/constants';
import { versionShort } from 'utils/version';

export default ({ relationId, definitionsId, decisionId, decisionName }) => `<?xml version="1.0" encoding="UTF-8"?>
<definitions xmlns="https://www.omg.org/spec/DMN/20191111/MODEL/" xmlns:dmndi="https://www.omg.org/spec/DMN/20191111/DMNDI/" xmlns:dc="http://www.omg.org/spec/DMN/20180521/DC/" id="${definitionsId}" name="DRD" namespace="http://camunda.org/schema/1.0/dmn" xmlns:modeler="http://camunda.org/schema/modeler/1.0" xmlns:camunda="http://camunda.org/schema/1.0/dmn" exporter="${EXPORTER}" exporterVersion="${versionShort}" modeler:executionPlatform="Camunda Cloud" modeler:executionPlatformVersion="${DEFAULT_ZEEBE_VERSION}" camunda:diagramRelationId="${relationId}">
  <decision id="${decisionId ? decisionId : 'Decision_{{ ID:decision }}'}" name="${
    decisionName ? decisionName : 'Decision 1'
  }">
    <decisionTable id="DecisionTable_{{ ID }}">
      <input id="Input_1">
        <inputExpression id="InputExpression_1" typeRef="string">
          <text></text>
        </inputExpression>
      </input>
      <output id="Output_1" typeRef="string" />
    </decisionTable>
  </decision>
  <dmndi:DMNDI>
    <dmndi:DMNDiagram>
      <dmndi:DMNShape dmnElementRef="${decisionId ? decisionId : 'Decision_{{ ID:decision }}'}">
        <dc:Bounds height="80" width="180" x="160" y="100" />
      </dmndi:DMNShape>
    </dmndi:DMNDiagram>
  </dmndi:DMNDI>
</definitions>
`;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { ActionableNotification } from '@carbon/react';
import { Launch } from '@carbon/icons-react';

import { notificationStore } from 'stores';

import * as Styled from './BrowseBlueprintsModalList.styled';

export const BrowseBlueprintsModalNotFound = ({ errors, searchValue, isBlueprintsLoadingError }) => {
  /**
   * Copy the error logs stored in errors state variable
   */
  const copyErrorLogs = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(errors, null, 2));
      notificationStore.showSuccess('The error logs have been copied into your clipboard as JSON.');
    } catch (err) {
      notificationStore.showError(
        "Yikes! Couldn't copy the error logs into your clipboard. Make sure that you gave permissions to the browser."
      );
    }
  };

  return (
    <div>
      {searchValue?.length > 0 ? (
        `We couldn't find a match for your search phrase. Please clear the search to see existing resources.`
      ) : (
        <>
          {isBlueprintsLoadingError ? (
            <>
              <ActionableNotification
                kind="error"
                title="Error"
                subtitle={
                  <span>
                    We are sorry, an error occurred, please try again later. If the problem persists, get in touch with
                    the{' '}
                    <Styled.SupportLink target="_blank" href="https://forum.camunda.io/">
                      support team
                    </Styled.SupportLink>
                    .
                  </span>
                }
                inline
                lowContrast
                hideCloseButton
                actionButtonLabel="Copy error logs for support"
                onActionButtonClick={copyErrorLogs}
              />
            </>
          ) : (
            <>
              <Styled.NoBlueprintsFoundText>{"Can't find what you're looking for?"}</Styled.NoBlueprintsFoundText>
              <Styled.ExternalLink
                target="_blank"
                href="https://marketplace.camunda.com/en-US/pages/connectorsIdeaPortal"
              >
                {'Suggest to our Idea Portal'}
                <Styled.ResourceLink>
                  <Launch />
                </Styled.ResourceLink>
              </Styled.ExternalLink>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default observer(BrowseBlueprintsModalNotFound);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useRef, useEffect } from 'react';
import { Form } from '@bpmn-io/form-js';

import { BPMN_EVENTS_PRIORITY } from 'utils/constants';

import * as Styled from './FormPreview.styled';

export const INVALID_FORM_MESSAGE = 'Form schema invalid';

export default function FormPreview({
  formDefinition,
  onChange = () => {},
  scale = 1,
  readOnly = false,
  bordered = false
}) {
  const [formRenderFailed, setFormRenderFailed] = useState(null);
  let formViewer = null;
  const formRef = useRef();

  useEffect(() => {
    (async () => {
      formViewer = new Form({
        properties: {
          readOnly,
          textLinkTarget: '_blank'
        }
      });
      try {
        await formViewer.importSchema(formDefinition);
        formViewer.attachTo(formRef.current);
        formViewer.on('changed', BPMN_EVENTS_PRIORITY.LOW, onChange);
      } catch (err) {
        setFormRenderFailed(true);
      }
    })();

    return () => {
      if (formViewer) {
        formViewer.off('changed', BPMN_EVENTS_PRIORITY.LOW, onChange);
        formViewer.destroy();
      }
    };
  }, [formDefinition]);

  if (formRenderFailed) {
    return (
      <Styled.InvalidFormMessage data-test={'invalid-form-message'}>{INVALID_FORM_MESSAGE}</Styled.InvalidFormMessage>
    );
  }

  return (
    <Styled.FormPreviewContainer $bordered={bordered}>
      <Styled.FormPreview ref={formRef} scale={scale} />
    </Styled.FormPreviewContainer>
  );
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, makeObservable, observable, runInAction } from 'mobx';

import { FILE_TYPE_MAPPING } from 'utils/constants';
import { milestoneService, trackingService } from 'services';
import { notificationStore } from 'stores';
import { organizationSharedResourcesStore } from 'components/SharedResources/stores';

class UnpublishResourceModalStore {
  shouldShowUnpublishModal = false;
  resource;
  organizationId;

  constructor() {
    makeObservable(this, {
      shouldShowUnpublishModal: observable,
      resource: observable,
      organizationId: observable,
      showUnpublishModal: action,
      closeUnpublishModal: action,
      unpublishResource: action
    });
  }

  showUnpublishModal = (resource, organizationId) => {
    runInAction(() => {
      this.shouldShowUnpublishModal = true;
      this.resource = resource;
      this.organizationId = organizationId;
    });
  };

  closeUnpublishModal = () => {
    runInAction(() => {
      this.shouldShowUnpublishModal = false;
      this.resource = undefined;
      this.organizationId = undefined;
    });
  };

  unpublishResource = async () => {
    // Storing these variables locally so they're not lost when state updates
    const resource = this.resource;
    const organizationId = this.organizationId;

    let milestoneUpdateSuccessful = false;
    try {
      await milestoneService.unpublishFromOrganization(resource.milestone.id);
      milestoneUpdateSuccessful = true;

      organizationSharedResourcesStore.updateSharedResourcesForOrganization(organizationId);
      notificationStore.showSuccess(`${resource.name} unpublished.`);
    } catch (ex) {
      notificationStore.showError(
        `Unpublishing ${resource.name} failed.\nPlease retry. If the issue persists, contact support.`
      );
    } finally {
      this.closeUnpublishModal();
    }

    if (milestoneUpdateSuccessful) {
      try {
        trackingService.trackPublicationMilestoneToOrganization({
          from: 'unpublish',
          fileId: resource.id,
          fileType: FILE_TYPE_MAPPING.CONNECTOR_TEMPLATE
        });
      } catch (e) {
        console.error(
          `trackingService.trackPublicationMilestoneToOrganization failed. milestone.id: ${resource.milestone.id} entity.id: ${resource.id}`
        );
      }
    }
  };
}

export default new UnpublishResourceModalStore();

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default class DocumentationOnlyPropertiesProvider {
  constructor(elementTemplates, propertiesPanel, injector) {
    /**
     * 50 - Low priority to execute this last
     * Otherwise another plugin will add an additional property after the filtering is done in this one
     */
    propertiesPanel.registerProvider(50, this);

    this._elementTemplates = elementTemplates;
    this._injector = injector;
  }

  getGroups() {
    return (groups) => {
      groups = groups.filter((group) => group.id === 'documentation');
      return groups;
    };
  }
}

DocumentationOnlyPropertiesProvider.$inject = ['elementTemplates', 'propertiesPanel', 'injector'];

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Loading } from '@carbon/react';

import * as Styled from './BrowseBlueprintsModal.styled';

export const BrowseBlueprintsModalLoading = ({ isBlueprintsLoading }) => {
  const getLoadingColumn = () => {
    return (
      <Styled.LoadingColumn sm={4} md={8} lg={16}>
        <Loading withOverlay={false} />
      </Styled.LoadingColumn>
    );
  };

  return (
    <>
      {isBlueprintsLoading ? (
        <Styled.BlueprintListLoadingRow>{getLoadingColumn()}</Styled.BlueprintListLoadingRow>
      ) : (
        <Styled.LoadingRow>{getLoadingColumn()}</Styled.LoadingRow>
      )}
    </>
  );
};

export default observer(BrowseBlueprintsModalLoading);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import { InlineNotification } from '@carbon/react';

import { getSanitizedZeebeVersionString } from 'utils/cluster/cluster-version-util';
import { processApplicationStore, clustersStore, organizationStore, userStore } from 'stores';
import { clusterService, trackingService } from 'services';
import { isLinkingImprovementsModalVisibleStore } from 'App/Pages/Diagram/stores';
import { deploymentPermissionsStore, deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import { Button, Dialog, Link, Spinner } from 'primitives';
import config from 'utils/config';
import capitalize from 'utils/capitalize';
import pluralize from 'utils/pluralize';
import { useExecutionPayload } from 'App/Pages/Diagram/Deployment/executeModal/AddVariable';
import SubmitInProgress from 'App/Pages/Diagram/Deployment/executeModal/SubmitInProgress';
import { formLinkStore } from 'App/Pages/Diagram/FormLinking';

import ClusterSelection from './ClusterSelection';
import NoPermissionMessage from './NoPermissionMessage';
import * as Styled from './ExecuteModal.styled';
import useStagesClusters from './Stages/useStagesClusters';

export const ExecuteModal = ({ open, onClose, mode, deployTarget = 'diagram' }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorLoadingClusters, setIsErrorLoadingClusters] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { selectedClusterId, setSelectedClusterId } = deploymentStore;
  const { isAllowedToDeploy, isAllowedToStartInstance, isAllowedToDeployToSelectedCluster } =
    deploymentPermissionsStore;
  const { availableClusters, unavailableClusters } = processApplicationStore.fromAProcessApplication
    ? useStagesClusters({
        availableClusters: clustersStore.availableClusters,
        unavailableClusters: clustersStore.unavailableClusters,
        processApplication: processApplicationStore.processApplication
      })
    : clustersStore;
  const deployOnly = mode === 'deploy';
  const isAllowed = deployOnly ? isAllowedToDeploy : isAllowedToStartInstance;

  const { isTrialExpired, isUsingTrial, hasBillingPermission, currentOrganization } = organizationStore;
  const { isLoading: isFormLinkingLoading } = formLinkStore;

  // load available clusters
  useEffect(() => {
    let mounted = true;

    const fetchData = async () => {
      try {
        if (open && isAllowed) {
          setIsLoading(true);
          const clusters = await clusterService.getClusters(organizationStore.currentOrganizationId);
          if (mounted) {
            clustersStore.setClusters(clusters);
          }
          if (processApplicationStore.fromAProcessApplication) {
            await processApplicationStore.getExternalResources();
          }
        } else {
          setPayload('');
          setIsErrorLoadingClusters(false);
        }
      } catch (error) {
        setIsErrorLoadingClusters(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();

    return () => {
      mounted = false;
    };
  }, [open]);

  // check if user has revoked roles
  useEffect(() => {
    if (open && isAllowed) {
      userStore.checkRevokedRoles();
    }
  }, [open]);

  useEffect(() => {
    const isSelectedClusterPresentInClusters =
      deploymentStore.selectedClusterId &&
      availableClusters.some(({ uuid }) => uuid === deploymentStore.selectedClusterId);

    if (!isSelectedClusterPresentInClusters) {
      setSelectedClusterId(availableClusters[0]?.uuid);
    }
  }, [availableClusters]);

  const [payload, setPayload, isValidPayload] = useExecutionPayload();

  const selectedCluster = availableClusters?.find(({ uuid }) => uuid === selectedClusterId);

  const isSelectedClusterUnhealthy = selectedCluster?.status.zeebe !== 'Healthy';

  function getDisabledButtonText() {
    if (formLinkStore.diagramHasConflictingLinkedForms) {
      return `${capitalize(deployTarget)} has conflicting linked forms`;
    }

    if (deployOnly) {
      return `${capitalize(deployTarget)} can only be deployed in healthy clusters`;
    }

    return 'Instances can only be started in healthy clusters';
  }

  const hasUnavailableClusters = unavailableClusters?.length > 0;

  let content;

  function getTextForUsersWithBillingPermissions() {
    return (
      <Link
        href={`${organizationStore.consoleDashboardPageUrl}/checkout`}
        target="_blank"
        rel="noreferrer"
        onClick={() => {
          const displayedSalesPlan = organizationStore.currentOrganizationInfo?.salesPlan?.type;
          trackingService.trackCheckoutPage(displayedSalesPlan);
        }}
      >
        Upgrade your plan
      </Link>
    );
  }

  if (isLoading) {
    content = (
      <Styled.Loader>
        <Spinner />
        <Styled.LoaderDescription>Checking cluster availability</Styled.LoaderDescription>
      </Styled.Loader>
    );
  } else if (isErrorLoadingClusters) {
    content = (
      <InlineNotification
        hideCloseButton
        kind="error"
        title="Unable to check cluster availability"
        subtitle="Please wait a few minutes and try again"
        lowContrast
      />
    );
  } else if (isUsingTrial && isTrialExpired) {
    content = (
      <>
        <Styled.ExpiredTrialHeader>
          Upgrade plan to {deployOnly ? `deploy ${deployTarget}` : 'start instance'}
        </Styled.ExpiredTrialHeader>
        <Styled.ExpiredTrialText>
          Clusters are not available on the Modeling plan.{' '}
          {hasBillingPermission
            ? getTextForUsersWithBillingPermissions()
            : 'Contact the admin or owner to upgrade your plan'}{' '}
          in order to create a cluster and {deployOnly ? `deploy ${pluralize(deployTarget, 2)}` : 'start instances'}.
        </Styled.ExpiredTrialText>
      </>
    );
  } else if (!isAllowed) {
    content = <NoPermissionMessage deployOnly={deployOnly} />;
  } else {
    content = (
      <>
        <ClusterSelection
          clusters={availableClusters}
          deployOnly={deployOnly}
          onClose={onClose}
          payload={payload}
          setPayload={setPayload}
          isValidPayload={isValidPayload}
        />

        {hasUnavailableClusters && <ClusterSelection clusters={unavailableClusters} deployOnly disabled />}
      </>
    );
  }

  const hasAvailableAction = !isUsingTrial || !isTrialExpired || hasBillingPermission;

  const onCloseHandler = (selectedCluster) => {
    const zeebeVersion = selectedCluster?.generation?.versions?.zeebe;
    isLinkingImprovementsModalVisibleStore.setSelectedClusterVersion(getSanitizedZeebeVersionString(zeebeVersion));
    onClose({
      hasBeenExecutedOnClusterLT8_4: isLinkingImprovementsModalVisibleStore.isClusterVersionLT8_4(zeebeVersion)
    });
  };

  const handleDeployClick = async () => {
    setIsSubmitting(true);

    deployOnly
      ? await deploymentStore.deploy(onCloseHandler)
      : await deploymentStore.execute(onCloseHandler, payload.trim() ? JSON.parse(payload) : {});

    setIsSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <Dialog.Header>
        <Dialog.Title>{deployOnly ? `Deploy ${deployTarget}` : 'Start instance'}</Dialog.Title>
      </Dialog.Header>
      <Dialog.Content>{content}</Dialog.Content>
      <Dialog.Footer>
        {isSubmitting ? (
          <SubmitInProgress deployOnly={deployOnly} />
        ) : (
          <>
            <Button variant={hasAvailableAction ? 'secondary' : 'primary'} onClick={onClose}>
              {hasAvailableAction ? 'Cancel' : 'Close'}
            </Button>
            {isUsingTrial && isTrialExpired && hasBillingPermission && (
              <Styled.SubscribeLink
                href={`https://console.${config.camundaCloudBaseDomain}/org/${currentOrganization.id}/checkout`}
              >
                <Button>Upgrade now</Button>
              </Styled.SubscribeLink>
            )}
            {(!isUsingTrial || !isTrialExpired) && (
              <div
                title={
                  isSelectedClusterUnhealthy || formLinkStore.diagramHasConflictingLinkedForms
                    ? getDisabledButtonText()
                    : ''
                }
              >
                <Button
                  onClick={handleDeployClick}
                  disabled={
                    !isAllowedToDeployToSelectedCluster ||
                    !availableClusters ||
                    availableClusters.length === 0 ||
                    !isValidPayload ||
                    isSelectedClusterUnhealthy ||
                    !isAllowed ||
                    isFormLinkingLoading ||
                    formLinkStore.diagramHasConflictingLinkedForms
                  }
                  data-test="deploy-action"
                >
                  {deployOnly ? 'Deploy' : 'Run'}
                </Button>
              </div>
            )}
          </>
        )}
      </Dialog.Footer>
    </Dialog>
  );
};

ExecuteModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  mode: PropTypes.oneOf(['deploy', 'execute'])
};

ExecuteModal.defaultProps = {
  open: false,
  onClose: () => {}
};

export default observer(ExecuteModal);

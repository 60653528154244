/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Diagram } from 'icons';
import { BPMN, IMPORT_DIALOG_SOURCE } from 'utils/constants';
import { extractFileNameFromResourceUrl, prepareOutputForSingleTemplate } from 'components/ImportModal/ImportHelpers';

import BaseImportService from './BaseImportService';

class ImportProcessTemplateService extends BaseImportService {
  async prepareOutput(template) {
    return prepareOutputForSingleTemplate(template, BPMN);
  }

  async import({ selectedProject, resourcesMetadata }) {
    return this.importNoDuplicateCheck({
      selectedProject,
      resourcesMetadata,
      source: IMPORT_DIALOG_SOURCE
    });
  }

  extractFileName(template, resource) {
    return extractFileNameFromResourceUrl(template, resource);
  }

  // eslint-disable-next-line no-unused-vars
  extractIcon(_template, _resource) {
    return <Diagram width="20" height="20" alt={`Process template logo`} />;
  }

  executePostImportAction(history, _selectedProject, importedProcesses) {
    this.#goToProcess(history, importedProcesses[0].id);
  }

  async fetchResources(listOfResources) {
    return super.fetchResources(listOfResources);
  }

  #goToProcess(history, processId) {
    history.push(`/diagrams/${processId}`);
  }
}

export default new ImportProcessTemplateService();

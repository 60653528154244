/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { autorun } from 'mobx';

import { organizationStore, projectStore } from 'stores';
import hasAccess, { actions } from 'utils/user-access';
import { gitSettingsStore } from 'features/git-sync';

function useUserCapabilities() {
  const [userCapabilities, setUserCapabilities] = useState({
    hasElevatedOrganizationPermissions: false,
    canSetupProcessApplicationSettings: false,
    canEditSensitiveFields: false,
    canReplacePrivateKey: false
  });

  useEffect(() => {
    const disposer = autorun(() => {
      const { hasElevatedOrganizationPermissions } = organizationStore;
      const { project } = projectStore;
      const canSetupProcessApplicationSettings = hasAccess(project, actions.MODIFY_PROJECT);
      const canEditSensitiveFields = hasElevatedOrganizationPermissions && canSetupProcessApplicationSettings;
      const canReplacePrivateKey =
        canEditSensitiveFields &&
        gitSettingsStore.getCurrentSetting('pemKey') &&
        gitSettingsStore.isSettingSaved('pemKey');

      setUserCapabilities({
        hasElevatedAccess: hasElevatedOrganizationPermissions,
        canSetupProcessApplicationSettings,
        canEditSensitiveFields,
        canReplacePrivateKey
      });
    });

    return () => disposer();
  }, []);

  return userCapabilities;
}

export default useUserCapabilities;

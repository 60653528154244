/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { InlineNotification } from '@carbon/react';

import { processApplicationStore } from 'stores';

export default function HealthyClusterRequiredNotification({ deployOnly }) {
  const { fromAProcessApplication } = processApplicationStore;
  const target = fromAProcessApplication ? 'Process application' : 'Diagram';
  return (
    <InlineNotification
      subtitle={
        deployOnly
          ? `${target} can only be deployed in healthy clusters`
          : 'Instances can only be started in healthy clusters'
      }
      kind="warning"
      lowContrast
      hideCloseButton
    />
  );
}

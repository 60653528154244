/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { EntityList as BaseEntityList } from 'components';
import { Sidebar } from 'primitives';

export const EntityList = styled(BaseEntityList)`
  --header-background: white;
  --padding: 15px;
`;

export const SidebarOuter = styled(Sidebar)`
  width: 450px;
`;

export const CollaboratorSidebarTitle = styled(Sidebar.Title)`
  background-color: var(--cm-color-white-base);
`;

export const CollaboratorSidebarContent = styled.div`
  display: flex;
  height: calc(100% - 40px);
  position: relative;
`;

export const EmptyStateWrapper = styled.div`
  position: absolute;
  top: 185px;
`;

export const Icon = styled.span`
  margin-right: var(--spacing-small);
`;

export const NotificationContainer = styled.div`
  padding: 10px 10px 0 10px;
`;

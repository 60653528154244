/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Pagination } from '@carbon/react';
import { CloudDownload, Launch } from '@carbon/icons-react';
import { useHistory } from 'react-router-dom';

import { notificationStore, projectStore } from 'stores';
import { importDelegatorService, PublishAction } from 'components/ImportModal';

import browseBlueprintsService from './BrowseBlueprintsService';
import * as Styled from './BrowseBlueprintsModalList.styled';
import BrowseBlueprintsModalNotFound from './BrowseBlueprintsModalNotFound';

export const BrowseBlueprintsModalList = ({
  allBlueprints,
  blueprints,
  handleClose,
  errors,
  page,
  pageSize,
  totalItems,
  setPage,
  setPageSize,
  setBlueprints,
  isBlueprintsLoadingError,
  searchValue,
  setIsLoading
}) => {
  const { project } = projectStore;
  const history = useHistory();
  /**
   * Load the blueprint details after user clicks on 'Use blueprint' button.
   */
  const handleImportBlueprint = async (blueprintId) => {
    setIsLoading(true);
    const fetchedTemplates = await browseBlueprintsService.fetchTemplate(blueprintId);
    await importBlueprint(fetchedTemplates);
    setIsLoading(false);
    handleClose();
  };

  const importBlueprint = async (resourcesToImport) => {
    try {
      const { errors, metadata } = await importDelegatorService.fetchResources(resourcesToImport);
      if (errors?.length > 0) {
        notificationStore.showError(
          "Couldn't fetch resource. Please try again. If the problem persists, please get in touch with the support team"
        );
        return;
      }
      const requestObject = {
        selectedProject: project,
        resourcesMetadata: metadata,
        publishAction: PublishAction.Publish
      };
      const importedFiles = await importDelegatorService.import(requestObject);
      importDelegatorService.executePostImportAction(history, project, importedFiles);
    } catch (err) {
      console.error(err);
      notificationStore.showError(
        `There was a problem while trying to add the ${resourcesToImport}. Please try again.`
      );
    }
  };

  return (
    <>
      {blueprints?.length > 0 ? (
        <>
          {blueprints.map((blueprint) => {
            return (
              <Styled.BlueprintCard key={blueprint.id}>
                <Styled.BlueprintDetails>
                  <Styled.BlueprintName data-test="template-name">{blueprint.name}</Styled.BlueprintName>

                  <Styled.BlueprintCreatedBy data-test="template-created-metadata">
                    By {blueprint.creatorName}
                  </Styled.BlueprintCreatedBy>
                  <Styled.BlueprintDescription>{blueprint.overview}</Styled.BlueprintDescription>
                  <Styled.BlueprintDetailLink>
                    <Styled.ExternalLink target="_blank" href={blueprint.documentationUrl}>
                      {'More details'}
                      <Styled.ResourceLink>
                        <Launch />
                      </Styled.ResourceLink>
                    </Styled.ExternalLink>
                  </Styled.BlueprintDetailLink>
                  <Styled.UseBlueprintContainer>
                    <Styled.UseBlueprintButton
                      kind="tertiary"
                      renderIcon={CloudDownload}
                      onClick={() => {
                        handleImportBlueprint(blueprint.id);
                      }}
                      iconDescription="Use Blueprint"
                    >
                      Use Blueprint
                    </Styled.UseBlueprintButton>
                  </Styled.UseBlueprintContainer>
                </Styled.BlueprintDetails>
                <Styled.BlueprintPreview>
                  <Styled.BlueprintImage src={blueprint.overviewImageUrl} alt="Blueprint" />
                </Styled.BlueprintPreview>
              </Styled.BlueprintCard>
            );
          })}
          <Pagination
            page={page}
            onChange={(pageObj) => {
              setPage(pageObj.page);
              setPageSize(pageObj.pageSize);
              setBlueprints(
                allBlueprints?.slice(pageObj.pageSize * (pageObj.page - 1), pageObj.pageSize * pageObj.page) || []
              );
            }}
            pageSize={pageSize}
            pageSizes={[10, 20, 30]}
            totalItems={totalItems}
          />
        </>
      ) : (
        <BrowseBlueprintsModalNotFound
          errors={errors}
          searchValue={searchValue}
          isBlueprintsLoadingError={isBlueprintsLoadingError}
        />
      )}
    </>
  );
};

export default observer(BrowseBlueprintsModalList);

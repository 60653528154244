/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { StructuredListCell, StructuredListRow, Tag } from '@carbon/react';

import { milestoneStore } from 'stores';
import selectAndScrollToElement from 'utils/modeler-utils/select-and-scroll-to-element';

import { LIST_TYPES, listTypeConfig } from './helpers';
import * as Styled from './BPMNChangeList.styled';

export default function ChangeList({ list, type }) {
  return (
    list &&
    Object.keys(list).map((key) => (
      <StructuredListRow key={key} onClick={() => selectAndScrollToElement(milestoneStore.modeler, key)} selection>
        <StructuredListCell>
          {(type === LIST_TYPES.CHANGED ? list[key]?.model?.name : list[key]?.name) || key}
        </StructuredListCell>
        <Styled.TagCell>
          <Tag className="some-class" type={listTypeConfig[type].tagType}>
            {listTypeConfig[type].label}
          </Tag>
        </Styled.TagCell>
      </StructuredListRow>
    ))
  );
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import pluralize from 'utils/pluralize';

import * as Styled from './ContainedResourcesNotification.styled';

export default function ContainedResourcesNotification({ resources = 1 }) {
  return (
    <>
      <Styled.Title>Resources</Styled.Title>
      <Styled.ActionableNotification
        inline
        title={`The application contains ${resources} ${pluralize('resource', resources)}`}
        subtitle={'Every resource within the process application will be automatically deployed.'}
        kind="info"
        lowContrast
        hideCloseButton
        actionButtonLabel="Learn more"
        onActionButtonClick={() =>
          window.open(
            'https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#deploy-a-process-application'
          )
        }
        hasFocus={false}
      />
    </>
  );
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { parseXML } from 'utils/web-modeler-diagram-parser';
import { BPMN, DMN, UNKNOWN, FORM, CONNECTOR_TEMPLATE } from 'utils/constants';

/**
 * Returns a file type based on the given file extension or content. If a XML
 * file is provided, the type is detected by parsing the content, because both DMN and
 * BPMN diagrams can have the `.xml` extension.
 *
 * @param {String} extension The file extension.
 * @param {String} content The file's content.
 * @returns {String} The file type, e.g. `BPMN`
 */
export const getFileTypeByExtensionOrContent = (extension, content) => {
  switch (extension) {
    case 'json': {
      try {
        const json = JSON.parse(content);

        if (json.components && json.type) {
          return FORM;
        } else if (json.appliesTo) {
          return CONNECTOR_TEMPLATE;
        } else {
          return UNKNOWN;
        }
      } catch (ex) {
        return UNKNOWN;
      }
    }
    case 'bpmn':
      return BPMN;
    case 'form':
      return FORM;
    case 'dmn':
      return DMN;
    case 'xml':
    default: {
      try {
        const { documentElement } = parseXML(content);

        if (documentElement.namespaceURI.includes(BPMN)) {
          return BPMN;
        } else if (documentElement.namespaceURI.includes(DMN)) {
          return DMN;
        } else {
          return UNKNOWN;
        }
      } catch (ex) {
        return UNKNOWN;
      }
    }
  }
};

/**
 * Reads given files and returns an object with their name, size, type, and parsed content.
 *
 * @param {FileList} files
 * @returns {Promise<Object>}
 */
export default function readFiles(files) {
  const filePromises = Array.from(files).map((file) => {
    return new Promise((resolve) => {
      const reader = new FileReader();

      reader.onload = (evt) =>
        resolve({
          name: file.name,
          size: file.size,
          content: evt.target.result,
          type: getFileTypeByExtensionOrContent(file.name.split(/\./g).pop(), evt.target.result)
        });

      reader.readAsText(file);
    });
  });

  return Promise.all(filePromises);
}

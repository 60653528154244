/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { CrossCircle, Warning, InformationCircle } from 'icons';

import Errors from './Errors';

export default function ErrorIcon({ category }) {
  return (
    <Errors.Icon>
      {category === 'error' && <CrossCircle title="Error" />}
      {category === 'warn' && <Warning title="Warning" />}
      {category === 'info' && <InformationCircle title="Information" />}
    </Errors.Icon>
  );
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';

import * as Styled from './BrowseBlueprintsModal.styled';
import BrowseBlueprintsModalListContainer from './BrowseBlueprintsModalListContainer';
import BrowseBlueprintsModalLoading from './BrowseBlueprintsModalLoading';
import BrowseBlueprintsModalLeftFilters from './BrowseBlueprintsModalLeftFilters';
import browseBlueprintsService from './BrowseBlueprintsService';

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_SELECTED_BLUEPRINT_TYPE = 'All blueprints';

export const BrowseBlueprintsModal = ({ isOpen, onClose }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isBlueprintsLoading, setIsBlueprintsLoading] = useState(false);
  const [isBlueprintsLoadingError, setIsBlueprintsLoadingError] = useState(false);
  const [blueprintsInCurrentPage, setBlueprintsInCurrentPage] = useState({});
  const [allBlueprints, setAllBlueprints] = useState({});
  const [availableFilters, setAvailableFilters] = useState([]);
  const [filters, setFilters] = useState({});
  const [errors, setErrors] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [activeButton, setActiveButton] = useState('0');
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [totalItems, setTotalItems] = useState(0);
  const [selectedBlueprintType, setSelectedBlueprintType] = useState(DEFAULT_SELECTED_BLUEPRINT_TYPE);

  const disposeState = () => {
    // We need to reset the state when the modal is closed, hence the timeout (the modal is animated).
    setTimeout(() => {
      setActiveButton('');
      setBlueprintsInCurrentPage([]);
      setAllBlueprints([]);
      setPage(1);
      setPageSize(DEFAULT_PAGE_SIZE);
      setTotalItems(0);
      setSelectedBlueprintType(DEFAULT_SELECTED_BLUEPRINT_TYPE);
      setAvailableFilters([]);
      setIsLoading(false);
      setIsBlueprintsLoading(false);
      setIsBlueprintsLoadingError(false);
      setFilters({});
      setSearchValue('');
      setErrors([]);
    }, 500);
  };

  const handleClose = () => {
    disposeState();
    onClose();
  };

  /**
   * Load all data necessary for showing the modal.
   */
  useEffect(() => {
    if (!isOpen) {
      return;
    }
    loadMarketplaceData();
  }, [isOpen]);

  /**
   * Load the blueprints
   */
  const loadBlueprints = async () => {
    setIsBlueprintsLoadingError(false);
    try {
      const blueprintsData = await browseBlueprintsService.fetchBlueprints(filters, searchValue);
      setAllBlueprints(blueprintsData?.items);
      let blueprintsInCurrentPageData = [];
      if (blueprintsData?.items) {
        const pageItemsFrom = pageSize * (page - 1);
        if (pageItemsFrom > blueprintsData.items.length) {
          setPage(1);
          blueprintsInCurrentPageData = blueprintsData.items.slice(0, pageSize);
        } else {
          const pageItemsTo = pageSize * page;
          blueprintsInCurrentPageData = blueprintsData.items.slice(pageItemsFrom, pageItemsTo);
        }
      } else {
        blueprintsInCurrentPageData = [];
      }
      setBlueprintsInCurrentPage(blueprintsInCurrentPageData);
      setTotalItems(blueprintsData?.items?.length ? blueprintsData?.items?.length : 0);
    } catch (err) {
      setErrors([err]);
      setIsBlueprintsLoadingError(true);
    }
  };

  /**
   * Load the blueprints with loading indicator
   */
  const loadBlueprintsWithLoadingIndicator = async () => {
    setIsBlueprintsLoading(true);
    await loadBlueprints();
    setIsBlueprintsLoading(false);
  };

  /**
   * Load filters and blueprints.
   */
  const loadMarketplaceData = async () => {
    setIsLoading(true);
    await loadAvailableFilters();
    await loadBlueprints();
    setIsLoading(false);
  };

  /**
   * Load the filters.
   */
  const loadAvailableFilters = async () => {
    try {
      const filtersData = await browseBlueprintsService.fetchFilters();
      setAvailableFilters(filtersData);
    } catch (err) {
      setErrors([err]);
      setIsBlueprintsLoadingError(true);
    }
  };

  /**
   * If filter changes we have to refresh the process blueprints list.
   */
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    loadBlueprintsWithLoadingIndicator();
  }, [filters]);

  /**
   * If searchValue changes we have to refresh the process blueprints list.
   */
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    loadBlueprintsWithLoadingIndicator();
  }, [searchValue]);

  const getModalBody = () => {
    return (
      <div>
        <BrowseBlueprintsModalLeftFilters
          availableFilters={availableFilters}
          activeButton={activeButton}
          setActiveButton={setActiveButton}
          setSelectedBlueprintType={setSelectedBlueprintType}
          filters={filters}
          setFilters={setFilters}
        />
        <Styled.BrowseBlueprintsContent>
          <BrowseBlueprintsModalListContainer
            isBlueprintsLoading={isBlueprintsLoading}
            filters={filters}
            availableFilters={availableFilters}
            setFilters={setFilters}
            setSearchValue={setSearchValue}
            allBlueprints={allBlueprints}
            blueprints={blueprintsInCurrentPage}
            errors={errors}
            handleClose={handleClose}
            page={page}
            pageSize={pageSize}
            totalItems={totalItems}
            setPage={setPage}
            setPageSize={setPageSize}
            setBlueprints={setBlueprintsInCurrentPage}
            isBlueprintsLoadingError={isBlueprintsLoadingError}
            searchValue={searchValue}
            setIsLoading={setIsLoading}
          />
        </Styled.BrowseBlueprintsContent>
      </div>
    );
  };

  return (
    <Styled.BrowseBlueprintsDialog
      open={isOpen}
      onClose={handleClose}
      size="lg"
      className="overflow-visible"
      aria-label="Browse Blueprints Dialog"
    >
      <>
        {isLoading ? (
          <BrowseBlueprintsModalLoading />
        ) : (
          <>
            <Styled.BrowseBlueprintsHeader closeModal={handleClose}>
              <Styled.SelectedBlueprintTypeHeader>{selectedBlueprintType}</Styled.SelectedBlueprintTypeHeader>
            </Styled.BrowseBlueprintsHeader>
            <Styled.BrowseBlueprintsModalBody>
              <>{getModalBody()}</>
            </Styled.BrowseBlueprintsModalBody>
          </>
        )}
      </>
    </Styled.BrowseBlueprintsDialog>
  );
};

BrowseBlueprintsModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

BrowseBlueprintsModal.defaultProps = {
  isOpen: false,
  onClose: () => {}
};

export default observer(BrowseBlueprintsModal);

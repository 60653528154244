/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import throttle from 'lodash/throttle';

import { notificationStore } from 'stores';
import { authService } from 'services';
import { version } from 'utils/version';
import {
  ASSET_REFRESH_DELAY,
  ERROR_REASON_MAINTENANCE,
  HTTP_DEBOUNCE_DELAY,
  STATUS_ASSET_REFRESH,
  STATUS_UNAUTHORIZED
} from 'utils/constants';
import localStorage from 'utils/localstorage';
import { STATUS_SERVICE_UNAVAILABLE } from 'Server/util/shared-utils';
import config from 'utils/config';

import trackingService from './TrackingService';
import tracingService from './TracingService';

const FORCE_RELOGIN_MESSAGE = 'Your session expired. You will be logged back in automatically.';
const STATUS_ASSET_REFRESH_MESSAGE = 'The Modeler is updating. Please wait just a few seconds for updates to complete.';

/**
 * The base service class, which all other services inherit their methods from.
 */
export default class Service {
  // Each specialized service can define its own long-standing endpoints
  LONG_STANDING_ENDPOINTS = [];

  /**
   * Sends a request to the backend using the Fetch API. The returned response
   * will be parsed as JSON if applicable.
   * The returned promise resolves if the status code is between 200 and 299. Otherwise,
   * the promise will reject with the status code and some additional information.
   *
   * @param {String} method The HTTP method.
   * @param {String} path The URL path of the endpoint.
   * @param {Object} [body] The optional body with request options.
   * @returns {Promise}
   */
  request(method, path, body) {
    return new Promise((resolve, reject) => {
      authService.getToken().then((token) => {
        const fetchOptions = {
          method,
          body: JSON.stringify(body),
          ...Service.httpOptions(token)
        };

        if (this.#isLongStandingRequest(path)) {
          fetchOptions.signal = AbortSignal.timeout(config.apiTimeout);
        }

        const baseUrl = config.modelerUrl;

        fetch(this.getPreparedURL(`${baseUrl}${path}`), fetchOptions)
          .then(async (response) => {
            if (response.status === 408) {
              return reject({
                status: 408,
                reason: 'Request timeout'
              });
            } else if (response.status === STATUS_ASSET_REFRESH) {
              handleAppRefresh();
              // NOTE: We don't want to resolve with a response that can have side-effects in the consumer
              // this because an updated backend will return data that is incompatible with an outdated frontend
              return;
            } else if (await this.isRestapiInMaintenanceMode(response)) {
              this.handleRestapiInMaintenanceMode(response);
              // reject with custom object (instead of response) because the error reason is also checked in
              // AuthService.saas.js but can only be consumed from the response body once
              return reject({
                status: STATUS_SERVICE_UNAVAILABLE,
                reason: ERROR_REASON_MAINTENANCE
              });
            } else if (response.status === STATUS_UNAUTHORIZED && !path.startsWith('/internal-api/shares/')) {
              this.handleUnauthorized();
              return reject(response);
            } else {
              const body = await this.getResponseBody(response);

              if (response.ok) {
                return resolve(body);
              } else {
                this.logError(response);
                return reject({ ...body, status: response.status });
              }
            }
          })
          .catch((error) => {
            if (error.message?.includes('signal timed out')) {
              return reject({
                status: 408,
                reason: 'Request timeout'
              });
            }

            tracingService.traceError(error);
            return reject(error);
          });
      });
    });
  }

  /**
   * Sends a GET request to the backend.
   *
   * @param {String} path The URL path of the endpoint.
   * @returns {Promise}
   */
  get(path) {
    return this.request('GET', path);
  }

  /**
   * Sends a POST request to the backend.
   *
   * @param {String} path The URL path of the endpoint.
   * @param {Object} [body] The optional body with request options.
   * @returns {Promise}
   */
  post(path, body) {
    return this.request('POST', path, body);
  }

  /**
   * Sends a PATCH request to the backend.
   *
   * @param {String} path The URL path of the endpoint.
   * @param {Object} [body] The optional body with request options.
   * @returns {Promise}
   */
  patch(path, body) {
    return this.request('PATCH', path, body);
  }

  /**
   * Sends a PUT request to the backend.
   * @param path - The URL path of the endpoint.
   * @param body - The optional body with request options.
   * @returns {Promise}
   */
  put(path, body) {
    return this.request('PUT', path, body);
  }

  /**
   * Sends a DELETE request to the backend.
   *
   * @param {String} path The URL path of the endpoint.
   * @param {Object} [body] The optional body with request options.
   * @returns {Promise}
   */
  delete(path, body) {
    return this.request('DELETE', path, body);
  }

  /**
   * Logs an HTTP error to the tracing service. Not all errors are logged,
   * but only 400, 408, 422, and 429.
   *
   * @param {Object} response The response that came back from the backend.
   * @param customMessage Add additional context to the error logged
   * @returns {void}
   */
  logError(response, customMessage) {
    if ([400, 408, 422, 429].includes(response.status)) {
      const error = new Error(
        `Unexpected HTTP error occurred: ${response.url} ${response.status} ${response.statusText} ${JSON.stringify(response.body)}`
      );
      tracingService.traceError(error, customMessage);
    }
  }

  /**
   * Parses and returns the response body from the backend. If possible, it returns
   * the data as JSON.
   *
   * @param {Promise} response The Fetch API's response promise.
   * @returns {Promise|void}
   */
  async getResponseBody(response) {
    try {
      const contentType = response.headers.get('Content-Type');
      const contentDisposition = response.headers.get('Content-Disposition');

      if (contentType?.includes('application/json')) {
        const json = await response.json();

        if (response.ok) {
          return json.data ? json.data : json;
        }

        return json.errors ? json.errors : json;
      } else if (contentDisposition?.startsWith('attachment')) {
        // Handle downloading blob content, e.g., a file
        return await response.blob();
      } else {
        return await response.text();
      }
    } catch (e) {
      return undefined;
    }
  }

  /**
   * Forces a user to log in again when the session is expired.
   */
  handleUnauthorized() {
    notificationStore.showNotification({
      message: FORCE_RELOGIN_MESSAGE
    });
    trackingService.trackForceRelogin();
    window.location.assign(
      window.location.pathname
        ? `${config.modelerUrl}/login?returnUrl=${encodeURIComponent(window.location.pathname)}`
        : `${config.modelerUrl}/login`
    );
  }

  /**
   * Returns a fully built URL, containing search parameters for the asset
   * refresh.
   *
   * @param {String} path The URL path to visit.
   * @returns {String}
   */
  getPreparedURL(path) {
    const url = new URL(path);
    const now = Math.round(new Date().getTime() / 1000);

    try {
      const lastRefresh = Number(localStorage.getItem('lastRefresh') || now);
      const lastRefreshAge = now - lastRefresh;

      url.searchParams.append('version', version);
      url.searchParams.append('lastRefreshAge', lastRefreshAge);
    } catch (ex) {
      console.info("Can't access `localStorage` due to Chrome's CSP.");
    }

    return url.toString();
  }

  /**
   * Returns true if the restapi is in maintenance mode.
   * @param {Object} response The error response with status and body received from the restapi
   * @returns {Boolean}
   */
  async isRestapiInMaintenanceMode(response) {
    if (response.status === STATUS_SERVICE_UNAVAILABLE) {
      const errors = await this.getResponseBody(response);
      return errors && errors[0]?.reason?.includes(ERROR_REASON_MAINTENANCE);
    }
    return false;
  }

  /**
   * Redirects to the maintenance page.
   * @param {Object} response The error response with status and body received from the restapi
   */
  handleRestapiInMaintenanceMode(response) {
    this.logError(response, `restapi is in maintenance mode.`);
    window.location.assign(
      `${config.modelerUrl}/maintenance?returnUrl=${encodeURIComponent(window.location.pathname)}`
    );
  }

  /**
   * Returns the default HTTP options, like headers.
   *
   * @returns {String}
   */
  static httpOptions(token) {
    const options = {
      credentials: 'same-origin',
      headers: {
        Accept: 'application/json; charset=utf-8',
        'Cache-control': 'no-cache',
        'Content-Type': 'application/json; charset=utf-8',
        Pragma: 'no-cache',
        Expires: 0,
        'X-Client-Version': version
      }
    };

    if (token) {
      options.headers.Authorization = `Bearer ${token}`;
    }

    return options;
  }

  /**
   * Returns the debounce delay we use for debouncing some critical http calls.
   *
   * @returns {Number}
   */
  static get debounceDelay() {
    return HTTP_DEBOUNCE_DELAY;
  }

  /**
   * Returns the debounce options we use for debouncing some critical http calls.
   *
   * @returns {Object}
   */
  static get debounceOptions() {
    return { leading: true, trailing: false };
  }

  #isLongStandingRequest(url) {
    return this.LONG_STANDING_ENDPOINTS.some((regex) => regex.test(url));
  }
}

/**
 * Reloads the React client.
 */
const handleAppRefresh = throttle(
  () => {
    notificationStore.showNotification({
      variant: 'info',
      message: STATUS_ASSET_REFRESH_MESSAGE
    });

    trackingService.trackAssetRefresh();

    setTimeout(() => {
      const now = Math.round(new Date().getTime() / 1000);
      localStorage.setItem('lastRefresh', now);

      window.location.reload();
    }, ASSET_REFRESH_DELAY);
  },
  ASSET_REFRESH_DELAY,
  {
    trailing: false
  }
);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import {
  Accordion,
  AccordionItem,
  Button,
  Column,
  ComposedModal,
  FlexGrid,
  ModalBody,
  ModalHeader,
  MultiSelect,
  Search,
  SideNav
} from '@carbon/react';
import styled from 'styled-components';

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const BrowseBlueprintsDialog = styled(ComposedModal)`
  .cds--modal-container {
    background: white;
    height: 100%;
  }

  .cds--modal-content {
    margin-bottom: 0;
  }
`;

export const BrowseBlueprintsHeader = styled(ModalHeader)`
  margin-bottom: 0;
`;

export const NoGutterFlexGrid = styled(FlexGrid)`
  padding-inline: 1rem;
`;

export const BrowseBlueprintsModalBody = styled(ModalBody)`
  padding-top: 0px !important;
  padding-bottom: 0px !important;
`;

export const LoadingRow = styled(Row)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const BlueprintListLoadingRow = styled(LoadingRow)`
  padding-left: 16rem;
  padding-top: 60px;
`;

export const LoadingColumn = styled(Column)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SelectedBlueprintTypeHeader = styled.h4`
  margin-left: 16rem;
`;

export const BlueprintsSearch = styled(Search)`
  margin-top: 20px;
  .cds--search-input {
    height: 40px;
    background-color: white;
    border-top: 1px solid #c6c6c6;
  }
  .cds--search-close {
    block-size: 40px;
    inline-size: 40px;
  }
`;
styled(Button)`
  display: block;
  margin-left: auto;
  margin-right: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-self: stretch;
  line-height: 16px;
  margin-bottom: 16px;
`;

export const BrowseBlueprintsSideNav = styled(SideNav)`
  background-color: rgb(229, 229, 229);
`;

export const BrowseBlueprintsButton = styled(Button)`
  width: 100%;
  background-color: rgb(229, 229, 229);
  padding-right: 16px;
  color: rgb(22, 22, 22);
  border: none;
  &:hover,
  &:focus {
    background-color: rgba(141, 141, 141, 0.2);
    box-shadow: 3px 0px 0px 0px #0f62fe inset;
    color: #161616;
  }
  &.active {
    background-color: rgba(141, 141, 141, 0.2);
    box-shadow: 3px 0px 0px 0px #0f62fe inset;
    color: #161616;
  }
`;

export const FilterPanelMenuItem = styled.li`
  margin-top: 4px;
`;

export const FilterPanelAccordionButton = styled(BrowseBlueprintsButton)`
  padding-left: 32px;
`;

export const BrowseBlueprintsContent = styled.div`
  padding-left: 16rem;
`;

export const BrowseBlueprintsMultiSelect = styled(MultiSelect)`
  .cds--multi-select {
    border: 1px solid #c6c6c6;
    border-right: none;
  }
`;

export const CondensedColumn = styled(Column)`
  padding-inline-end: 0px !important;
  padding-inline-start: 0px !important;
`;

export const BrowseBlueprintListContainer = styled.div`
  margin-top: 16px;
`;

export const FilterPanelAccordion = styled(Accordion)`
  .cds--accordion__item {
    border: none;
  }
  .cds--accordion__wrapper {
    padding-block: 0;
    padding-block-end: 0;
  }
`;

export const FilterPanelAccordionItem = styled(AccordionItem)`
  .cds--accordion__content {
    padding-inline-end: 0px;
    padding-inline-start: 0px;
  }
`;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Link, TextInput, InlineNotification } from '@carbon/react';
import { useState } from 'react';
import { createPortal } from 'react-dom';

import { notificationStore } from 'stores';

import * as Styled from './ProcessApplicationVersionTagModal.styled';

export default function ProcessApplicationVersionTagModal({
  onRequestClose,
  open,
  numberOfResources,
  handleVersionCreation
}) {
  const numberOfResourcesDescription = numberOfResources ? `all resources (${numberOfResources})` : 'all resources';

  const [processAppVersionTag, setProcessAppVersionTag] = useState('');

  const hasValidVersion = processAppVersionTag.trim().length;

  const onRequestSubmit = () => {
    if (hasValidVersion) {
      handleVersionCreation(processAppVersionTag)
        .then(() => notificationStore.showSuccess('New process application version added to history.'))
        .catch(() =>
          notificationStore.showError(
            'Yikes! Could not create the version for this process application. Please try again later.'
          )
        );
      setProcessAppVersionTag('');
      onRequestClose();
    }
  };

  return createPortal(
    <Styled.ProcessApplicationVersionTagModal
      open={open}
      modalHeading="Create a milestone"
      primaryButtonText="Create"
      primaryButtonDisabled={!hasValidVersion}
      secondaryButtonText="Cancel"
      onRequestSubmit={onRequestSubmit}
      onRequestClose={onRequestClose}
    >
      <Styled.ModalContent>
        <Styled.VersionTagDescription>
          <span>
            Name your milestone with a version tag to track it through the development lifecycle and ensure the right
            version is called.
          </span>
          <Link
            href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/#versioning"
            target="_blank"
          >
            Learn more
          </Link>
        </Styled.VersionTagDescription>

        <TextInput
          data-modal-primary-focus
          id="version-tag"
          labelText="Version tag"
          placeholder="v1.0"
          value={processAppVersionTag}
          onChange={(event) => {
            setProcessAppVersionTag(event.target.value);
          }}
        />

        <InlineNotification kind="info" title="Milestone creation" lowContrast hideCloseButton>
          <label>
            Milestones will be created for <strong>{numberOfResourcesDescription}</strong> in this process application.
          </label>
        </InlineNotification>
      </Styled.ModalContent>
    </Styled.ProcessApplicationVersionTagModal>,
    document.body
  );
}

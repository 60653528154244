/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Tooltip } from 'primitives';

import * as Styled from './ReadOnlyPill.styled';

export default function ReadOnlyPill() {
  return (
    <Tooltip
      title="This resource cannot be modified. Contact your project admin to request more permissions."
      align="bottom"
      justify="right"
    >
      <Styled.Tag type="gray" filter={false}>
        Read-only
      </Styled.Tag>
    </Tooltip>
  );
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component, Fragment } from 'react';
import { observer } from 'mobx-react';
import { runInAction } from 'mobx';

import { Dialog, Button, Typography } from 'primitives';
import { shareService } from 'services';
import { notificationStore } from 'stores';

const SHARE_LINK_REMOVED_MESSAGE = 'Shared link has been removed.';

class DestroyLinkControl extends Component {
  diagram = this.props.diagram;
  onCancel = this.props.onCancel;
  closeParent = this.props.closeParent;

  removeShare = () => {
    shareService
      .destroy(this.diagram.shareId)
      .then(() => {
        this.onCancel();
        this.closeParent();
        runInAction(() => {
          this.diagram.shareId = null;
          this.diagram.passwordProtected = false;
        });

        notificationStore.showSuccess(SHARE_LINK_REMOVED_MESSAGE);
      })
      .catch(() => notificationStore.showError('Could not remove this share.'));
  };

  render() {
    return (
      <Fragment>
        <Dialog.Header>
          <Dialog.Title data-test="share-title">Remove share link</Dialog.Title>
        </Dialog.Header>

        <Dialog.Content>
          <Typography>Remove public access by destroying the link. Anyone with the link will lose access.</Typography>
        </Dialog.Content>

        <Dialog.Footer>
          <Button variant="secondary" onClick={this.props.onCancel}>
            Cancel
          </Button>
          <Button onClick={this.removeShare} isDanger data-test="confirm-remove-button">
            Remove link
          </Button>
        </Dialog.Footer>
      </Fragment>
    );
  }
}

export default observer(DestroyLinkControl);

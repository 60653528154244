/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Checkbox as CheckboxPrimitive } from 'primitives';

export const Checkbox = styled(CheckboxPrimitive)`
  margin-right: auto;
  opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
`;

export const LeftAction = styled.span`
  display: flex;
  align-items: center;
  flex-basis: ${({ $columnWidth }) => `${$columnWidth}px;`}
  flex-shrink: 0;
  padding: 0 10px;
  color: var(--grey-darken-33);
`;

export const RightAction = styled.span`
  flex-basis: 50px;

  > button {
    opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  }
`;

export const Entity = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--grey-lighten-50);

  cursor: ${({ $hasHover }) => ($hasHover ? 'pointer' : 'default')};

  &:hover {
    ${Checkbox}, ${RightAction} > button {
      opacity: 1;
    }
  }

  ${({ $isLightTheme }) => {
    if ($isLightTheme) {
      return `
        border-bottom: 1px solid var(--silver-darken-87);
        height: 58px;

        &:first-of-type {
          border-top: 1px solid var(--silver-darken-87);
        }
      `;
    }

    return `
      border-radius: 3px;
      background-color: white;
      border: 1px solid var(--silver-darken-87);
      box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
      min-height: 60px;

      &:not(:last-of-type) {
        margin-bottom: 15px;
      }
    `;
  }}

  ${({ $isSelected, $hasHover, $isLightTheme }) => {
    if ($isSelected) {
      return `
        background-color: var(--silver-darken-94);
        border-color: var(--cds-blue-60);
        box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), inset 0 0 0 1px var(--cds-blue-60);
      `;
    }

    if ($hasHover) {
      return `
        &:hover {
          background-color: ${$isLightTheme ? 'var(--silver-darken-94)' : '#fcfcfc'};
        }
      `;
    }
  }}
`;

export const EntityLink = styled(Link)`
  text-decoration: none;
  display: flex;
  flex: 1;
  align-self: stretch;
  align-items: center;
  color: inherit;
`;

export const EntityType = styled.span`
  font-size: 12px;
  color: var(--grey-lighten-50);
`;

export const EntityName = styled.h3`
  color: var(--grey-darken-23);
  font-size: 15px;
  margin: 0;
  font-weight: 500;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Column = styled.span`
  padding: 0 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${({ $disableResponsiveness }) => {
    if (!$disableResponsiveness) {
      return `
        @media (max-width: 1200px) {
          &:last-of-type {
            display: none;
          }
        }
      
        @media (max-width: 1000px) {
          &:nth-last-of-type(2) {
            display: none;
          }
        }
      `;
    }
  }}

  ${({ $isPrimary, $columnWidth = 180 }) => {
    if ($isPrimary) {
      return `
        flex: 1;
        width: 0;
      `;
    }

    return `
      flex: 0 0 ${$columnWidth}px;

      ${
        $columnWidth == 180 &&
        `
        @media (max-width: 1400px) {
          flex-basis: ${$columnWidth * 0.8}px;
        }
      `
      }
    `;
  }}
`;

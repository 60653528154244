/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import { ModalHeader, Column, Loading, Grid, ActionableNotification, Row } from '@carbon/react';
import debounce from 'lodash/debounce';

import { notificationStore } from 'stores';
import { selectedElementStore } from 'App/Pages/Diagram/stores';

import {
  discoverConnectorsService,
  discoverConnectorsStore,
  DiscoverConnectorsModalConnector,
  DiscoverConnectorsModalFilter
} from './';
import * as Styled from './DiscoverConnectorsModal.styled';

export const DiscoverConnectorsModal = ({ isOpen, onClose, actionSource }) => {
  const SEARCH_VALUE_CHANGE_DEBOUNCE = 500;
  const [connectors, setConnectors] = useState([]);
  const [availableFilters, setAvailableFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isConnectorsLoading, setIsConnectorsLoading] = useState(false);
  const [filters, setFilters] = useState({});
  const [searchValue, setSearchValue] = useState('');
  const [isConnectorsLoadingError, setIsConnectorsLoadingError] = useState(false);
  const [errors, setErrors] = useState([]);

  const disposeState = () => {
    // We need to reset the state when the modal is closed, hence the timeout (the modal is animated).
    setTimeout(() => {
      setConnectors([]);
      setAvailableFilters([]);
      setIsLoading(false);
      setIsConnectorsLoading(false);
      setFilters({});
      setSearchValue('');
    }, 500);
  };

  /**
   * Load the left hand side filters.
   */
  const loadAvailableFilters = async () => {
    const filtersData = await discoverConnectorsService.fetchFilters().catch((err) => {
      setErrors([err]);
      setIsConnectorsLoadingError(true);
    });
    setAvailableFilters(filtersData);
  };

  /**
   * Load the connectors.
   */
  const loadConnectors = async () => {
    setIsConnectorsLoadingError(false);
    const connectorsData = await discoverConnectorsService.fetchConnectors(filters, searchValue).catch((err) => {
      setErrors([err]);
      setIsConnectorsLoadingError(true);
    });
    setConnectors(connectorsData);
  };

  /**
   * Load the connectors.
   */
  const loadConnectorsWithLoadingIndicator = async () => {
    setIsConnectorsLoading(true);
    await loadConnectors();
    setIsConnectorsLoading(false);
  };

  /**
   * Load filters and connectors.
   */
  const loadMarketplaceData = async () => {
    setIsLoading(true);
    await loadAvailableFilters();
    await loadConnectors();
    setIsLoading(false);
  };

  /**
   * Initialize the connectors store.
   */
  useEffect(() => {
    discoverConnectorsStore.init();
    return () => discoverConnectorsStore.reset();
  }, []);

  /**
   * Load all data necessary for showing the modal.
   */
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    if (actionSource === 'bpmn-replace' || actionSource === 'element-template-chooser') {
      // if we have data about the selected element we filter the connectors by the type of the selected element
      let selectedElement = selectedElementStore.selectedElement;
      if (selectedElement?.type && selectedElement.type !== 'bpmn:Process') {
        filters.supportedBpmnType = [transformSelectedElementType(selectedElement.type)];
      }
    }
    loadMarketplaceData();
  }, [isOpen]);

  /**
   *
   * @param {String} selectedElementType The original bpmn element type
   * @returns {String} The element type in filter compatible format
   */
  const transformSelectedElementType = (selectedElementType) => {
    if (!selectedElementType) {
      return '';
    }
    let transformedSelectedElementType = selectedElementType;
    if (transformedSelectedElementType.includes('bpmn:')) {
      transformedSelectedElementType = transformedSelectedElementType.split('bpmn:')[1];
    }
    if (transformedSelectedElementType === 'ServiceTask') {
      transformedSelectedElementType = 'Task';
    }
    return lowercaseFirstLetter(transformedSelectedElementType);
  };

  const lowercaseFirstLetter = (str) => {
    return str.length ? str.charAt(0).toLowerCase() + str.slice(1) : str;
  };

  /**
   * Load the connectors details after user clicks on Download to project button.
   */
  const handleImportConnector = async (connectorId, iconUrl) => {
    setIsLoading(true);
    await discoverConnectorsStore.importTemplate(connectorId, iconUrl);
    setIsLoading(false);
    handleClose();
  };

  const handleClose = () => {
    disposeState();
    if (onClose) {
      onClose();
    }
  };

  /**
   * Reset the filters
   */
  const resetFilters = () => {
    setFilters({});
  };

  /**
   * Handle checkbox clicks, and update the filters state object.
   */
  const handleFilterCheckBoxOnclick = (filterGroup, filterValue) => {
    if (filters[filterGroup]?.length > 0) {
      // if there is at least one element in this category
      if (filters[filterGroup]?.includes(filterValue)) {
        // remove if we already have it
        filters[filterGroup] = filters[filterGroup].filter((item) => item !== filterValue);
      } else {
        // add if we don't have it yet
        filters[filterGroup].push(filterValue);
      }
    } else {
      // if there is no element in this category
      filters[filterGroup] = [filterValue];
    }
    setFilters(JSON.parse(JSON.stringify(filters)));
  };

  /**
   * If filter changes we have to refresh the connectors list.
   */
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    loadConnectorsWithLoadingIndicator();
  }, [filters]);

  /**
   * If searchValue changes we have to refresh the connectors list.
   */
  useEffect(() => {
    if (!isOpen) {
      return;
    }

    loadConnectorsWithLoadingIndicator();
  }, [searchValue]);

  /**
   * Search for a connector.
   */
  const searchConnector = debounce(
    (event) => {
      setSearchValue(event.target.value);
    },
    SEARCH_VALUE_CHANGE_DEBOUNCE,
    {
      trailing: true
    }
  );

  /**
   * Copy the error logs stored in errors state variable
   */
  const copyErrorLogs = async () => {
    try {
      await navigator.clipboard.writeText(JSON.stringify(errors, null, 2));
      notificationStore.showSuccess('The error logs have been copied into your clipboard as JSON.');
    } catch (err) {
      notificationStore.showError(
        "Yikes! Couldn't copy the error logs into your clipboard. Make sure that you gave permissions to the browser."
      );
    }
  };

  const getConnectorsNotFoundText = () => {
    return (
      <Styled.NoConnectorsFoundText>
        {searchValue?.length > 0 ? (
          `We couldn't find a match for your search phrase. Please clear the search to see existing resources.`
        ) : (
          <>
            {isConnectorsLoadingError ? (
              <ActionableNotification
                kind="error"
                title="Error"
                subtitle={
                  <span>
                    We are sorry, an error occurred, please try again later. If the problem persists, get in touch with
                    the{' '}
                    <Styled.SupportLink target="_blank" href="https://forum.camunda.io/">
                      support team
                    </Styled.SupportLink>
                    .
                  </span>
                }
                inline
                lowContrast
                hideCloseButton
                actionButtonLabel="Copy error logs for support"
                onActionButtonClick={copyErrorLogs}
              />
            ) : (
              `We couldn't find a match for your filter criteria. Please reset the filters to see existing resources.`
            )}
          </>
        )}
      </Styled.NoConnectorsFoundText>
    );
  };

  const getConnectorTile = (connector) => {
    return (
      <DiscoverConnectorsModalConnector
        connector={connector}
        handleImportConnector={(connectorId, connectorIconUrl) => {
          handleImportConnector(connectorId, connectorIconUrl);
        }}
      />
    );
  };

  const getConnectorsGrid = () => {
    return (
      <Grid narrow>
        {connectors?.items?.length > 0 ? (
          connectors.items.map((connector) => (
            <Column key={connector.id} sm={4} md={8} lg={8}>
              {getConnectorTile(connector)}
            </Column>
          ))
        ) : (
          <Column sm={4} md={8} lg={16}>
            {getConnectorsNotFoundText()}
          </Column>
        )}
      </Grid>
    );
  };

  const getConnectorsLoading = () => {
    return (
      <Styled.ConnectorsLoadingRow>
        <Styled.LoadingColumn sm={4} md={8} lg={16}>
          <Loading withOverlay={false} />
        </Styled.LoadingColumn>
      </Styled.ConnectorsLoadingRow>
    );
  };

  const getConnectorsSearch = () => {
    return (
      <Grid narrow>
        <Column sm={4} md={8} lg={16}>
          <Styled.ConnectorSearch
            size="lg"
            placeholder="Search for a connector"
            labelText="Search"
            closeButtonLabelText="Clear search input"
            id="search-for-a-connector"
            onChange={searchConnector}
          />
        </Column>
      </Grid>
    );
  };

  const getModalConnectorsContainer = () => {
    return (
      <>
        {getConnectorsSearch()}
        {isConnectorsLoading ? <>{getConnectorsLoading()}</> : <>{getConnectorsGrid()}</>}
      </>
    );
  };

  const getFilterPanel = () => {
    return (
      <DiscoverConnectorsModalFilter
        availableFilters={availableFilters}
        filters={filters}
        handleFilterCheckBoxOnclick={(filterGroupValue, filterOptionValue) => {
          handleFilterCheckBoxOnclick(filterGroupValue, filterOptionValue);
        }}
        resetFilters={() => {
          resetFilters();
        }}
      />
    );
  };

  const getModalBody = () => {
    return (
      <Row>
        <Column sm={2} md={3} lg={4}>
          {getFilterPanel()}
        </Column>
        <Column sm={2} md={5} lg={12}>
          {getModalConnectorsContainer()}
        </Column>
      </Row>
    );
  };

  const getLoading = () => {
    return (
      <Styled.LoadingRow>
        <Styled.LoadingColumn sm={4} md={8} lg={16}>
          <Loading withOverlay={false} />
        </Styled.LoadingColumn>
      </Styled.LoadingRow>
    );
  };

  return (
    <Styled.DiscoverConnectorsDialog
      open={isOpen}
      onClose={handleClose}
      size="lg"
      className="overflow-visible"
      aria-label="Discover Connectors Dialog"
    >
      <ModalHeader>
        <Styled.ModalTitle>Browse Marketplace Connectors</Styled.ModalTitle>
      </ModalHeader>
      <Styled.DiscoverConnectorsModalBody>
        <>{isLoading ? <>{getLoading()}</> : <>{getModalBody()}</>}</>
      </Styled.DiscoverConnectorsModalBody>
    </Styled.DiscoverConnectorsDialog>
  );
};

export default observer(DiscoverConnectorsModal);

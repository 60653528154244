/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { DefinitionTooltip as _DefinitionTooltip, Link, Modal } from '@carbon/react';

export const StagesModal = styled(Modal)`
  .cds--modal-container {
    max-width: 640px;
  }
`;

export const StagesModalContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 20px;
`;

export const ContentHeading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const OpenConsoleLink = styled(Link)`
  font-size: 15px;
`;

export const NameWithTag = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: baseline;
`;

export const DefinitionTooltip = styled(_DefinitionTooltip)`
  margin-bottom: 10px;
  button {
    font-size: 14px;
    line-height: 18px;
    font-weight: 600;
    color: var(--cds-text-secondary, #525252);
  }
`;

export const NoClusterText = styled.p`
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  color: var(--cds-text-secondary, #525252);
`;

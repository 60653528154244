/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable, runInAction } from 'mobx';

import { gitSyncService, gitSyncStore, GitSyncTrackingEvents } from 'features/git-sync';
import { CONFIGURED_GIT_PROVIDER, SupportedGitProviders } from 'features/git-sync/constants';
import { processApplicationStore } from 'stores';
import { notificationStore } from 'components/NotificationSystem';

export const GIT_SETTINGS_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  FINISHED: 'finished',
  ERROR: 'error'
};

class GitSettingsStore {
  status = GIT_SETTINGS_STATUS.INACTIVE;
  settings = {};
  localSettingsList = [];
  shouldShowDialog = false;
  shouldInitiateSyncAfterSetup = false;

  initialSettings = {};

  constructor() {
    makeAutoObservable(this);
  }

  init(processApplicationId) {
    this.#fetchSettings(processApplicationId);
  }

  reset() {
    this.shouldShowDialog = false;
    this.shouldInitiateSyncAfterSetup = false;
    this.settings = {};
    this.localSettingsList = [];
    this.initialSettings = {};
    this.status = GIT_SETTINGS_STATUS.INACTIVE;
  }

  open() {
    this.shouldShowDialog = true;
  }

  close() {
    this.shouldShowDialog = false;
    this.status = GIT_SETTINGS_STATUS.INACTIVE;
    this.localSettingsList = [];
  }

  async save() {
    runInAction(() => {
      this.status = GIT_SETTINGS_STATUS.ACTIVE;
    });

    try {
      await gitSyncService.saveSettings({
        processApplicationId: processApplicationStore.processApplication.id,
        settings: this.settings
      });

      runInAction(() => {
        this.status = GIT_SETTINGS_STATUS.FINISHED;
        this.localSettingsList = [];
        this.initialSettings = { ...this.settings };
      });

      GitSyncTrackingEvents.trackGitConnectionSetup();

      setTimeout(() => {
        this.close();
        this.#handleForcedSync();
      }, 1000);
    } catch (error) {
      console.error('Error saving settings', error);
      notificationStore.showError('Something went wrong while saving Git settings');

      runInAction(() => {
        this.status = GIT_SETTINGS_STATUS.ERROR;
      });
    }
  }

  storeSetting(key, value) {
    if (value === this.initialSettings[key]) {
      if (this.localSettingsList.includes(key)) {
        runInAction(() => {
          this.settings[key] = value;
          this.localSettingsList = this.localSettingsList.filter((setting) => setting !== key);
        });

        this.#computeShouldInitiateSyncAfterSetup();
      }
      return;
    }

    runInAction(() => {
      this.settings[key] = value;

      if (!this.localSettingsList.includes(key)) {
        this.localSettingsList.push(key);
      }

      if (!this.shouldInitiateSyncAfterSetup) {
        this.#computeShouldInitiateSyncAfterSetup();
      }
    });
  }

  #computeShouldInitiateSyncAfterSetup() {
    runInAction(() => {
      this.shouldInitiateSyncAfterSetup = this.localSettingsList.some((setting) =>
        SupportedGitProviders[CONFIGURED_GIT_PROVIDER].fieldsThatRequireResync.includes(setting)
      );
    });
  }

  isSettingSaved(key) {
    return !this.localSettingsList.includes(key);
  }

  getCurrentSetting(key) {
    return this.settings[key] || '';
  }

  get hasUnsavedSettings() {
    return this.localSettingsList.length > 0;
  }

  get hasAllRequiredSettings() {
    let requiredSettings = [];
    for (const key in SupportedGitProviders[CONFIGURED_GIT_PROVIDER].validationRules) {
      const field = SupportedGitProviders[CONFIGURED_GIT_PROVIDER].validationRules[key];
      if (field.required) {
        requiredSettings.push(key);
      }
    }
    return requiredSettings.every((setting) => Object.keys(this.initialSettings).includes(setting));
  }

  async #fetchSettings(processApplicationId) {
    try {
      let settings = await gitSyncService.getSettings(processApplicationId);

      if (Object.keys(settings).length === 1 && settings.pemKey === false) {
        // We receive such a payload when there are no settings configured yet.
        // To avoid side-effects we consider it empty.
        settings = {};
      }

      runInAction(async () => {
        this.settings = { ...settings };
        this.initialSettings = { ...settings };
      });
    } catch (error) {
      console.error('Error fetching settings', error);
      notificationStore.showError('Something went wrong while fetching Git settings');
    }
  }

  #handleForcedSync() {
    if (this.shouldInitiateSyncAfterSetup) {
      gitSyncStore.open({ force: true });

      runInAction(() => {
        this.shouldInitiateSyncAfterSetup = false;
      });
    }
  }
}

export default new GitSettingsStore();

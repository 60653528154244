/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { useRef, useEffect } from 'react';
import BpmnViewer from 'camunda-bpmn-js/lib/camunda-cloud/Viewer';

import { Button } from 'primitives';
import { waitForAttach } from 'components/Modeler/bpmn/utils';

import * as Styled from './TemplateTile.styled';

function TemplateTile({
  blank,
  id,
  name,
  createdByName,
  createdAt,
  diagramXml,
  onOpenDetails,
  onTemplateSelected,
  isCreatingTemplate
}) {
  const bpmnContainer = useRef();
  const viewer = useRef();

  useEffect(() => {
    (async () => {
      if (diagramXml && bpmnContainer.current) {
        viewer.current = new BpmnViewer({ container: bpmnContainer.current });

        await waitForAttach(viewer.current);

        try {
          viewer.current.importXML(diagramXml).then(() => {
            viewer.current.get('canvas').zoom('fit-viewport', true);
          });
        } catch (err) {
          console.error(err);
        }
      }
    })();

    return () => {
      if (viewer.current) {
        viewer.current.detach();
      }
    };
  }, []);

  const handleOpenDetailsClick = (event) => {
    event.stopPropagation();
    onOpenDetails();
  };

  const handleTemplateSelectedClick = (event) => {
    event.stopPropagation();
    onTemplateSelected();
  };

  return blank ? (
    <Styled.TemplateTile className="blank" onClick={handleTemplateSelectedClick} data-test="blank-model-tile">
      <Styled.BlankTemplateIcon>+</Styled.BlankTemplateIcon>
      <Styled.TemplateTileBottom>
        <Styled.TemplateTileName>Create a blank model</Styled.TemplateTileName>
      </Styled.TemplateTileBottom>
    </Styled.TemplateTile>
  ) : (
    <Styled.TemplateTile key={`template-${id}`} onClick={handleOpenDetailsClick} data-test="template-tile">
      <Styled.TemplateThumbnail>
        <Styled.DiagramContainer ref={bpmnContainer} />
      </Styled.TemplateThumbnail>

      <Styled.TemplateTileBottom>
        <Styled.TemplateMetadata>
          <span className="company">{createdByName}</span> <span className="date">{createdAt}</span>
        </Styled.TemplateMetadata>

        <Styled.TemplateActions>
          <Button size="small" variant="secondary" onClick={handleOpenDetailsClick} data-test="template-details-btn">
            Details
          </Button>
          <Button
            data-test="template-use-btn"
            size="small"
            variant="primary"
            loading={isCreatingTemplate}
            loaderSize="small"
            lightLoader
            onClick={handleTemplateSelectedClick}
          >
            Use template
          </Button>
        </Styled.TemplateActions>

        <Styled.TemplateTileName data-test="template-title">{name}</Styled.TemplateTileName>
      </Styled.TemplateTileBottom>
    </Styled.TemplateTile>
  );
}

TemplateTile.propTypes = {
  blank: PropTypes.bool,
  id: PropTypes.string,
  name: PropTypes.string,
  createdByName: PropTypes.string,
  createdByUrl: PropTypes.string,
  createdAt: PropTypes.string,
  diagramXml: PropTypes.string,
  isCreatingTemplate: PropTypes.bool,
  onOpenDetails: PropTypes.func.isRequired,
  onTemplateSelected: PropTypes.func.isRequired
};

TemplateTile.defaultProps = {
  blank: false,
  id: '',
  name: '',
  createdByName: '',
  createdByUrl: '',
  createdAt: '',
  diagramXml: '',
  isCreatingTemplate: false,
  onOpenDetails: () => {},
  onTemplateSelected: () => {}
};

export default TemplateTile;

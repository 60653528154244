/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Link } from '@carbon/react';
import { useEffect, useState } from 'react';

import { CreateNewClusterSection } from 'components';
import config from 'utils/config';
import { clustersStore, organizationStore, processApplicationStore } from 'stores';

import { defineStagesModalStore } from './';
import * as Styled from './DefineStagesModal.styled';
import StageClusterSection from './StageClusterSection';

function DefineStagesModal({ onClose = () => {} }) {
  const processApplication = processApplicationStore.processApplication;

  const { devClusters, testClusters, stageClusters, prodClusters } = clustersStore;

  const initialDevClusterId = processApplication.defaultDevClusterId ?? devClusters[0]?.uuid;
  const initialTestClusterId = processApplication.defaultTestClusterId ?? testClusters[0]?.uuid;
  const initialStageClusterId = processApplication.defaultStageClusterId ?? stageClusters[0]?.uuid;
  const initialProdClusterId = processApplication.defaultProdClusterId ?? prodClusters[0]?.uuid;

  const [defaultDevClusterId, setDefaultDevClusterId] = useState(initialDevClusterId);
  const [defaultTestClusterId, setDefaultTestClusterId] = useState(initialTestClusterId);
  const [defaultStageClusterId, setDefaultStageClusterId] = useState(initialStageClusterId);
  const [defaultProdClusterId, setDefaultProdClusterId] = useState(initialProdClusterId);

  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    // Loading the cluster list, when opening the define stages modal
    if (defineStagesModalStore.isVisible) {
      clustersStore.loadClusters();
    }
  }, [defineStagesModalStore.isVisible, clustersStore.loadClusters]);

  useEffect(() => {
    // When the list of clusters gets updated, we verify if the current selected ones are still present.
    // If it is not, then we select the first one, for each stage, by default
    updateSelectedClusterId(devClusters, defaultDevClusterId, setDefaultDevClusterId);
    updateSelectedClusterId(testClusters, defaultTestClusterId, setDefaultTestClusterId);
    updateSelectedClusterId(stageClusters, defaultStageClusterId, setDefaultStageClusterId);
    updateSelectedClusterId(prodClusters, defaultProdClusterId, setDefaultProdClusterId);
  }, [
    devClusters,
    testClusters,
    stageClusters,
    prodClusters,
    defaultDevClusterId,
    defaultTestClusterId,
    defaultStageClusterId,
    defaultProdClusterId
  ]);

  const updateSelectedClusterId = (clusters, selectedClusterId, setSelectedClusterId) => {
    if (!clusters.find((cluster) => cluster.uuid === selectedClusterId)) {
      setSelectedClusterId(clusters[0]?.uuid);
    }
  };

  const closeModal = () => {
    defineStagesModalStore.close();
    onClose();
  };

  const performRequest = async () => {
    try {
      setIsSaving(true);
      await processApplicationStore.updateStages({
        defaultDevClusterId: defaultDevClusterId,
        defaultTestClusterId: defaultTestClusterId,
        defaultStageClusterId: defaultStageClusterId,
        defaultProdClusterId: defaultProdClusterId
      });
      closeModal();
    } catch (err) {
      console.error(err);
    } finally {
      setIsSaving(false);
    }
  };

  const onRequestClose = () => {
    closeModal();
    setDefaultDevClusterId(initialDevClusterId);
    setDefaultTestClusterId(initialTestClusterId);
    setDefaultStageClusterId(initialStageClusterId);
    setDefaultProdClusterId(initialProdClusterId);
  };

  return (
    <Styled.StagesModal
      open={defineStagesModalStore.isVisible}
      modalHeading="Define stages"
      primaryButtonText="Save"
      secondaryButtonText="Cancel"
      onRequestSubmit={performRequest}
      onRequestClose={onRequestClose}
      loadingStatus={isSaving ? 'active' : 'inactive'}
      loadingDescription={'Saving...'}
    >
      <Styled.StagesModalContent>
        <Styled.ContentHeading>
          <section>
            <div>Assign clusters to your stages so that your process easily navigates through the PDLC.</div>
            {/*TODO Update the learn more link: https://github.com/camunda/web-modeler/issues/10299*/}
            <Link
              href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/"
              target="_blank"
            >
              Learn more.
            </Link>
          </section>
          <section>
            <div>To create new clusters, go to Console.</div>
            <CreateNewClusterSection
              creationElement={
                <Styled.OpenConsoleLink
                  href={`https://console.${config.camundaCloudBaseDomain}/org/${organizationStore.currentOrganization.id}/clusters?create=true`}
                  target="_blank"
                >
                  Open Console
                </Styled.OpenConsoleLink>
              }
            />
          </section>
        </Styled.ContentHeading>
        <StageClusterSection
          stageName="development"
          tooltipDefinition="The development stage where new software features and changes are created and tested."
          tagName="dev"
          clusters={devClusters}
          onClusterSelect={setDefaultDevClusterId}
          selectedCluster={defaultDevClusterId}
        />
        <StageClusterSection
          stageName="testing"
          tooltipDefinition="The testing stage for quality checks, ensuring software meets defined standards before release."
          tagName="test"
          clusters={testClusters}
          onClusterSelect={setDefaultTestClusterId}
          selectedCluster={defaultTestClusterId}
        />
        <StageClusterSection
          stageName="staging"
          tooltipDefinition="The staging stage for controlled testing where changes are validated before deployment to production."
          tagName="stage"
          clusters={stageClusters}
          onClusterSelect={setDefaultStageClusterId}
          selectedCluster={defaultStageClusterId}
        />
        <StageClusterSection
          stageName="production"
          tooltipDefinition="The production stage indicates the live system with the latest software, deployable only by admins and organization owners through the interface."
          tagName="prod"
          clusters={prodClusters}
          onClusterSelect={setDefaultProdClusterId}
          selectedCluster={defaultProdClusterId}
        />
      </Styled.StagesModalContent>
    </Styled.StagesModal>
  );
}

export default observer(DefineStagesModal);

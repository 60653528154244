/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import checkForRemovedTargetOrSource from 'utils/diffing/check-for-removed-target-or-source';

export default function drawMarkers(changes, canvas) {
  const cleanup = [];

  changes.new.forEach((newElement) => {
    const elementId = newElement.id;
    const elementType = newElement.type;

    const element = document.querySelector(`[data-element-id="${elementId}"] > .djs-visual > path`);

    // maybe the user navigated away from the dom, so the element might not exist anymore.
    if (element) {
      if (elementType === 'bpmn:SequenceFlow') {
        const previousMarkerEnd = element.style.markerEnd;
        const previousMarkerStart = element.style.markerStart;
        cleanup.push(() => {
          element.style.markerEnd = previousMarkerEnd;
          if (previousMarkerStart) {
            element.style.markerStart = previousMarkerStart;
          }
        });

        element.style.markerEnd = 'url("#green-sequenceflow-arrow")';
        if (previousMarkerStart) {
          element.style.markerStart = 'url("#green-conditional-default-flow-end")';
        }
      }

      if (elementType === 'bpmn:MessageFlow') {
        const previousMarkerEnd = element.style.markerEnd;
        const previousMarkerStart = element.style.markerStart;

        cleanup.push(() => {
          element.style.markerEnd = previousMarkerEnd;
          element.style.markerStart = previousMarkerStart;
        });

        element.style.markerEnd = 'url("#green-messageflow-arrow")';
        element.style.markerStart = 'url("#green-messageflow-end")';
      }
    }

    try {
      canvas.addMarker(elementId, 'diff-added');
      canvas.addMarker(elementId, elementType.replace('bpmn:', ''));
    } catch (ex) {
      console.log('could not add diff-added for', elementId);
    }

    if (elementType && ['bpmn:Gateway', 'bpmn:SequenceFlow', 'bpmn:MessageFlow', 'bpmn:Event'].includes(elementType)) {
      try {
        canvas.addMarker(`${elementId}_label`, 'diff-added-label');
      } catch (ex) {
        console.log('could not add diff-added-label for', elementId, '_label');
      }
    }
  });

  changes.modified.forEach((modifiedElement) => {
    const elementType = modifiedElement.type;
    const elementId = modifiedElement.id;

    let hasRemovedTargetOrSource = false;

    if (['bpmn:SequenceFlow', 'bpmn:MessageFlow'].includes(elementType)) {
      const element = document.querySelector(`[data-element-id="${elementId}"] > .djs-visual > path`);

      hasRemovedTargetOrSource = modifiedElement.changes.some(checkForRemovedTargetOrSource);
      const arrowColor = hasRemovedTargetOrSource ? 'red' : 'orange';

      // maybe the user navigated away from the dom, so the element might not exist anymore.
      if (element) {
        if (elementType === 'bpmn:SequenceFlow') {
          const previousMarkerEnd = element.style.markerEnd;
          const previousMarkerStart = element.style.markerStart;
          cleanup.push(() => {
            element.style.markerEnd = previousMarkerEnd;
            element.style.markerStart = previousMarkerStart;
          });

          element.style.markerEnd = `url("#${arrowColor}-sequenceflow-arrow")`;
          element.style.markerStart = `url("#${arrowColor}-conditionalflow-end")`;
        }

        if (elementType === 'bpmn:MessageFlow') {
          const previousMarkerEnd = element.style.markerEnd;
          cleanup.push(() => {
            element.style.markerEnd = previousMarkerEnd;
          });

          element.style.markerEnd = `url("#${arrowColor}-messageflow-arrow")`;
        }
      }
    }

    const changedOrRemovedClassName = hasRemovedTargetOrSource ? 'diff-removed' : 'diff-changed';

    try {
      /**
       * if the element has a removed
       * target or source, we paint it in red (diff-removed class) otherwise in orange (diff-changed lcass)
       */

      canvas.addMarker(elementId, changedOrRemovedClassName);
      canvas.addMarker(elementId, elementType.replace('bpmn:', ''));
    } catch (ex) {
      console.log('could not add diff-changed for', elementId);
    }
    if (elementType && ['bpmn:Gateway', 'bpmn:SequenceFlow', 'bpmn:MessageFlow', 'bpmn:Event'].includes(elementType)) {
      try {
        canvas.addMarker(`${elementId}_label`, `${changedOrRemovedClassName}-label`);
      } catch (ex) {
        console.log('could not add diff-changed-label for', elementId, '_label');
      }
    }
  });

  cleanup.push(() => {
    const markers = Array.from(
      document.querySelectorAll(
        '.diff-added, .diff-changed, .diff-removed, .diff-added-label, .diff-changed-label, .diff-removed-label'
      )
    );

    markers.forEach((marker) => {
      marker.classList.remove('diff-added');
      marker.classList.remove('diff-added-label');
      marker.classList.remove('diff-changed');
      marker.classList.remove('diff-removed');
      marker.classList.remove('diff-changed-label');
      marker.classList.remove('diff-removed-label');
    });
  });

  return cleanup;
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Button, Typography, Dialog as PrimitiveDialog } from 'primitives';

export const dialogWidth = 860;
export const contentHeight = 420;

export const Dialog = styled(PrimitiveDialog)`
  overflow-x: hidden;
`;

export const DialogContainer = styled.div`
  max-width: ${dialogWidth}px;
  height: 50vh;
  max-height: ${contentHeight}px;
  background: var(--silver-base-97);
`;

export const TemplateDetails = styled.div`
  display: inline-block;
  width: 40%;
  height: 100%;
  overflow-y: auto;
  vertical-align: top;
  padding: 18px 14px 10px 24px;
`;

export const TemplatePreview = styled.div`
  position: relative;
  display: inline-block;
  width: calc(60% - 20px);
  height: calc(100% - 20px);

  margin: 10px;
  background-color: white;
  border: 1px solid var(--silver-darken-87);
`;

export const BackButton = styled.div`
  cursor: pointer;
  position: absolute;
  top: 4px;
  left: 10px;
  line-height: 50px;
  font-weight: 600;
`;

export const BackButtonIcon = styled.div`
  display: inline-block;
  vertical-align: middle;
  line-height: 13px;
`;

export const CreateButton = styled(Button)`
  span {
    display: inline-block;
    margin-right: 5px;
  }
`;

export const Name = styled(Typography)`
  font-size: 24px;
  cursor: default;
`;
export const CreatedBy = styled(Typography)`
  cursor: default;
  font-weight: 400;

  a,
  a:visited {
    color: var(--cm-color-button-blue5);
  }
`;
export const TechnicalLevel = styled.div`
  cursor: default;
  margin-top: 10px;

  width: 100%;

  span {
    display: inline-block;
    width: 50%;
  }

  span:first-child {
    font-weight: 600;
  }

  span:last-child {
    font-weight: 400;
  }
`;
export const Description = styled.div`
  cursor: default;
  margin-top: 10px;

  a,
  a:visited {
    color: var(--cm-color-button-blue5);
  }
`;
export const CategoriesContainer = styled.div`
  cursor: default;
  width: 100%;
  margin-top: 10px;
`;
export const TagsContainer = styled.div`
  display: inline-block;
  vertical-align: top;
  width: 50%;
`;
export const CategoryName = styled.div`
  font-weight: 800;
`;
export const Tag = styled.div`
  cursor: default;
`;

export const DiagramContainer = styled.div`
  pointer-events: none;

  width: 100%;
  height: 100%;

  padding: 10px;

  .bjs-container {
    border: none;
  }

  .bjs-powered-by {
    display: none;
  }
`;

export const DiagramControlsContainer = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 1;
`;

export const Footer = styled(PrimitiveDialog.Footer)`
  background-color: white;
`;

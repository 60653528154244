/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

const tileHeight = 186;

export const TemplateTile = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: calc(33% - 13px);
  height: ${tileHeight}px;
  background: var(--silver-lighten-99);
  overflow: hidden;

  transition: box-shadow 0.18s ease-in-out;
  box-shadow: 0 2px 5px 0px var(--silver-darken-87);
  border: 1px solid var(--silver-darken-87);
  border-radius: 3px;

  margin-right: 15px;
  margin-bottom: 15px;

  cursor: pointer;

  &:hover {
    box-shadow: 0 2px 5px 0px var(--silver-darken-80);
  }
`;

export const TemplateTileBottom = styled.div`
  position: absolute;
  width: 100%;
  height: 58px;
  line-height: 58px;
  border-top: 1px solid var(--silver-darken-87);
  background: white;
  bottom: 0px;

  padding-left: 15px;
  padding-right: 15px;
`;

export const TemplateTileName = styled.div`
  color: black;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  cursor: default;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: absolute;
  bottom: 10px;
  width: calc(100% - 30px);

  .blank & {
    cursor: pointer;
    bottom: 18px;
  }
`;

export const BlankTemplateIcon = styled.div`
  position: relative;
  top: 25px;
  font-weight: 600;
  font-size: 102px;
  line-height: 60px;
  text-align: center;

  transition: color 0.09s ease-in-out;
  color: var(--grey-lighten-60);

  ${TemplateTile}:hover & {
    color: var(--cds-blue-60);
  }
`;

export const TemplateThumbnail = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 126px;
  padding: 5px;
  pointer-events: none;

  .bjs-container * {
    pointer-events: none;
  }
`;

export const DiagramContainer = styled.div`
  height: calc(100% - 10px);

  .bjs-powered-by {
    display: none;
  }
`;

export const TemplateMetadata = styled.div`
  padding-top: 10px;
  font-weight: 400;
  font-size: 10px;
  line-height: 13px;

  color: var(--cm-color-button-light3);

  .company {
    font-weight: 600;
  }

  .date {
    color: var(--cm-color-button-light2);
  }
`;

export const TemplateActions = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-around;
  align-items: center;

  background-color: rgba(255, 255, 255, 0.95);
  opacity: 0;

  transition:
    bottom 0.15s ease-in-out,
    opacity 0.1s ease-in-out;
  position: absolute;
  z-index: 1;
  bottom: -50px;
  left: 0;
  width: 100%;
  height: 50px;

  ${TemplateTile}:hover & {
    bottom: 0;
    opacity: 1;
  }
`;

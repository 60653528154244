/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect } from 'react';

export default function useMakePropertiesPanelInputsReadOnly(isReadOnly, isFormPlaygroundReady) {
  useEffect(() => {
    const targetNode = document.querySelector('.fjs-properties-container');
    if (isReadOnly && targetNode && isFormPlaygroundReady) {
      makePropertiesPanelInputsReadOnly(targetNode);
    }

    return () => {
      if (targetNode?._observer) {
        targetNode._observer.disconnect();
      }
    };
  }, [isFormPlaygroundReady]);
}

const makePropertiesPanelInputsReadOnly = (targetNode) => {
  const isAlreadyObserving = targetNode._observer;
  if (!isAlreadyObserving) {
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          makeInputElementsWithinAddedNodesReadOnly(mutation);
        }
      });
    });

    observer.observe(targetNode, { childList: true, subtree: true });

    targetNode._observer = observer;
  }
};

const makeInputElementsWithinAddedNodesReadOnly = (mutation) => {
  mutation.addedNodes.forEach((addedNode) => {
    try {
      const matchingInputElements = addedNode.querySelectorAll('.bio-properties-panel-input');
      matchingInputElements.forEach(addReadonlyAttribute);
      silentlyFailKeyPressForDivElements(addedNode);
    } catch (e) {
      // fail silently
    }
  });
};

const addReadonlyAttribute = (element) => {
  element.setAttribute('readonly', 'true');
};

const silentlyFailKeyPressForDivElements = (addedNode) => {
  if (addedNode.nodeType === 1 && addedNode.classList.contains('cm-editor')) {
    addedNode.addEventListener('keypress', function (event) {
      event.preventDefault();
    });
  }
};

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeObservable, observable, action, computed, runInAction } from 'mobx';

import { currentDiagramStore, realtimeCollaborationStore } from 'stores';
import { trackingService } from 'services';
import localStorage from 'utils/localstorage';
import createPermission from 'utils/createPermission';
import config from 'utils/config';

const MODES_INDEXES = {
  DESIGN: 0,
  IMPLEMENT: 1,
  PLAY: 2
};

class BaseDedicatedModesStore {
  availableViewModes = [
    {
      index: MODES_INDEXES.DESIGN,
      label: 'Design',
      roles: ['ADMIN', 'WRITE', 'COMMENT']
    },
    {
      index: MODES_INDEXES.IMPLEMENT,
      label: 'Implement',
      roles: ['ADMIN', 'WRITE', 'COMMENT']
    },
    {
      index: MODES_INDEXES.PLAY,
      label: 'Play',
      roles: ['ADMIN', 'WRITE', 'COMMENT']
    }
  ];
  allowedViewModes = [];
  modesAriaControls = [];

  defaultModeIndex = MODES_INDEXES.IMPLEMENT;
  selectedViewModeIndex = null;

  #storageKey = 'view_mode';

  constructor() {
    makeObservable(this, {
      reset: action,
      init: action,
      setViewModeIndex: action,
      resetViewModeIndexForPlay: action,
      availableViewModes: observable,
      allowedViewModes: observable,
      defaultModeIndex: observable,
      selectedViewModeIndex: observable,
      modesAriaControls: observable,
      defaultViewModeIndex: computed,
      hasPlayMode: computed,
      selectedModeIndex: computed,
      selectedModeLabel: computed
    });
  }

  get defaultViewModeIndex() {
    const lastUsedMode = localStorage.getItem(this.#storageKey);

    const shouldUsePlayMode =
      lastUsedMode !== null &&
      +lastUsedMode === MODES_INDEXES.PLAY &&
      !localStorage.getItem('play-directAccess') &&
      this.#isUserAllowedToSeeMode(+lastUsedMode);

    const shouldUseImplementMode =
      currentDiagramStore?.isProcessTemplate && this.#isUserAllowedToSeeMode(MODES_INDEXES.IMPLEMENT);

    if (shouldUsePlayMode) {
      return MODES_INDEXES.PLAY;
    }

    if (shouldUseImplementMode) {
      return MODES_INDEXES.IMPLEMENT;
    }

    // Use previous selection (if user is allowed to access the mode)
    if (lastUsedMode && this.#isUserAllowedToSeeMode(+lastUsedMode)) {
      return +lastUsedMode;
    }

    return this.#isUserAllowedToSeeMode(this.defaultModeIndex) ? this.defaultModeIndex : 0;
  }

  reset() {
    this.selectedViewModeIndex = null;
    this.allowedViewModes = [];
  }

  init() {
    const permission = createPermission(currentDiagramStore.state.project?.permissionAccess);
    this.allowedViewModes = this.availableViewModes.filter((viewMode) =>
      this.#userCanAccessViewMode(permission, viewMode)
    );

    if (!config.play.enabled) {
      this.disallowPlayMode();
    }

    this.selectedViewModeIndex = this.defaultViewModeIndex;
  }

  setViewModeIndex = (val) => {
    if (val === MODES_INDEXES.PLAY) {
      realtimeCollaborationStore.reset();
    } else if (this.selectedViewModeIndex === MODES_INDEXES.PLAY && val !== MODES_INDEXES.PLAY) {
      currentDiagramStore.fetchDiagramById(currentDiagramStore.state.diagram.id);
    }

    this.selectedViewModeIndex = val;

    localStorage.setItem(this.#storageKey, val);

    if (currentDiagramStore?.state) {
      trackingService.trackDiagramModeChange(
        currentDiagramStore.state.diagram.id,
        currentDiagramStore.state.diagram.type,
        this.selectedModeLabel,
        currentDiagramStore.state.project?.permissionAccess?.toLowerCase()
      );
    }
  };

  resetViewModeIndexForPlay = () => {
    const lastUsedMode = localStorage.getItem(this.#storageKey);

    if (lastUsedMode && +lastUsedMode === MODES_INDEXES.PLAY) {
      localStorage.setItem(this.#storageKey, null);
    }
  };

  get selectedModeIndex() {
    return this.selectedViewModeIndex ?? this.defaultViewModeIndex;
  }

  get selectedModeLabel() {
    return this.availableViewModes.filter((mode) => mode.index === this.selectedModeIndex)[0]?.label?.toLowerCase();
  }

  get hasPlayMode() {
    return this.#isUserAllowedToSeeMode(MODES_INDEXES.PLAY);
  }

  get isDesignMode() {
    return this.selectedModeIndex === MODES_INDEXES.DESIGN;
  }

  get designModeAriaControl() {
    return this.modesAriaControls?.[MODES_INDEXES.DESIGN] ?? '';
  }

  get isImplementMode() {
    return this.selectedModeIndex === MODES_INDEXES.IMPLEMENT;
  }

  get implementModeAriaControl() {
    return this.modesAriaControls?.[MODES_INDEXES.IMPLEMENT] ?? '';
  }

  get isPlayMode() {
    return this.selectedModeIndex === MODES_INDEXES.PLAY;
  }
  get playModeAriaControl() {
    return this.modesAriaControls?.[MODES_INDEXES.PLAY] ?? '';
  }

  get playModeIndex() {
    return MODES_INDEXES.PLAY;
  }

  #userCanAccessViewMode(permission, viewMode) {
    return permission.is(viewMode.roles);
  }

  #isUserAllowedToSeeMode(index) {
    return this.allowedViewModes.some((viewMode) => viewMode.index === index);
  }

  disallowPlayMode() {
    runInAction(() => {
      this.allowedViewModes = this.allowedViewModes.filter(({ index }) => index !== MODES_INDEXES.PLAY);
    });
  }

  allowPlayMode() {
    runInAction(() => {
      const playModeView = this.availableViewModes.filter(({ index }) => index === MODES_INDEXES.PLAY)[0];

      if (!playModeView) {
        return;
      }

      const permission = createPermission(currentDiagramStore.state.project?.permissionAccess);

      if (
        !this.allowedViewModes.some(({ index }) => index === MODES_INDEXES.PLAY) &&
        this.#userCanAccessViewMode(permission, playModeView)
      ) {
        this.allowedViewModes.push(this.availableViewModes[MODES_INDEXES.PLAY]);
      }
    });
  }

  updateModesAriaControls = (ariaControls = []) => {
    runInAction(() => {
      this.modesAriaControls = ariaControls;
    });
  };
}

export default BaseDedicatedModesStore;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Row } from '@carbon/react';
import { observer } from 'mobx-react';
import debounce from 'lodash/debounce';

import * as Styled from './BrowseBlueprintsModal.styled';

export const BrowseBlueprintsModalTopFilters = ({ filters, setFilters, availableFilters, setSearchValue }) => {
  const SEARCH_VALUE_CHANGE_DEBOUNCE = 500;

  const handleMultiselectFilterCheckBoxOnclick = (filterGroup, filterValueArray) => {
    // always override
    filters[filterGroup] = filterValueArray;
    setFilters(JSON.parse(JSON.stringify(filters)));
  };

  /**
   * Search for a blueprint.
   */
  const searchBlueprint = debounce(
    (event) => {
      setSearchValue(event.target.value);
    },
    SEARCH_VALUE_CHANGE_DEBOUNCE,
    {
      trailing: true
    }
  );

  return (
    <Styled.NoGutterFlexGrid condensed>
      <Row condensed>
        <Styled.CondensedColumn sm={4} md={4} lg={4}>
          <Styled.BlueprintsSearch
            size="lg"
            placeholder="Search blueprints"
            labelText="Search"
            closeButtonLabelText="Clear search input"
            id="search-for-a-connector"
            onChange={searchBlueprint}
          />
        </Styled.CondensedColumn>
        {availableFilters
          ?.filter((availableFilter) => 'top' === availableFilter.groupName)
          .map((topFilters) => {
            return topFilters?.filters?.map((filter) => {
              return (
                <Styled.CondensedColumn sm={4} md={4} lg={4} key={filter.value}>
                  <Styled.BrowseBlueprintsMultiSelect
                    label={filter.label}
                    id={filter.value}
                    items={filter.options}
                    itemToString={(item) => (item ? item.label : '')}
                    onChange={(obj) => {
                      handleMultiselectFilterCheckBoxOnclick(
                        filter.value,
                        obj.selectedItems.map((selectedItem) => selectedItem.value)
                      );
                    }}
                  />
                </Styled.CondensedColumn>
              );
            });
          })}
      </Row>
    </Styled.NoGutterFlexGrid>
  );
};

export default observer(BrowseBlueprintsModalTopFilters);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useContext } from 'react';

import { Tooltip } from 'primitives';
import buildSlug from 'utils/buildSlug';

import * as Styled from './Entity.styled';
import EntityContext from './EntityContext';

const ColumnContent = ({ column, isPrimary }) => {
  const content = column.content || column;
  const afterContent = column.afterContent;
  const tooltip = column.tooltip || content;

  return (
    <>
      {column.pretitle && <Styled.EntityType>{column.pretitle}</Styled.EntityType>}
      <Tooltip title={tooltip} justify="left" showOnlyOnOverflow={!column.tooltip}>
        {isPrimary ? (
          <Styled.EntityName>{content}</Styled.EntityName>
        ) : (
          <div className="overflow-ellipsis">{content}</div>
        )}
      </Tooltip>
      {afterContent}
    </>
  );
};

const WithLink = ({ entity, children }) => {
  if (entity.link) {
    return <Styled.EntityLink to={entity.link}>{children}</Styled.EntityLink>;
  }

  return children;
};

export default function Entity({ entity, isEntitySelected, onSelect }) {
  const context = useContext(EntityContext);

  return (
    <Styled.Entity
      $isSelected={isEntitySelected(entity)}
      data-test={`entity-${entity.columns[0].content || entity.columns[0]}`}
      $hasHover={Boolean(buildSlug(entity.id, entity.name)) || Boolean(entity.onClick)}
      $isLightTheme={context.isLightTheme}
      onClick={() => entity.onClick?.()}
    >
      {(context.MultiSelection || entity.icon) && (
        <Styled.LeftAction $columnWidth={context.MultiSelection ? 80 : 54}>
          {context.MultiSelection && (
            <Styled.Checkbox
              onChange={() => onSelect(entity)}
              $isVisible={context.selected.length > 0}
              checked={isEntitySelected(entity)}
              data-test="entity-checkbox"
            />
          )}

          {entity?.icon}
        </Styled.LeftAction>
      )}

      <WithLink entity={entity}>
        {context.columns.map((column, index) => (
          <Styled.Column
            $isPrimary={index == 0}
            key={entity.id + column.name}
            $columnWidth={column.width}
            $disableResponsiveness={context.disableResponsiveness}
          >
            <ColumnContent column={entity.columns[index]} isPrimary={index == 0} />
          </Styled.Column>
        ))}
      </WithLink>

      {entity.action && <Styled.RightAction>{context.selected.length == 0 && entity.action}</Styled.RightAction>}
    </Styled.Entity>
  );
}

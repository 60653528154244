/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import FileMapper from 'utils/file-mapper';
import { BPMN } from 'utils/constants';

import Service from './Service';

class BaseProcessApplicationService extends Service {
  constructor() {
    super();
  }

  /**
   * Creates a new process application in the given project, or in the given folder.
   *
   * @param {String | undefined} projectId the project id
   * @param {String | undefined} parentId the folder id
   * @param {String} processApplicationName
   * @param {String | undefined} defaultDevClusterId the cluster that can be set as default in the context of this process application
   * @return {Promise}
   */
  createProcessApplication({ projectId, parentId, processApplicationName, defaultDevClusterId }) {
    const payload = {
      name: processApplicationName,
      defaultDevClusterId,
      parentId,
      projectId,
      mainProcessContent: new FileMapper(BPMN).generate({
        name: processApplicationName
      }).content
    };
    return this.post(`/internal-api/process-applications`, payload);
  }

  /**
   * Updates the definition of the stages for the given process application
   *
   * @param {String} processApplicationId
   * @param {Object} payload
   * @return {Promise}
   */
  updateStages(processApplicationId, payload) {
    return this.patch(`/internal-api/process-applications/${processApplicationId}/stages`, payload);
  }

  /**
   * Returns the details related to the given process application
   *
   * @param {String} processApplicationId
   * @return {Promise}
   */
  fetchProcessApplication(processApplicationId) {
    return this.get(`/internal-api/process-applications/${processApplicationId}`);
  }

  /**
   * Retrieves the external resources for the given process
   * @param {String} processApplicationId
   * @return {Promise}
   */
  fetchExternalResources(processApplicationId) {
    return this.get(`/internal-api/process-applications/${processApplicationId}/external-resources`);
  }

  /**
   * Sets the given file as main process for the process application
   * @param {String} processApplicationId
   * @param {String} fileId
   * @return {Promise}
   */
  reassignMainProcess(processApplicationId, fileId) {
    return this.patch(`/internal-api/process-applications/${processApplicationId}/main-process`, { fileId });
  }

  /**
   * Creates a version for the given process application
   *
   * @param {String} processApplicationId
   * @param {String} name the version tag
   * @return {Promise}
   */
  createVersion(processApplicationId, name) {
    return this.post(`/internal-api/process-applications/${processApplicationId}/versions`, { name });
  }
}

export default BaseProcessApplicationService;

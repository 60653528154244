/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default class BufferedAuthorizer {
  #requests = {};
  #requestTimeout = undefined;
  #options = {};
  #authOptions = {};

  constructor(options) {
    this.#options = options;
    this.#authOptions = options.authOptions || {};

    this.#setRequestTimeout();
  }

  /**
   * Add auth request to queue and execute after delay
   */
  add(channel, callback) {
    this.#requests[channel] = callback;

    if (!this.#requestTimeout) {
      this.#setRequestTimeout();
    }
  }

  /**
   * Set new delay and authenticate all queued requests after timeout
   */
  #setRequestTimeout() {
    clearTimeout(this.#requestTimeout);

    this.#requestTimeout = setTimeout(() => {
      if (Object.keys(this.#requests).length) {
        this.#executeRequests();
        this.#setRequestTimeout();
      } else {
        this.#requestTimeout = null;
      }
    }, this.#options.authDelay || 0);
  }

  /**
   * Execute all queued auth requests
   */
  #executeRequests() {
    const queuedRequests = { ...this.#requests };
    this.#requests = {};

    this.#xhrRequest({
      requests: queuedRequests,
      socketId: this.#options.socketId,
      authOptions: this.#authOptions,
      authEndpoint: this.#options.authEndpoint,
      callback: (error, response) => {
        if (error) {
          this.#objectApply(queuedRequests, (callback) => {
            callback(true, response);
          });
        } else {
          this.#objectApply(queuedRequests, (callback, channel) => {
            if (response[channel]) {
              if (!response[channel].status || response[channel].status === 200) {
                callback(null, response[channel].data);
              } else {
                // authentication failed
                callback(true, response[channel].status);
              }
            } else {
              callback(true, 404);
            }
          });
        }
      }
    });
  }

  #objectApply(object, func) {
    for (const key in object) {
      if (Object.prototype.hasOwnProperty.call(object, key)) {
        func(object[key], key, object);
      }
    }
  }

  /**
   * Execute XHR request to auth endpoint
   */
  async #xhrRequest({ requests, socketId, authOptions, authEndpoint, callback }) {
    if (!this.#options.pusher) {
      throw new Error('Missing Pusher instance');
    }

    authOptions = typeof authOptions === 'function' ? await authOptions() : authOptions;

    const xhr = this.#options.pusher.Runtime.createXHR();
    xhr.open('POST', authEndpoint, true);
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');

    for (const headerName in authOptions.headers) {
      xhr.setRequestHeader(headerName, authOptions.headers[headerName]);
    }

    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          let data;
          let parsed = false;

          try {
            data = JSON.parse(xhr.responseText);
            parsed = true;
          } catch (e) {
            callback(
              new Error(
                `JSON returned from webapp was invalid, yet status code was 200. Data was: ${xhr.responseText}`
              ),
              { auth: '' }
            );
          }

          if (parsed) {
            callback(null, data);
          }
        } else {
          callback(new Error(`Unable to retrieve auth string from auth endpoint - received status: ${xhr.status}`), {
            auth: ''
          });
        }
      }
    };

    xhr.send(this.#composeQuery(requests, socketId, authOptions));
  }

  /**
   * Compose POST query to auth endpoint
   */
  #composeQuery(requests, socketId, authOptions) {
    let i = 0;
    let query = `&socket_id=${encodeURIComponent(socketId)}`;

    for (const channel in requests) {
      query += `&channel_name[${i}]=${encodeURIComponent(channel)}`;
      i++;
    }

    for (const param in authOptions.params) {
      query += `&${encodeURIComponent(param)}=${encodeURIComponent(authOptions.params[param])}`;
    }

    return query;
  }
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useContext } from 'react';

import EntityContext from 'components/EntityList/EntityContext';

import * as Styled from './ListHead.styled';

export default function ListHead({ onSort, onSelect, isCheckboxChecked, sortOrder }) {
  const entityContext = useContext(EntityContext);
  const hasAction = entityContext.entities.some((entity) => Boolean(entity.action));

  return (
    <Styled.Row $isOpaque={entityContext.entities.length < 1}>
      <Styled.Column $columnWidth={entityContext.MultiSelection ? 80 : 54}>
        {entityContext.MultiSelection && (
          <Styled.Checkbox
            $isVisible={entityContext.selected.length > 0}
            onChange={onSelect}
            checked={isCheckboxChecked}
            data-test="entity-list-head-checkbox"
          />
        )}
      </Styled.Column>

      {entityContext.columns.map((column, index) => (
        <Styled.Column
          key={column.name}
          $isPrimary={index == 0}
          $columnWidth={column.width}
          $disableResponsiveness={entityContext.disableResponsiveness}
        >
          {column.sortable ? (
            <Styled.HeadButton data-test={`entity-head-${column.name}`} onClick={() => onSort(index)}>
              {column.name}

              {column.name && (
                <Styled.Arrow
                  $isCurrentSorted={entityContext.isSortedBy(index)}
                  $isASC={sortOrder == 'asc'}
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  data-test={`sort-icon-${column.name}`}
                >
                  <polygon
                    fill="currentColor"
                    fillRule="evenodd"
                    points="12 19 6 13.135 7.167 12 11 15.751 11 6 13 6 13 15.752 16.833 12 18 13.135"
                  />
                </Styled.Arrow>
              )}
            </Styled.HeadButton>
          ) : (
            column.name
          )}
        </Styled.Column>
      ))}

      {hasAction && <Styled.Column $columnWidth={50} />}
    </Styled.Row>
  );
}

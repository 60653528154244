/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

export default {
  setItem(key, value) {
    try {
      window.localStorage.setItem(key, value);
    } catch (ex) {
      // we don't notify users when this fails.
    }
  },
  getItem(key) {
    try {
      return window.localStorage.getItem(key);
    } catch (ex) {
      // we don't notify users when this fails.
      return null;
    }
  }
};

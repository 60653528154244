/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';
import { TableCell as _TableCell, TableContainer as _TableContainer, TableHeader as _TableHeader } from '@carbon/react';

export const TableContainer = styled(_TableContainer)`
  flex: 1;
  padding: ${({ $noTablePadding }) => ($noTablePadding ? '0' : '40px')};
  min-width: 670px;

  table,
  td,
  .cds--table-toolbar,
  .cds--data-table-header {
    background-color: var(--cm-color-white-base);
  }

  tbody td {
    max-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .cds--table-toolbar button {
    border-radius: initial;
    font-weight: 400;
    height: 100%;
  }

  .cds--data-table td.no-padding {
    padding-left: 0;
    padding-right: 0;
  }

  .cds--data-table td.overflow-visible {
    overflow: visible;
  }
`;

export const TableCell = styled(_TableCell)`
  padding: 8px 16px 8px 0;
`;

export const TableHeader = styled(_TableHeader)`
  ${({ $customWidth, $customMinWidth }) => {
    if ($customWidth) {
      return `
        width: ${$customWidth} !important; /* need to override a carbon rule */
      `;
    }

    if ($customMinWidth) {
      return `
        min-width: ${$customMinWidth} !important; /* need to override a carbon rule */
      `;
    }
  }}
`;

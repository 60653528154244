/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { DEFAULT_ZEEBE_VERSION, EXPORTER } from 'utils/constants';
import { versionShort } from 'utils/version';

export default ({ processId, relationId, processName }) => {
  const nameAttribute = processName ? ` name="${processName}"` : '';
  const processNode = `<bpmn:process id="${processId}"${nameAttribute} isExecutable="true">
    <bpmn:startEvent id="StartEvent_1" />
  </bpmn:process>`;

  return `<?xml version="1.0" encoding="UTF-8"?>
<bpmn:definitions xmlns:bpmn="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:dc="http://www.omg.org/spec/DD/20100524/DC" id="Definitions_1" targetNamespace="http://bpmn.io/schema/bpmn" xmlns:zeebe="http://camunda.org/schema/zeebe/1.0" xmlns:modeler="http://camunda.org/schema/modeler/1.0" exporter="${EXPORTER}" exporterVersion="${versionShort}" modeler:executionPlatform="Camunda Cloud" modeler:executionPlatformVersion="${DEFAULT_ZEEBE_VERSION}" xmlns:camunda="http://camunda.org/schema/1.0/bpmn" camunda:diagramRelationId="${relationId}">
  ${processNode}
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
    <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="${processId}">
      <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
        <dc:Bounds x="150" y="100" width="36" height="36" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</bpmn:definitions>
`;
};

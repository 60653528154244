/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';
import { ProgressBar } from '@carbon/react';

import * as Styled from './ProjectsDropdown.styled';

const ProjectsDropdown = ({
  selectedProject,
  isCreatingProject,
  isPublishing,
  hasProjects,
  filteredProjects,
  onCreateNewProject,
  onProjectSelected
}) => {
  if (isCreatingProject || isPublishing) {
    const label = isCreatingProject ? 'Creating project' : 'Adding resources to project';
    return <ProgressBar label={label} />;
  }

  return (
    <Styled.ProjectsDropdown
      id="select-project"
      data-test="select-project"
      label="Select a project"
      selectedItem={selectedProject?.name || ''}
      titleText=""
      items={[
        { id: 'create-project', name: 'Create new project' },
        ...(hasProjects
          ? filteredProjects
          : [
              {
                id: 'no-projects',
                name: 'No projects have been created yet',
                disabled: true
              }
            ])
      ]}
      itemToElement={(item) => {
        return item.id === 'create-project' ? (
          <Styled.CreateNewProjectItem data-test={item.id}>{item.name}</Styled.CreateNewProjectItem>
        ) : (
          item.name
        );
      }}
      onChange={({ selectedItem }) => {
        if (selectedItem.id === 'create-project') {
          onCreateNewProject();
        } else {
          onProjectSelected(selectedItem);
        }
      }}
    />
  );
};

ProjectsDropdown.propTypes = {
  selectedProject: PropTypes.object,
  isCreatingProject: PropTypes.bool,
  isPublishing: PropTypes.bool,
  hasProjects: PropTypes.bool,
  filteredProjects: PropTypes.array,
  onCreateNewProject: PropTypes.func,
  onProjectSelected: PropTypes.func
};

export default ProjectsDropdown;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { action, makeObservable, observable } from 'mobx';

import { performLinting } from 'App/Pages/Diagram/lint-util';

export class LintingStore {
  lintErrors = [];
  runtimeErrors = [];

  constructor() {
    makeObservable(this, {
      runtimeErrors: observable,
      lintErrors: observable,
      reset: action,
      setLintErrors: action,
      setRuntimeErrors: action
    });
  }

  reset = () => {
    this.runtimeErrors = [];
    this.lintErrors = [];
  };

  setLintErrors = (arr) => {
    this.lintErrors = arr;
  };

  setRuntimeErrors = (arr) => {
    this.runtimeErrors = arr;
  };

  performRuntimeLint = async (modeler, isImplementMode, inboundConnectorHealthData) => {
    try {
      const runtimeErrors = inboundConnectorHealthData
        ?.filter((data) => data?.health?.status === 'DOWN' && data?.health?.error?.message)
        ?.map((data) => {
          return {
            category: 'error',
            documentation: { url: null },
            executionPlatform: 'Camunda Cloud',
            id: data?.elementId,
            message: data?.health?.error?.message,
            name: undefined,
            rule: 'element-templates/validate'
          };
        });
      this.setRuntimeErrors(runtimeErrors);
      this.performLinting(modeler, isImplementMode);
    } catch (err) {
      console.error(err);
    }
  };

  performLinting = async (modeler, isImplementMode) => {
    // NOTE: there might be racing conditions when the linting is performed but the user has already left the page; this is why we catch the error here and we avoid to break the app.
    try {
      const elementRegistry = modeler?.get('elementRegistry');
      const lintErrors = await performLinting(modeler, isImplementMode);

      let allLintErrors = lintErrors;
      if (isImplementMode) {
        const runtimeErrorsForAvailableElements = this.runtimeErrors?.filter((runtimeError) =>
          elementRegistry?.get(runtimeError.id)
        );
        allLintErrors = lintErrors.concat(runtimeErrorsForAvailableElements);
      }

      this.setLintErrors(allLintErrors);

      const linting = modeler.get('linting', false);

      if (linting) {
        const canvasLintErrors = allLintErrors.filter((e) => e.category !== 'info');

        linting.setErrors(canvasLintErrors);
        linting.activate();
      }

      return allLintErrors;
    } catch (err) {
      console.error(err);
    }
  };
}

export default new LintingStore();

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';
import { Fragment } from 'react';

import { notificationStore } from 'components/NotificationSystem';
import { fileService, folderService, processApplicationService, trackingService } from 'services';
import { confirmActionStore, currentDiagramStore, projectStore, userStore } from 'stores';
import buildSlug from 'utils/buildSlug';
import history from 'utils/history';
import { DELETE_DIAGRAM_WARNINGS } from 'stores/project-store/ProjectStore';
import pluralize from 'utils/pluralize';
import localStorage from 'utils/localstorage';
import { DEFAULT, FILE_TYPE_MAPPING, FOLDER, PROCESS_APPLICATION } from 'utils/constants';

class ProcessApplicationStore {
  isCreationModalVisible = false;
  isAdvertisingModalVisible = false;
  hasExternalBusinessRuleTaskLinks = false;
  hasExternalCallActivityLinks = false;
  externalForms = null;
  loading = false;
  loadingPromise = null;
  processApplication = {};

  #advFlagStorageKey = 'process_application_adv_shown_once';

  constructor() {
    makeAutoObservable(this);
  }

  setProcessApplication(processApplication) {
    if (processApplication?.files?.length > 1) {
      this.#sortFilesByMainProcessFirst(processApplication.files, processApplication.mainProcessFileId);
    }
    this.processApplication = processApplication;
  }

  setLoading(loading) {
    this.loading = loading;
    if (loading) {
      this.loadingPromise = new Promise((resolve) => {
        this._resolveLoading = resolve;
      });
    } else if (this._resolveLoading) {
      this._resolveLoading();
      this.loadingPromise = null;
    }
  }

  setExternalResources(externalResources) {
    this.hasExternalBusinessRuleTaskLinks = externalResources?.hasExternalBusinessRuleTaskLinks ?? false;
    this.hasExternalCallActivityLinks = externalResources?.hasExternalCallActivityLinks ?? false;
    this.externalForms = externalResources?.externalForms ?? null;
  }

  reset() {
    this.setLoading(true);
    this.setProcessApplication({});
    this.setExternalResources(null);
    projectStore.reset();
  }

  async init(processApplicationId, skipParentInitialization = false) {
    let processApplicationFile;
    if (userStore.isAuthenticated) {
      this.setLoading(true);
      try {
        processApplicationFile = await processApplicationService.fetchProcessApplication(processApplicationId);
        this.setProcessApplication(processApplicationFile);
        if (!skipParentInitialization) {
          const type = this.processApplication.parentId ? 'folder' : 'project';
          const initId = this.processApplication.parentId || this.processApplication.projectId;
          await projectStore.init(type, initId, this.processApplication.id);
        }
      } catch (err) {
        console.error(err);
        notificationStore.showError("Yikes! Couldn't retrieve your process application. Please try again later.");
      } finally {
        this.setLoading(false);
      }
    }
    return processApplicationFile;
  }

  async getExternalResources() {
    try {
      const externalResources = await processApplicationService.fetchExternalResources(this.processApplication.id);
      this.setExternalResources(externalResources);
    } catch (ex) {
      console.error(ex);
    }
  }

  setIsCreationModalVisible(val) {
    this.isCreationModalVisible = val;
  }

  setIsAdvertisingModalVisible(val) {
    this.isAdvertisingModalVisible = val;
    localStorage.setItem(this.#advFlagStorageKey, val ? 'false' : 'true');
  }

  startCreation() {
    if (this.#shouldShowProcessApplicationAdvertisingModal()) {
      this.setIsAdvertisingModalVisible(true);
      return;
    }

    this.setIsAdvertisingModalVisible(false);
    this.setIsCreationModalVisible(true);
  }

  async finalizeCreation(processApplicationName = '', defaultDevClusterId) {
    try {
      const folderId = projectStore.folder.id;
      const projectId = projectStore.project.id;
      let targetContainer;
      if (folderId) {
        targetContainer = { parentId: folderId };
      } else {
        targetContainer = { projectId };
      }
      this.setProcessApplication(
        await processApplicationService.createProcessApplication({
          ...targetContainer,
          processApplicationName,
          defaultDevClusterId
        })
      );

      trackingService.trackCreateFolder({
        from: 'button',
        parentType: projectStore.isFolder ? FILE_TYPE_MAPPING[FOLDER] : FILE_TYPE_MAPPING[DEFAULT],
        folderType: FILE_TYPE_MAPPING[PROCESS_APPLICATION],
        defaultDevClusterId
      });

      history.push(`/process-applications/${buildSlug(this.processApplication.id, this.processApplication.name)}`);
    } catch (err) {
      console.error(err);
      notificationStore.showError("Yikes! Couldn't create your process application. Please try again later.");
    }
  }

  async updateStages(updatedStages) {
    try {
      await processApplicationService.updateStages(this.processApplication.id, updatedStages);

      this.setProcessApplication({ ...this.processApplication, ...updatedStages });
    } catch (err) {
      notificationStore.showError("Yikes! Couldn't update your process application. Please try again later.");
      throw err;
    }
  }

  async rename(name) {
    if (name.trim().length === 0 || this.processApplication.name === name) {
      return false;
    }

    try {
      await folderService.rename(this.processApplication.id, { name });

      this.setProcessApplication({ ...this.processApplication, name });
      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not rename your process application. Please try again later.');
      return false;
    }
  }

  async delete(processApplicationId) {
    try {
      const { warnings, fileCount } = await fileService.destroyDryRun([], [processApplicationId]);
      const confirmed = await confirmActionStore.confirm({
        title: `Deleting process application`,
        text: (
          <Fragment>
            You're about to delete the process application "{this.processApplication.name}", which involves
            {` ${fileCount} ${pluralize('file', fileCount)}`}. Collaborators won't be able to access this content any
            longer.
            {warnings.length > 0 && (
              <ul>
                {warnings.map((warning) => (
                  <li key={warning}>{DELETE_DIAGRAM_WARNINGS[warning]}</li>
                ))}
              </ul>
            )}
          </Fragment>
        ),
        confirmLabel: 'Delete',
        isDangerous: true,
        isPrimaryActionDanger: true
      });

      if (!confirmed) {
        return false;
      }

      await folderService.destroy(processApplicationId);

      trackingService.trackDeleteEntities(PROCESS_APPLICATION, fileCount);

      notificationStore.showSuccess('Your process application has been deleted.');
      return true;
    } catch (ex) {
      notificationStore.showError('Yikes! Could not delete your process application. Please try again later.');
      return false;
    }
  }

  async reassignMainProcess(entityId) {
    if (!entityId) {
      return;
    }

    try {
      await processApplicationService.reassignMainProcess(this.processApplication.id, entityId);
      this.#reflectNewMainProcessInTheState(entityId);
    } catch (ex) {
      notificationStore.showError(
        'Yikes! Could not reassign the main process for your process application. Please try again later.'
      );
    }
  }

  getMainProcessMilestonesURL() {
    return `/diagrams/${this.processApplication.mainProcessFileId}/milestones`;
  }

  get fromAProcessApplication() {
    return (
      this.processApplication?.files?.length > 0 ||
      currentDiagramStore.state.diagram?.folderType === PROCESS_APPLICATION
    );
  }

  get mainProcessId() {
    const mainProcessFile = this.processApplication?.files?.find(
      (file) => file.id === this.processApplication.mainProcessFileId
    );
    return mainProcessFile?.processId;
  }

  get defaultDevClusterId() {
    return this.processApplication.defaultDevClusterId;
  }

  #shouldShowProcessApplicationAdvertisingModal() {
    return !localStorage.getItem(this.#advFlagStorageKey)
      ? true
      : localStorage.getItem(this.#advFlagStorageKey) === 'false';
  }

  #sortFilesByMainProcessFirst(files, mainProcessFileId) {
    return files.sort((fileA, fileB) => {
      const isFileAMainProcess = fileA.id === mainProcessFileId;
      const isFileBMainProcess = fileB.id === mainProcessFileId;

      if (isFileAMainProcess && !isFileBMainProcess) {
        return -1;
      } else if (!isFileAMainProcess && isFileBMainProcess) {
        return 1;
      }
      return 0;
    });
  }

  #reflectNewMainProcessInTheState(newMainProcessFileId) {
    this.setProcessApplication({
      ...this.processApplication,
      mainProcessFileId: newMainProcessFileId
    });
  }
}

export default new ProcessApplicationStore();

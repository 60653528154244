/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

import { Spinner } from 'primitives';

export const Wrapper = styled.main`
  display: flex;
  flex: 1;
  overflow-x: hidden;

  ${({ $newContextPad, $isViewOnly }) =>
    $newContextPad &&
    !$isViewOnly &&
    `.djs-overlay-business-rule-task-menu,
     .djs-overlay-call-activity-menu,
     .djs-overlay-form-link-menu {
       display: none !important;
     }`}

  ${({ $newContextPad, $isBpmn, $isDmn }) =>
    $newContextPad &&
    ($isBpmn || $isDmn) &&
    `.bio-properties-panel-header {
      background-color: var(--cm-color-white-base) !important;
    }

    .bio-properties-panel-placeholder {
      --placeholder-background-color: var(--cm-color-white-base) !important;
    }`}

  ${({ $isPlayMode }) =>
    $isPlayMode &&
    `flex-direction: column;
     position: relative;
  `}

  ${({ $hasConnectors }) =>
    !$hasConnectors &&
    `[data-action="append-connector"] {
    display: none;
  }`}

  ${({ $showTemplates }) =>
    !$showTemplates &&
    `.template-guide-trigger {
      display: none;
    }`}

  .djs-overlay.foreground {
    z-index: var(--z-tooltip-guide);
  }

  .template-guide-trigger {
    width: 24px;
    height: 24px;
    position: absolute;
    border-radius: 50%;

    box-shadow:
      0 0 0 1px var(--cm-color-orange-base),
      inset 0 0 0 1px var(--cm-color-orange-base),
      0 0 4px 2px var(--cm-color-orange-base),
      inset 0 0 4px 0px var(--cm-color-orange-base);
  }
  .template-guide-trigger::before {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    width: 24px;
    height: 24px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background: var(--cm-color-orange-base);
    opacity: 0.25;
  }
  // ::after is used to maintain a certain threshold around the visible element to avoid triggering the mouse out event too soon while moving the mouse
  .template-guide-trigger::after {
    content: '';
    display: block;
    border-radius: 50%;
    position: absolute;
    width: 70px;
    height: 70px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .template-guide-tooltip {
    display: none;
    position: relative;
    width: 350px;
    padding: 15px 20px 20px 15px;
    background-color: var(--cm-color-ui-dark4);
    border-radius: 3px;

    color: #fff;
    font-size: 13px;
    font-family: var(--cm-font-text);
  }

  .template-guide-tooltip {
    &::before {
      content: '';
      position: absolute;

      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
    }

    &.top-arrow::before {
      top: -8px;
      border-bottom: 8px solid var(--cm-color-ui-dark4);
    }

    &.bottom-arrow::before {
      bottom: -8px;
      border-top: 8px solid var(--cm-color-ui-dark4);
    }

    &.left-arrow::before {
      left: 25px;
    }

    &.right-arrow::before {
      right: 25px;
    }
  }

  .template-guide-tooltip:hover,
  .template-guide-trigger:hover + .template-guide-tooltip {
    display: block;
  }

  .template-guide-tooltip a {
    font-family: var(--cm-font-text);
    font-size: 14px;
    font-weight: 400;

    border-radius: 3px;
    text-decoration: none;
    color: var(--cm-color-link-dark);

    transition: all linear 200ms;
  }
  .template-guide-tooltip a:not(.template-guide-dismiss)::after {
    content: '';
    padding-left: 15px;
    margin-right: 2px;
    background-image: url("data:image/svg+xml,%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' %3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd' %3E%3Cpath d='M6,0 L6,1.4 L1.4,1.4 L1.4,10.6 L10.6,10.6 L10.6,6.485 L12,6.485 L12,12 L0,12 L0,0 L6,0 Z M12,0 L12,5.204 L10.203,3.205 L6.707,6.702 L5.293,5.288 L8.863,1.716 L7.32,0 L12,0 Z' fill='%23dbebff' fill-rule='nonzero' %3E%3C/path%3E%3C/g%3E%3C/svg%3E");
    background-position: right;
    background-repeat: no-repeat;
    transform: translateY(1px);
  }
  .template-guide-tooltip a:hover {
    color: var(--cm-color-link-hover-dark);
  }
  .template-guide-tooltip a:focus {
    outline: none;
  }
  .template-guide-tooltip a:focus-visible {
    box-shadow:
      0px 0px 0px 1px var(--cm-color-focus-inner-neutral),
      0px 0px 0px 4px var(--cm-color-focus-outer-neutral);
  }
  .template-guide-tooltip a:active {
    color: var(--cm-color-link-active-dark);
  }

  .template-guide-tooltip a.template-guide-dismiss {
    cursor: pointer;
    display: block;
    margin-top: 10px;
    color: var(--cm-color-focus-outer-neutral);
    text-align: right;
  }
`;

export const DiagramLoader = styled(Spinner)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
`;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, forwardRef } from 'react';
import PropTypes from 'prop-types';

import { VARCHAR_MAX } from 'utils/constants';

import * as Styled from './Input.styled';

const Input = forwardRef(
  ({ label, error, multiline, grow, addon, type, hasMarginBottom, errorMessage, ...props }, ref) => {
    const randomId =
      label?.toLowerCase() +
      '-' +
      Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(2, 10);

    const calculateHeight = (evt) => {
      const target = evt ? evt.target : ref.current;

      target.style.height = 'initial';

      const computed = window.getComputedStyle(target);
      const height =
        parseInt(computed.getPropertyValue('border-top-width'), 10) +
        target.scrollHeight +
        parseInt(computed.getPropertyValue('border-bottom-width'), 10);

      target.style.height = height + 'px';
    };

    const handleKeyUp = (evt) => {
      if (evt.key == 'Enter') {
        calculateHeight();
      }
    };

    useEffect(() => {
      if (multiline && ref && ref.current) {
        const element = ref.current;

        element.addEventListener('input', calculateHeight);
        element.addEventListener('keyup', handleKeyUp);

        calculateHeight();

        return () => {
          element.removeEventListener('input', calculateHeight);
          element.removeEventListener('keyup', handleKeyUp);
        };
      }
    }, []);

    return (
      <Styled.FormGroup $hasMarginBottom={hasMarginBottom}>
        {label && <Styled.FormLabel htmlFor={randomId}>{label}</Styled.FormLabel>}
        {multiline ? (
          <Styled.FormTextarea {...props} $grow={grow} id={label ? randomId : null} $error={error} ref={ref} />
        ) : (
          <Styled.FormInput
            type={type}
            $addon={Boolean(addon)}
            maxLength={VARCHAR_MAX}
            {...props}
            id={label ? randomId : null}
            $error={error}
            ref={ref}
          />
        )}
        {error && errorMessage && <Styled.FormError data-test="input-error">{errorMessage}</Styled.FormError>}
        {addon && <Styled.FormAddon>{addon}</Styled.FormAddon>}
      </Styled.FormGroup>
    );
  }
);

Input.propTypes = {
  label: PropTypes.string,
  error: PropTypes.bool,
  multiline: PropTypes.bool,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  hasMarginBottom: PropTypes.bool,
  addon: PropTypes.node,
  grow: PropTypes.bool
};

Input.defaultProps = {
  type: 'text',
  error: false,
  multiline: false,
  hasMarginBottom: false,
  grow: false
};

export default Input;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';
import { Link } from '@carbon/react';

import { Arrow } from 'icons';
import { currentDiagramStore } from 'stores';
import { dedicatedModesStore } from 'App/Pages/Diagram/stores';
import { ErrorPanel } from 'components';
import useIsNewContextPadEnabled from 'experiments/new-context-pad/useIsNewContextPadEnabled';

import VersionSelector from './VersionSelector';
import errorPanelStore from './ErrorPanelStore';
import Errors from './Errors';
import VariablesTab from './Variables';
import * as Styled from './DiagramErrorPanel.styled';

const DiagramErrorPanel = ({ lintErrors, deployErrors, isDeploymentAllowed = true }) => {
  const { showProblems, showOutput, showVariables } = errorPanelStore;
  const { isErrorPanelCollapsed } = currentDiagramStore;
  const deploymentErrors = deployErrors?.errors;

  const { isImplementMode } = dedicatedModesStore;

  const diagnosticsCount = useMemo(() => {
    return (lintErrors || []).length;
  }, [lintErrors]);

  useEffect(() => {
    if (!deployErrors) {
      errorPanelStore.reset();
    }
  }, [deployErrors]);

  const handleProblemsTabClick = () => {
    if (isErrorPanelCollapsed || !showProblems) {
      errorPanelStore.switchToProblemsTab();
    } else {
      currentDiagramStore.setIsErrorPanelCollapsed(true);
    }
  };

  const handleOutputTabClick = () => {
    if (isErrorPanelCollapsed || !showOutput) {
      errorPanelStore.switchToOutputTab();
    } else {
      currentDiagramStore.setIsErrorPanelCollapsed(true);
    }
  };

  const handleVariableTabClick = () => {
    if (isErrorPanelCollapsed || !showVariables) {
      errorPanelStore.switchToVariablesTab();
    } else {
      currentDiagramStore.setIsErrorPanelCollapsed(true);
    }
  };

  const newContextPadEnabled = useIsNewContextPadEnabled();

  return (
    <Styled.Container data-test="diagram-error-panel">
      <ErrorPanel.Title
        role="tablist"
        aria-expanded={!isErrorPanelCollapsed}
        onClick={(e) =>
          e.target === e.currentTarget && currentDiagramStore.setIsErrorPanelCollapsed(!isErrorPanelCollapsed)
        }
        data-test="error-panel-title"
        $newContextPad={newContextPadEnabled}
      >
        {currentDiagramStore.isBPMN && (
          <ErrorPanel.Tab
            role="tab"
            aria-selected={showProblems}
            onClick={handleProblemsTabClick}
            $active={showProblems}
            $isErrorPanelCollapsed={isErrorPanelCollapsed}
          >
            <ErrorPanel.StatusText>Problems</ErrorPanel.StatusText>
            <ErrorPanel.Badge>{diagnosticsCount}</ErrorPanel.Badge>
          </ErrorPanel.Tab>
        )}
        {currentDiagramStore.isBPMN && isImplementMode && (
          <ErrorPanel.Tab
            role="tab"
            aria-selected={showVariables}
            onClick={handleVariableTabClick}
            $active={showVariables}
            $isErrorPanelCollapsed={isErrorPanelCollapsed}
          >
            <ErrorPanel.StatusText>Variables</ErrorPanel.StatusText>
          </ErrorPanel.Tab>
        )}
        {deploymentErrors && isImplementMode && (
          <ErrorPanel.Tab
            role="tab"
            aria-selected={showOutput}
            onClick={handleOutputTabClick}
            $active={showOutput}
            $isErrorPanelCollapsed={isErrorPanelCollapsed}
          >
            <ErrorPanel.StatusText>Output</ErrorPanel.StatusText>
          </ErrorPanel.Tab>
        )}
        <ErrorPanel.MenuRight>
          {currentDiagramStore.isBPMN && !currentDiagramStore.state.isLoadingModeler && isImplementMode && (
            <VersionSelector
              message="Your diagram is checked against the selected engine version."
              executionPlatformVersion={currentDiagramStore.executionPlatformVersion}
              onSelectExecutionPlatformVersion={currentDiagramStore.setExecutionPlatformVersion}
              isReadOnly={currentDiagramStore.isCommenter}
            />
          )}

          {currentDiagramStore.isBPMN && !isImplementMode && (
            <Styled.ModelingGuidelinesInfo data-test="validation-note">
              Checked against{' '}
              <Link
                href="https://docs.camunda.io/docs/next/components/modeler/reference/modeling-guidance/"
                target="_blank"
                title="Your diagram is checked against the modeling guidelines. Click to learn more."
                role="tab"
              >
                modeling guidelines
              </Link>
            </Styled.ModelingGuidelinesInfo>
          )}

          <ErrorPanel.Collapse
            $isCollapsed={isErrorPanelCollapsed}
            onClick={() => currentDiagramStore.setIsErrorPanelCollapsed(!isErrorPanelCollapsed)}
            data-test="error-panel-collapse"
          >
            <Arrow width="12" />
          </ErrorPanel.Collapse>
        </ErrorPanel.MenuRight>
      </ErrorPanel.Title>

      {showProblems && (
        <ErrorPanel.Errors tabIndex="0" role="tabpanel">
          <Errors
            errors={lintErrors}
            emptyMessage={
              isImplementMode && isDeploymentAllowed
                ? 'No problems found. You can deploy your diagram now.'
                : 'No problems found.'
            }
          />
        </ErrorPanel.Errors>
      )}
      {showOutput && (
        <ErrorPanel.Errors tabIndex="0" role="tabpanel">
          <ErrorPanel.DeploymentErrors>{deploymentErrors}</ErrorPanel.DeploymentErrors>
        </ErrorPanel.Errors>
      )}
      {showVariables && (
        <ErrorPanel.Errors tabIndex="0" role="tabpanel">
          <VariablesTab />
        </ErrorPanel.Errors>
      )}
    </Styled.Container>
  );
};

export default observer(DiagramErrorPanel);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Dropdown } from '@carbon/react';
import { C3ClusterTag } from '@camunda/camunda-composite-components';

import capitalize from 'utils/capitalize';
import getClusterGeneration from 'utils/cluster/get-cluster-generation';

import * as Styled from './DefineStagesModal.styled';

export default function StageClusterSection({
  clusters,
  selectedCluster,
  onClusterSelect,
  stageName,
  tagName,
  tooltipDefinition
}) {
  return (
    <section data-test={`section-${stageName}`}>
      <Styled.NameWithTag>
        <Styled.DefinitionTooltip align="right" openOnHover definition={tooltipDefinition}>
          {capitalize(stageName)}
        </Styled.DefinitionTooltip>
        <C3ClusterTag stage={tagName} />
      </Styled.NameWithTag>
      {clusters?.length ? (
        <Dropdown
          id={`dropdown-${stageName}-cluster`}
          titleText={false}
          onChange={({ selectedItem }) => onClusterSelect(selectedItem?.uuid)}
          selectedItem={clusters.find((cluster) => selectedCluster === cluster.uuid)}
          label={`Select a ${stageName} cluster`}
          items={clusters}
          itemToString={(cluster) => (cluster ? `${cluster.name} ${getClusterGeneration(cluster)}` : '')}
        />
      ) : (
        <Styled.NoClusterText>No {stageName} cluster available</Styled.NoClusterText>
      )}
    </section>
  );
}

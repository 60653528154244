/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Component } from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import { PropTypes } from 'prop-types';

import { Spinner } from 'primitives';

const DEFAULT_DELAY = 400;

class DelayedSpinner extends Component {
  isVisible = false;

  constructor(props) {
    super(props);

    makeObservable(this, {
      isVisible: observable,
      setIsVisible: action('set visible')
    });
  }

  static propTypes = {
    delay: PropTypes.number
  };

  static defaultProps = {
    delay: DEFAULT_DELAY
  };

  componentDidMount() {
    const delay = this.props.delay;

    this.timeout = setTimeout(() => {
      this.setIsVisible();
    }, delay);
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  setIsVisible = () => {
    this.isVisible = true;
  };

  render() {
    return this.isVisible && <Spinner />;
  }
}

export default observer(DelayedSpinner);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { CodeSnippet } from '@carbon/react';

import { detailsPanelTabsStore, publicationStore } from 'App/Pages/Diagram/stores';
import { clustersStore, notificationStore, currentDiagramStore } from 'stores';
import { trackingService, tracingService } from 'services';

import publicationService from './PublicationService';
import * as Styled from './Publication.styled';

let pollingInterval;

const Publication = () => {
  const { isPublicationTabSelected } = detailsPanelTabsStore;
  const { recentlyDeployed, isStartEventAttachedToForm, isPublicAccessEnabled } = publicationStore;
  const { diagram } = currentDiagramStore.state;

  const isBrowserTabVisible = useBrowserTabVisibilityListener();
  const { activePublicLinks, initialLoadingInProgress } = usePollPublicLinksStatus({
    isBrowserTabVisible,
    isPublicationTabSelected,
    fileId: diagram.id,
    interval: 3000
  });

  const loadingMessage = 'Updating public process status';

  return (
    <Styled.Publication>
      <Styled.PublicationTitle>Public Links</Styled.PublicationTitle>

      {!isPublicAccessEnabled && (
        <Styled.InformativeText kind="info" lowContrast hideCloseButton>
          Use the publication group in the properties panel to enable public access to your process.
        </Styled.InformativeText>
      )}

      {initialLoadingInProgress ? (
        <Styled.Loading description={loadingMessage} />
      ) : (
        <>
          {recentlyDeployed && <Styled.Loading description={loadingMessage} />}
          {isPublicAccessEnabled && activePublicLinks?.length > 0 ? (
            <>
              {activePublicLinks?.map((link) => (
                <Styled.ActivePublicLink key={link.endpoint}>
                  {link.clusterName}
                  <CodeSnippet
                    wrapText
                    type="multi"
                    onClick={() => {
                      notificationStore.showSuccess('The link has been copied to the clipboard');
                      trackingService.trackPublicLinkCopy(currentDiagramStore.state.diagram?.processId);
                    }}
                  >
                    {link.endpoint}
                  </CodeSnippet>
                </Styled.ActivePublicLink>
              ))}
            </>
          ) : (
            isPublicAccessEnabled &&
            isStartEventAttachedToForm && (
              <Styled.InformativeText kind="info" lowContrast hideCloseButton>
                <strong>There is no public link active in any cluster</strong>
                Deploy the diagram to publish it.
              </Styled.InformativeText>
            )
          )}
        </>
      )}
    </Styled.Publication>
  );
};

const useBrowserTabVisibilityListener = () => {
  const [isBrowserTabVisible, setIsBrowserTabVisible] = useState(true);
  useEffect(() => {
    const visibilityChangeHandler = () => {
      if (document.hidden) {
        setIsBrowserTabVisible(false);
      } else {
        setIsBrowserTabVisible(true);
      }
    };
    document.addEventListener('visibilitychange', visibilityChangeHandler);

    return () => {
      document.removeEventListener('visibilitychange', visibilityChangeHandler);
    };
  }, []);

  return isBrowserTabVisible;
};

/**
 * Poll if the browser tab is visible AND publication tab is active
 */
const usePollPublicLinksStatus = ({ isBrowserTabVisible, isPublicationTabSelected, fileId, interval = 5000 }) => {
  const [initialLoadingInProgress, setInitialLoadingInProgress] = useState(true);
  const [activePublicLinks, setActivePublicLinks] = useState(null);

  const shouldDoPolling = isBrowserTabVisible && isPublicationTabSelected;

  useEffect(() => {
    (async () => {
      if (shouldDoPolling) {
        const queryPublicLinksStatus = async () => {
          let currentActivePublicLinks = [];

          if (clustersStore.clusters) {
            await Promise.all(
              clustersStore.clusters.map(async (cluster) => {
                const baseUrl = cluster.urls?.tasklist;

                if (!baseUrl) {
                  tracingService.traceError(new Error(`Cluster ${cluster.name} does not have a tasklist URL`));
                  return;
                }

                let publicLink = null;

                try {
                  publicLink = await publicationService.getPublicLink({
                    fileId,
                    clusterId: cluster.uuid
                  });
                } catch (err) {
                  console.warn(err);
                }

                const isPublicLinkValid = publicLink && Object.keys(publicLink).length > 0;
                if (isPublicLinkValid) {
                  publicLink = {
                    ...publicLink,
                    endpoint: `${baseUrl}${publicLink.endpoint}`,
                    clusterId: cluster.uuid,
                    clusterName: cluster.name,
                    clusterUrl: baseUrl
                  };

                  currentActivePublicLinks.push(publicLink);
                }
              })
            );
          }

          const sortedActiveWebhooks = currentActivePublicLinks.sort((a, b) =>
            a.clusterName.localeCompare(b.clusterName)
          );
          setActivePublicLinks(sortedActiveWebhooks);
        };

        // Make initial request
        await queryPublicLinksStatus();
        setInitialLoadingInProgress(false);

        // Clear previous interval
        if (pollingInterval) {
          clearInterval(pollingInterval);
        }

        // Keep polling
        pollingInterval = setInterval(queryPublicLinksStatus, interval);
      } else {
        clearInterval(pollingInterval);
      }
    })();

    return () => {
      clearInterval(pollingInterval);
    };
  }, [isBrowserTabVisible, isPublicationTabSelected]);

  return { activePublicLinks, initialLoadingInProgress };
};

export default observer(Publication);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Stack, Tag } from '@carbon/react';

import { containsSearchWord } from 'components/EntityTable/utils';

import * as Styled from './NameRenderer.styled';
import { Link } from './LinkRenderer.styled';

function NameRenderer({ name, icon, link, tag, title, onClick }) {
  return (
    <Stack orientation="horizontal">
      <Styled.Icon>{icon}</Styled.Icon>
      <Styled.Title orientation="vertical">
        <Link className="cds--link" to={link} title={name?.content} onClick={onClick}>
          {name?.content}
        </Link>
        {name?.pretitle && <Styled.Type>{name?.pretitle}</Styled.Type>}
      </Styled.Title>
      {tag && (
        <Styled.TagContainer title={title ?? tag}>
          <Tag size="sm">
            <span>{tag}</span>
          </Tag>
        </Styled.TagContainer>
      )}
    </Stack>
  );
}

NameRenderer.containsSearchWord = (cell, searchWord) =>
  containsSearchWord(cell?.name?.content, searchWord) || containsSearchWord(cell?.name?.pretitle, searchWord);

NameRenderer.getComparableValue = (cell) => cell?.name?.content;

NameRenderer.getDataTest = (cell) => `entity-${cell?.name?.content}`;

export default NameRenderer;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { Toggle, Toggletip, ToggletipActions, ToggletipButton, ToggletipContent, SwitcherDivider } from '@carbon/react';
import { Information } from '@carbon/react/icons';

import { userStore } from 'stores';
import history from 'utils/history';
import { WithExternalLinkIcon } from 'primitives';

import * as Styled from './SuperUserToggle.styled';

const SuperUserToggle = () => {
  return (
    <>
      <SwitcherDivider />
      <Styled.Wrapper>
        <Toggle
          size="sm"
          id="toggle-super-user-mode"
          defaultToggled={userStore.isSuperUserModeActive}
          onToggle={() => {
            userStore.toggleSuperUserMode();
            history.push('/');
          }}
          labelText="Enter super-user mode"
          hideLabel
        />
        <Toggletip align="bottom-right">
          <ToggletipButton label="Show information">
            <Information />
          </ToggletipButton>
          <ToggletipContent className="super-user-mode__toggletip-content">
            As super-user, you have extended permissions.
            <ToggletipActions>
              <Styled.Link
                href="https://docs.camunda.io/docs/next/components/modeler/web-modeler/collaboration/#super-user-mode"
                target="_blank"
              >
                <WithExternalLinkIcon label="Learn more" />
              </Styled.Link>
            </ToggletipActions>
          </ToggletipContent>
        </Toggletip>
      </Styled.Wrapper>
    </>
  );
};

export default observer(SuperUserToggle);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { makeAutoObservable } from 'mobx';
import semver from 'semver';
import BPMNModdle from 'bpmn-moddle';
import zeebeModdle from 'zeebe-bpmn-moddle/resources/zeebe.json';
import { getBusinessObject, is } from 'bpmn-js/lib/util/ModelUtil';

import { trackingService } from 'services';
import { currentDiagramStore } from 'stores';
import { getExtensionElement } from 'utils/web-modeler-diagram-parser/extension-elements-util';

class PublicationStore {
  minimumClusterVersions = ['8.3.0', 'zeebe snapshot'];

  parentProcessId = null;
  recentlyDeployed = false;
  startEventElement = null;
  isPublicAccessEnabled = false;
  isPublicAccessSupported = false;
  isStartEventAttachedToForm = false;
  shouldReEnablePublicAccess = false;

  // Since we listen for command stack changes in order to sync the store with
  // the extension elements properties, we use this flag to temporarily disable
  // the listener and avoid conflicts when the change would imply a trigger of
  // that event.
  listenForPublicAccessPropertyChange = true;

  constructor() {
    makeAutoObservable(this, { parentProcessId: false });
  }

  checkIfPublicAccessEnabed(modeler) {
    const elementRegistry = modeler.get('elementRegistry');
    const startEvent = elementRegistry.find((element) => is(element, 'bpmn:StartEvent'));
    if (startEvent) {
      this.isPublicAccessEnabled = this.getPublicAccessPropertyFromElement(startEvent);
    }
  }

  reset() {
    this.startEventElement = null;
    this.isPublicAccessEnabled = false;
    this.isPublicAccessSupported = false;
    this.isStartEventAttachedToForm = false;
    this.parentProcessId = null;
    this.recentlyDeployed = false;
    this.shouldReEnablePublicAccess = false;
  }

  setStartEventElement(element) {
    this.startEventElement = element;

    if (!element) {
      this.#disposePublicAccess();
    }
  }

  setParentProcessId(id) {
    this.parentProcessId = id;
  }

  setRecentlyDeployed() {
    this.recentlyDeployed = true;
    setTimeout(() => {
      this.recentlyDeployed = false;
    }, 15000);
  }

  setIsPublicAccessEnabled(flag, trackEvent = true) {
    this.listenForPublicAccessPropertyChange = false;

    this.isPublicAccessEnabled = flag;
    this.#upsertPublicAccessProperty(flag);

    if (trackEvent) {
      trackingService.trackPublicationToggleClick(this.parentProcessId, flag);
    }

    this.listenForPublicAccessPropertyChange = true;
  }

  setShouldReEnablePublicAccess = (value) => {
    this.shouldReEnablePublicAccess = value;
  };

  setIsPublicAccessSupported(flag) {
    this.isPublicAccessSupported = flag;
  }

  setIsStartEventAttachedToForm(flag) {
    const previousFlag = this.isStartEventAttachedToForm;
    this.isStartEventAttachedToForm = flag;

    // Remove the public access property if the start event is not attached to a form anymore
    if (previousFlag && !flag) {
      this.#disposePublicAccess();
    }
  }

  removePublicAccessFromElement(element) {
    if (element) {
      this.#disposePublicAccess({ element, unlinkForm: true });
    }
  }

  get isSelectedElementStartEvent() {
    return !!this.startEventElement;
  }

  getPublicAccessPropertyFromElement(element) {
    const businessObject = getBusinessObject(element);
    let zeebeProperties = getExtensionElement(businessObject, 'zeebe:Properties');

    if (!zeebeProperties?.properties) {
      return false;
    }

    const publicAccessProperty = zeebeProperties?.properties?.find((property) => property.name === 'publicAccess');
    return publicAccessProperty?.value === 'true' || publicAccessProperty?.value === true;
  }

  syncStoreWithExtensionElements() {
    if (!this.startEventElement || !this.listenForPublicAccessPropertyChange) {
      return;
    }

    this.isPublicAccessEnabled = this.getPublicAccessPropertyFromElement(this.startEventElement);
  }

  isClusterSupported(version) {
    if (!version) {
      return false;
    }

    const coercedVersion = semver.coerce(version);
    return (
      this.minimumClusterVersions.includes(version.toLowerCase()) ||
      this.minimumClusterVersions.some((minimumVersion) =>
        semver.valid(coercedVersion) && semver.valid(minimumVersion)
          ? semver.gte(coercedVersion, minimumVersion)
          : false
      )
    );
  }

  // NOTE: We cannot rely on the local member isPublicAccessEnabled, because it might have been overwritten by the store sync with the extension elements,
  // so we pass the flag as a parameter to the method. The consumer of this method will be responsible for storing the flag before any possible change that might
  // trigger the store sync.
  /**
   * Sets the flag that indicates if the public access property shold be re-enabled after the form linking.
   * @param {*} isPublicAccessEnabled The flag that indicates if the public access is enabled or not before the change
   */
  handlePublicAccessPropertyPersistance(isPublicAccessEnabled) {
    if (isPublicAccessEnabled) {
      this.setShouldReEnablePublicAccess(true);
    }
  }

  /**
   * Re-enables the public access property if it's marked to be re-enabled.
   */
  handlePersistedPublicAccessProperty() {
    if (this.shouldReEnablePublicAccess) {
      this.setIsPublicAccessEnabled(true, false);
      this.setShouldReEnablePublicAccess(false);
    }
  }

  #updateStartEventExtensionElements({ element, extensionElements }) {
    if (!element) {
      throw new Error('Element is required to update the start event extension elements');
    }

    if (!extensionElements) {
      throw new Error('Extension elements are required to update the start event extension elements');
    }

    try {
      currentDiagramStore?.modeler?.get('modeling')?.updateProperties(element, {
        extensionElements
      });
    } catch (e) {
      console.warn('Error updating start event extension elements');
    }
  }

  #disposePublicAccess(params = { element: this.startEventElement, unlinkForm: false }) {
    const { element, unlinkForm } = params;

    if (!element) {
      return;
    }

    const businessObject = getBusinessObject(element);
    const extensionElements = businessObject.extensionElements;
    let zeebeProperties = getExtensionElement(businessObject, 'zeebe:Properties');

    if (!extensionElements || !zeebeProperties) {
      return;
    }

    if (unlinkForm) {
      // remove userTaskForm from extensionElements
      const userTaskForm = getExtensionElement(businessObject, 'zeebe:UserTaskForm');
      if (userTaskForm) {
        extensionElements.get('values').splice(extensionElements.get('values').indexOf(userTaskForm), 1);
      }
    }

    if (zeebeProperties.properties) {
      // remove publicAccess, and form reference if needed, from zeebeProperties
      zeebeProperties.properties = zeebeProperties.properties.filter((property) =>
        unlinkForm ? property.name !== 'publicAccess' && !property.formKey : property.name !== 'publicAccess'
      );
    }

    this.#updateStartEventExtensionElements({ element, extensionElements });
    this.isPublicAccessEnabled = false;
  }

  #upsertPublicAccessProperty(flag) {
    if (!this.startEventElement) {
      return;
    }

    const moddle = new BPMNModdle({ zeebe: zeebeModdle });
    const businessObject = getBusinessObject(this.startEventElement);
    const extensionElements = businessObject.extensionElements || moddle.create('bpmn:ExtensionElements');
    let zeebeProperties = getExtensionElement(businessObject, 'zeebe:Properties');

    if (!zeebeProperties) {
      zeebeProperties = moddle.create('zeebe:Properties');
      extensionElements.get('values').push(zeebeProperties);
    }

    const existingPublicAccessProperty = zeebeProperties?.properties?.find(
      (property) => property.name === 'publicAccess'
    );

    if (existingPublicAccessProperty) {
      existingPublicAccessProperty.value = flag;
    } else {
      const publicAccessProperty = moddle.create('zeebe:Property', {
        name: 'publicAccess',
        value: flag
      });
      // NOTE: $parent needs to be assigned outside of the moddle.create call, otherwise it will be undefined
      publicAccessProperty.$parent = extensionElements;

      if (zeebeProperties.properties) {
        zeebeProperties.properties.push(publicAccessProperty);
      } else {
        zeebeProperties.properties = [publicAccessProperty];
      }
    }

    this.#updateStartEventExtensionElements({
      element: this.startEventElement,
      extensionElements
    });
  }
}

export default new PublicationStore();

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { processApplicationStore, milestoneStore, organizationStore } from 'stores';
import { AddMilestone, Dots } from 'icons';
import { IconButton } from 'primitives';
import { Dropdown, TargetSelector, VersionPublishDialog } from 'components';
import hasAccess, { actions } from 'utils/user-access';
import { isProcessApplication } from 'utils/helpers';

import ConfirmPublicationDialog from './ConfirmPublicationDialog';
import ProcessApplicationVersionTagModal from './ProcessApplicationVersionTagModal';

export default function MilestoneMenu({ milestone, canPublishToOrg }) {
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const [moveAnchorEl, setMoveAnchorEl] = useState(null);
  const [versionPublishDialogOpen, setVersionPublishDialogOpen] = useState(false);
  const [orgPublishDialogOpen, setOrgPublishDialogOpen] = useState(false);

  const { project } = milestoneStore.state;
  const { isLatestVersion } = milestoneStore;

  const handleMove = async (target) => {
    setAnchorEl(null);
    setMoveAnchorEl(null);

    if (target.projectId) {
      const redirectTarget = isProcessApplication(target) ? 'process-applications' : 'folders';
      if (await milestoneStore.createFromMilestone(milestone, target.projectId, target.id, target)) {
        history.push(`/${redirectTarget}/${target.id}`);
      }
    } else {
      if (await milestoneStore.createFromMilestone(milestone, target.id, undefined, target)) {
        history.push(`/projects/${target.id}`);
      }
    }
  };

  if (isLatestVersion(milestone.id)) {
    return (
      <Fragment>
        <IconButton
          title={milestoneStore.state?.isTemplate ? 'Publish a new version to project' : 'Create a new milestone'}
          data-test="publish-version"
          onClick={() => {
            setVersionPublishDialogOpen(true);
          }}
        >
          <AddMilestone width="24" height="24" />
        </IconButton>

        {processApplicationStore.fromAProcessApplication ? (
          <ProcessApplicationVersionTagModal
            open={versionPublishDialogOpen}
            onRequestClose={() => setVersionPublishDialogOpen(false)}
            numberOfResources={processApplicationStore.processApplication.files?.length}
            handleVersionCreation={(name) =>
              milestoneStore.createForProcessApplication({
                processApplicationId: processApplicationStore.processApplication.id,
                fileId: milestoneStore.state.diagram?.id,
                append: true,
                origin: 'latest-version',
                name
              })
            }
          />
        ) : (
          <VersionPublishDialog
            open={versionPublishDialogOpen}
            onClose={() => setVersionPublishDialogOpen(false)}
            file={milestoneStore.state.diagram}
            origin="latest-version"
          />
        )}
      </Fragment>
    );
  }

  if (!processApplicationStore.fromAProcessApplication) {
    return (
      <Fragment>
        <ConfirmPublicationDialog
          milestone={milestone}
          onClose={() => setOrgPublishDialogOpen(false)}
          open={orgPublishDialogOpen}
          templateName={milestoneStore.state?.diagram?.name}
        />
        <IconButton
          onClick={(evt) => {
            setAnchorEl(evt.currentTarget);
            evt.stopPropagation();
          }}
          aria-haspopup="true"
          aria-owns={anchorEl ? 'milestone-menu' : undefined}
          data-test="milestone-menu-button"
          title="Browse milestone actions"
        >
          <Dots />
        </IconButton>

        <Dropdown
          size="small"
          id="milestone-menu"
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setMoveAnchorEl(null);
          }}
        >
          {milestoneStore.state?.isTemplate &&
            organizationStore.hasElevatedOrganizationPermissions &&
            canPublishToOrg && (
              <>
                <Dropdown.ListItem
                  data-test="milestone-menu-item-publish-to-org"
                  onClick={(evt) => {
                    evt.stopPropagation();
                    setAnchorEl(null);
                    setOrgPublishDialogOpen(true);
                  }}
                >
                  Publish to organization
                </Dropdown.ListItem>
                <Dropdown.ListItemDivider />
              </>
            )}
          <Dropdown.ListItem
            data-test="milestone-menu-item-rename"
            onClick={(evt) => {
              evt.stopPropagation();
              setAnchorEl(null);
              milestoneStore.setEditingMilestone(milestone.id);
            }}
          >
            Edit name
          </Dropdown.ListItem>

          <Dropdown.ListItem
            data-test="milestone-menu-item-restore"
            disabled={milestoneStore.state.diagram.imported}
            onClick={(evt) => {
              evt.stopPropagation();
              setAnchorEl(null);
              milestoneStore.restoreMilestone(milestone);
            }}
          >
            Restore as latest
          </Dropdown.ListItem>

          {hasAccess(project, actions.DELETE_MILESTONE) && (
            <Dropdown.ListItem
              data-test="milestone-menu-item-delete"
              onClick={(evt) => {
                evt.stopPropagation();
                setAnchorEl(null);
                milestoneStore.delete(milestone);
              }}
            >
              Delete
            </Dropdown.ListItem>
          )}
          <Dropdown.ListItem
            hasSubDropdown
            data-test="milestone-menu-item-copy"
            onClick={(evt) => setMoveAnchorEl(evt.currentTarget)}
          >
            Copy to...
          </Dropdown.ListItem>
          <TargetSelector
            anchorEl={moveAnchorEl}
            open={Boolean(moveAnchorEl)}
            startingPoint={project}
            invalidTargetIds={[]}
            onSubmit={handleMove}
            action="copy"
          />
        </Dropdown>
      </Fragment>
    );
  }
}

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { autorun } from 'mobx';

import { organizationStore } from 'stores';
import config from 'utils/config';

import BaseDedicatedModesStore from './DedicatedModesStore.common';

class DedicatedModesStore extends BaseDedicatedModesStore {
  #storeObserverDisposer = null;

  constructor() {
    super();
  }

  init() {
    super.init();

    this.#storeObserverDisposer = autorun(() => {
      const isPlayEnabled = config.play.enabled && organizationStore.hasPlayAccess;

      if (!isPlayEnabled) {
        this.disallowPlayMode();
      } else {
        this.allowPlayMode();
      }
    });
  }

  reset() {
    super.reset();

    this.#storeObserverDisposer?.();
  }
}

export default new DedicatedModesStore();

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import PropTypes from 'prop-types';

import { Loader } from 'primitives';

import * as Styled from './Spinner.styled';

export default function Spinner({ fullHeight, ...props }) {
  return (
    <Styled.Wrapper $fullHeight={fullHeight} {...props} data-test="loader">
      <Loader />
    </Styled.Wrapper>
  );
}

Spinner.propTypes = {
  fullHeight: PropTypes.bool
};

Spinner.defaultProps = {
  fullHeight: false
};

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import Modeler from 'camunda-bpmn-js/lib/camunda-cloud/Modeler';

import { ResizablePanel } from 'primitives';

import connectorTemplateStore from './ConnectorTemplateStore';
import * as Styled from './PropertiesPanel.styled.js';

export function PropertiesPanel({ value }) {
  const diagramContainer = useRef();
  const panelContainer = useRef();
  const task = useRef();
  const [modeler, setModeler] = useState();
  const [showPreviewPanel, setShowPreviewPanel] = useState(true);

  const { isValid } = connectorTemplateStore;

  // initialize the modeler
  useEffect(() => {
    setModeler(
      new Modeler({
        container: diagramContainer.current,
        propertiesPanel: {
          parent: panelContainer.current
        }
      })
    );
  }, []);

  // initially select the task to put properties panel preview in correct state
  useEffect(() => {
    if (modeler) {
      modeler.get('eventBus').once('propertiesPanel.layoutChanged', () => {
        // wait for properties panel effect hooks to complete before selecting task
        setTimeout(() => modeler.get('selection').select(task.current, true), 0);
      });
    }
  }, [modeler]);

  // update properties panel whenever value changes
  useEffect(() => {
    if (modeler && value && isValid(value)) {
      try {
        const connectorTemplateDefinition = JSON.parse(value);
        modeler.invoke([
          'elementTemplatesLoader',
          'elementTemplates',
          'elementFactory',
          'bpmnFactory',
          'modeling',
          'canvas',
          'selection',
          (elementTemplatesLoader, elementTemplates, elementFactory, bpmnFactory, modeling, canvas, selection) => {
            elementTemplatesLoader.setTemplates([connectorTemplateDefinition]);

            const connectorTemplate = elementTemplates.get(
              connectorTemplateDefinition.id,
              connectorTemplateDefinition.version
            );

            if (!connectorTemplate) {
              // invalid template
              return;
            }

            const type =
              (connectorTemplate.elementType && connectorTemplate.elementType.value) || connectorTemplate.appliesTo[0];

            modeler.importXML(BPMN_XML).then(() => {
              try {
                task.current = modeling.createElements(
                  [
                    elementFactory.create('shape', {
                      type: type,
                      businessObject: bpmnFactory.create(type, {
                        name: type.replace('bpmn:', '')
                      })
                    })
                  ],
                  { x: 200, y: 200 },
                  canvas.getRootElement()
                )[0];

                elementTemplates.applyTemplate(task.current, connectorTemplate);

                selection.select(task.current, true);
              } catch (e) {
                // invalid task type specified
              }
            });
          }
        ]);
      } catch (e) {
        // invalid json specified
      }
    }
  }, [value, modeler]);

  return (
    <ResizablePanel
      data-test="visual-preview"
      panelKey="properties-panel"
      handleAriaLabel="Resize preview panel"
      open={showPreviewPanel}
      background="white"
      position="right"
      minSize={400}
      maxSize={1200}
      onOpenChange={(state) => setShowPreviewPanel(!state)}
    >
      <Styled.DiagramContainer ref={diagramContainer} />
      <Styled.Header>
        <b>Visual Preview</b>
      </Styled.Header>
      <Styled.PanelContainer ref={panelContainer} />
    </ResizablePanel>
  );
}

export default observer(PropertiesPanel);

const BPMN_XML = `<?xml version="1.0" encoding="UTF-8"?>
<definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" id="sid-38422fae-e03e-43a3-bef4-bd33b32041b2" targetNamespace="http://bpmn.io/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="9.0.3">
  <process id="Process_1" isExecutable="false" />
  <bpmndi:BPMNDiagram id="BpmnDiagram_1">
    <bpmndi:BPMNPlane id="BpmnPlane_1" bpmnElement="Process_1" />
  </bpmndi:BPMNDiagram>
</definitions>
`;

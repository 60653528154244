/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { CodeSnippet } from '@carbon/react';

import capitalize from 'utils/capitalize';
import { notificationStore } from 'stores';

import * as Styled from './InboundConnector.styled';

export const InboundConnectorDetail = ({ title, value }) => {
  return (
    <Styled.DetailContainer>
      <Styled.DetailTitle>{capitalize(title)}</Styled.DetailTitle>
      <CodeSnippet
        wrapText
        align="bottom-right"
        type="multi"
        onClick={() => {
          copyDetail(value);
        }}
      >
        {value}
      </CodeSnippet>
    </Styled.DetailContainer>
  );
};

const copyDetail = async (detail) => {
  try {
    await navigator.clipboard.writeText(detail);
    notificationStore.showSuccess('The details have been copied into your clipboard as JSON.');
  } catch (err) {
    notificationStore.showError(
      "Yikes! Couldn't copy the details into your clipboard. Make sure that you gave permissions to the browser."
    );
  }
};

export default observer(InboundConnectorDetail);

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import styled from 'styled-components';

export const EntityListWrapper = styled.div`
  --header-background: var(--silver-base-97);
  --padding: 40px;

  overflow: auto;
  flex: 1;
`;

export const EntityListInner = styled.div`
  height: 100%;
  overflow: auto;
  position: relative;
`;

export const Header = styled.header`
  z-index: 1;
  display: flex;
  padding: 40px var(--padding) 5px var(--padding);
  background-color: var(--header-background);
  flex-direction: column;
  justify-content: flex-end;
  position: sticky;
  border-bottom: 1px solid transparent;
  width: 100%;
  top: 0;

  ${({ $isSticky }) => {
    if ($isSticky) {
      return `
        border-color: var(--silver-darken-87);
      `;
    }
  }}
`;

export const ActionArea = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-bottom: 15px;

  > button:not(:last-child) {
    margin-right: 15px;
  }
`;

export const Title = styled.h2`
  margin: 0 auto 0 0;
  font-weight: 400;
  font-size: 21px;
  color: rgb(51, 51, 51);
`;

export const List = styled.ul`
  padding: 0 var(--padding);
  margin: 3px 0 15px 0;
  list-style-type: none;
  width: 100%;
`;

/*
 * Copyright Camunda Services GmbH and/or licensed to Camunda Services GmbH under
 * one or more contributor license agreements. See the NOTICE file distributed
 * with this work for additional information regarding copyright ownership.
 * Licensed under the Camunda License 1.0. You may not use this file
 * except in compliance with the Camunda License 1.0.
 */

import { observer } from 'mobx-react';
import { ActionableNotification } from '@carbon/react';
import { C3ClusterTag } from '@camunda/camunda-composite-components';
import { useEffect, useState } from 'react';

import { parseClusterZeebeVersion } from 'utils/cluster/cluster-version-util';
import { ClustersList } from 'components';
import { processApplicationStore, currentDiagramStore } from 'stores';
import { deploymentPermissionsStore, deploymentStore } from 'App/Pages/Diagram/Deployment/stores';
import ContainedResourcesNotification from 'App/Pages/Diagram/Deployment/executeModal/ContainedResourcesNotification';
import { AddVariable } from 'App/Pages/Diagram/Deployment/executeModal/AddVariable';
import DeployLinkedDiagramInfo from 'App/Pages/Diagram/Deployment/executeModal/DeployLinkedDiagramInfo';
import isClusterPaused from 'utils/cluster/is-cluster-paused';

import ClusterHeader from './ClusterHeader';
import PublicationClusterMessage from './PublicationClusterMessage';
import * as Styled from './ClusterSelection.styled';
import HealthyClusterRequiredNotification from './HealthyClusterRequiredNotification';
import ClusterPausedResumingNotification from './ClusterPausedResumingNotification';
import SecuredConnectorsMessage from './SecuredConnectorsMessage/SecuredConnectorsMessage';
import MissingClientsMessage from './MissingClientsMessage/MissingClientsMessage';

export const ClusterSelection = ({ clusters, deployOnly, onClose, payload, setPayload, isValidPayload, disabled }) => {
  const { executionPlatformVersion, isBPMN, state, modeler } = currentDiagramStore;
  const { diagram } = state;
  const { selectedClusterId, setSelectedClusterId } = deploymentStore;
  const { isAllowedToDeployToSelectedCluster } = deploymentPermissionsStore;
  const { processApplication, fromAProcessApplication } = processApplicationStore;

  const version = { deploy: null, lint: null };
  let selectedCluster;
  if (clusters?.length > 0) {
    selectedCluster = clusters.find((cluster) => cluster.uuid === selectedClusterId);
    if (selectedCluster?.generation?.versions?.zeebe) {
      const selectedClusterVersionInfo = parseClusterZeebeVersion(selectedCluster.generation?.versions?.zeebe);
      if (selectedClusterVersionInfo) {
        const { major, minor } = selectedClusterVersionInfo;

        version['deploy'] = `${major}.${minor}`;
        version['lint'] = `${executionPlatformVersion}`.substring(0, 3);
      }
    }
  }

  const [diagramXml, setDiagramXml] = useState(null);

  useEffect(() => {
    (async () => {
      if (!modeler) {
        return;
      }

      const { xml } = await modeler.saveXML();
      if (xml) {
        setDiagramXml(xml);
      }
    })();
  }, [modeler]);

  const isOnlyOneClusterPresentAndItsBeingCreated =
    clusters?.length === 1 && selectedCluster?.status?.zeebe === 'Creating';

  const inAProcessApplicationWithNoStagesClusters =
    processApplicationStore.fromAProcessApplication && !clusters?.length;

  return (
    <>
      <ClusterHeader
        deployOnly={deployOnly}
        onClose={onClose}
        isClusterBeingCreated={clusters?.length === 1 && clusters[0].status?.zeebe === 'Creating'}
        disabled={disabled}
      />

      {inAProcessApplicationWithNoStagesClusters ? (
        <Styled.NoStagesClustersNotification
          inline
          actionButtonLabel={'Learn more'}
          onActionButtonClick={() => {
            /*TODO Update the learn more link: https://github.com/camunda/web-modeler/issues/10299*/
            return window.open(
              'https://docs.camunda.io/docs/next/components/modeler/web-modeler/process-applications/'
            );
          }}
          kind="warning"
          lowContrast
          hideCloseButton
          title="Undefined stages"
          subtitle="To be able to deploy you have to define clusters for your stages."
        />
      ) : (
        <ClustersList
          clusters={clusters}
          disabled={disabled}
          selectedClusterId={selectedClusterId}
          onClusterIdSelection={(clusterId) => setSelectedClusterId(clusterId)}
        />
      )}

      <Styled.InfosContainer>
        {!isAllowedToDeployToSelectedCluster ? (
          <Styled.ProdClusterInfo
            inline
            actionButtonLabel={'Learn more'}
            onActionButtonClick={() =>
              window.open('https://docs.camunda.io/docs/components/modeler/web-modeler/run-or-publish-your-process/')
            }
            kind="info"
            lowContrast
            hideCloseButton
            title="Missing permissions"
            subtitle={
              <>
                Only organization admins and organization owners can directly deploy to <C3ClusterTag stage={'prod'} />{' '}
                clusters. Try a different cluster or request more permissions.
              </>
            }
          />
        ) : selectedCluster?.status?.zeebe === 'Resuming' || isClusterPaused(selectedCluster?.status?.zeebe) ? (
          <ClusterPausedResumingNotification clusterId={selectedClusterId} status={selectedCluster?.status?.zeebe} />
        ) : selectedCluster?.status?.zeebe === 'Healthy' ? (
          <>
            {fromAProcessApplication && processApplication.files && (
              <ContainedResourcesNotification resources={processApplication.files.length} />
            )}
            <DeployLinkedDiagramInfo />
            {isBPMN && (
              <Styled.EngineSwitchInfo>
                {version['lint'] !== version['deploy'] && (
                  <ActionableNotification
                    inline
                    actionButtonLabel={'Learn more'}
                    onActionButtonClick={() =>
                      window.open(
                        'https://docs.camunda.io/docs/components/modeler/web-modeler/fix-problems-in-your-diagram/#engine-version-selection'
                      )
                    }
                    kind="info"
                    lowContrast
                    hideCloseButton
                    title="Version mismatch"
                    subtitle={`If you deploy to the selected cluster, your diagram will be checked for problems against Zeebe ${version['deploy']} going forward`}
                  />
                )}
              </Styled.EngineSwitchInfo>
            )}
          </>
        ) : (
          clusters?.length > 0 &&
          !isOnlyOneClusterPresentAndItsBeingCreated && <HealthyClusterRequiredNotification deployOnly={deployOnly} />
        )}

        {isBPMN && <PublicationClusterMessage clusters={clusters} selectedCluster={selectedCluster} />}

        <SecuredConnectorsMessage />

        <MissingClientsMessage diagramXml={diagramXml} diagramType={diagram.type} />
      </Styled.InfosContainer>

      {clusters?.length > 0 && !deployOnly && (
        <AddVariable payload={payload} setPayload={setPayload} isValidPayload={isValidPayload} />
      )}
    </>
  );
};

export default observer(ClusterSelection);
